import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { TaskKey, useDisableTask, useEnableTask } from '../queries';

export const useToggleTaskStatus = () => {
  const { mutate: enableTask } = useEnableTask();
  const { mutate: disableTask } = useDisableTask();
  const queryClient = useQueryClient();

  const onChange = useCallback(
    (checked: boolean, taskId: number) => {
      const onSuccess = () => {
        queryClient.invalidateQueries(TaskKey.ALL);
      };

      if (checked) {
        enableTask(taskId, {
          onSuccess,
        });
      } else {
        disableTask(taskId, {
          onSuccess,
        });
      }
    },
    [queryClient, enableTask, disableTask],
  );

  return { onChange };
};

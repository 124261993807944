import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useDisableOrganization } from '../queries/disabled-organization';
import { useEnableOrganization } from '../queries/enable-organization';
import { OrganizationKeys } from '../queries/keys';

export const useToggleOrganizationStatus = () => {
  const { mutate: enableOrganization } = useEnableOrganization();
  const { mutate: disableOrganization } = useDisableOrganization();

  const queryClient = useQueryClient();

  const onChange = useCallback(
    (checked: boolean, organizationId: number) => {
      const onSuccess = () => {
        queryClient.invalidateQueries(OrganizationKeys.ALL);
      };

      if (checked) {
        enableOrganization(organizationId, {
          onSuccess,
        });
      } else {
        disableOrganization(organizationId, {
          onSuccess,
        });
      }
    },
    [disableOrganization, enableOrganization, queryClient],
  );

  return { onChange };
};

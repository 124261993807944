import {
  Button,
  CustomPageContainer,
  FilterAndPaginationContext,
  FilterSearchBar,
  Table,
} from '@/components';
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { TableRow, TableSort } from '@/components/Table/types';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import { CreateInfrastructureCostModal } from './components/CreateInfrastructureCostModal/CreateInfrastructureCostModal';
import { useGetInfrastructureCosts } from '../../queries';
import {
  getInfrastructureCostColumns,
  getInfrastructureCostColumnsNamedMapped,
} from './utils';

export const InfrastructureCostPage = (): ReactElement => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [tableSort, setTableSort] = useState<TableSort[]>([]);
  const navigate = useNavigate();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();

  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const { searchTerm, page, onPageChange, handleSearchChange } = useContext(
    FilterAndPaginationContext,
  );

  const {
    data: infrastructureCosts,
    isLoading: isLoadinginfrastructureCosts,
    isError,
  } = useGetInfrastructureCosts(
    page + 1,
    searchTerm,
    getInfrastructureCostColumnsNamedMapped(tableSort?.[0]?.columnId ?? 'name'),
    tableSort?.[0]?.direction ?? 'ascending',
  );

  useEffect(() => {
    if (isError) {
      toast.error(t('error_getting_infrastructureCosts'));
    }
  }, [isError, t]);

  const routeChange = (row?: TableRow) => {
    const path = `${row?.id}`;
    navigate(path);
  };

  const onSort = (data: TableSort[]) => {
    setTableSort(data);
  };

  return (
    <CustomPageContainer className="gap-10">
      <CustomPageContainer.Header title={t('infrastructure_costs')}>
        <Button onClick={handleModalState} variant="primary">
          {t('create_cost')}
        </Button>
      </CustomPageContainer.Header>
      <CreateInfrastructureCostModal
        handleClose={handleModalState}
        isOpen={isOpen}
      />
      <FilterSearchBar
        total={infrastructureCosts?.totalRows || 0}
        title={t('costs')}
        onSearch={handleSearchChange}
        handleInputSearchChange={handleInputSearchChange}
        searchInputValue={searchInputValue}
      />
      <Table
        columns={getInfrastructureCostColumns({
          onClick: routeChange,
        })}
        rows={infrastructureCosts?.items ?? []}
        isLoading={isLoadinginfrastructureCosts}
        noResultsLabel={t('no_costs_available')}
        withPagination
        totalItems={infrastructureCosts?.totalRows}
        totalPages={infrastructureCosts?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
      />
    </CustomPageContainer>
  );
};

import {
  Button,
  CustomPageContainer,
  FilterAndPaginationContext,
  FilterSearchBar,
  Table,
} from '@/components';
import { TableRow, TableSort } from '@/components/Table/types';
import { queryClient } from '@/constants';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useToggleProcessStatus } from '../../hooks/useToggleProcessStatus';
import { ProcessKey, useDeleteProcess, useGetProcesses } from '../../queries';
import { CreateProcessModal } from './components';
import { getProcessesColumns, getProcessesColumnsNamedMapped } from './utils';

export const ProcessesPage = (): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();
  const [tableSort, setTableSort] = useState<TableSort[]>([]);
  const { onChange: onProcessStatusChange } = useToggleProcessStatus();

  const getAllProcessesOptions = useMemo(
    () => ({
      onError: () => {
        toast.error(t('error_getting_processes'));
      },
    }),
    [t],
  );

  const { searchTerm, page, onPageChange, handleSearchChange } = useContext(
    FilterAndPaginationContext,
  );
  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const { data: processes, isLoading: isLoadingProcesses } = useGetProcesses(
    page + 1,
    searchTerm,
    getProcessesColumnsNamedMapped(tableSort?.[0]?.columnId ?? 'name'),
    tableSort?.[0]?.direction ?? 'ascending',
    getAllProcessesOptions,
  );

  const { mutate: deleteOrganization } = useDeleteProcess();

  const handleDelete = (processId: number) => {
    deleteOrganization(processId, {
      onError: () => {
        toast.error(t('error_deleting_process'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(ProcessKey.ALL);
        toast.success(t('success_deleting_process'));
      },
    });
  };

  const onSort = (data: TableSort[]) => {
    setTableSort(data);
  };

  const routeChange = useCallback(
    (row?: TableRow) => {
      const path = `${row?.id}`;
      navigate(path);
    },
    [navigate],
  );

  const handleOnStatusChange = useCallback(
    (checked: boolean, row: TableRow) => {
      onProcessStatusChange(checked, +row.id);
    },
    [onProcessStatusChange],
  );

  return (
    <CustomPageContainer>
      <CustomPageContainer.Header title={t('processes')}>
        <Button onClick={handleModalState} variant="primary" className="h-9">
          {t('create_process')}
        </Button>
      </CustomPageContainer.Header>
      <FilterSearchBar
        total={processes?.totalRows || 0}
        title={t('processes')}
        onSearch={handleSearchChange}
        handleInputSearchChange={handleInputSearchChange}
        searchInputValue={searchInputValue}
      />

      <CreateProcessModal handleClose={handleModalState} isOpen={isOpen} />
      <Table
        columns={getProcessesColumns({
          onClick: routeChange,
          disableDeleteMessage: t('delete_process'),
          onDeleteClick(row) {
            handleDelete(row?.id as number);
          },
          onStatusChange: handleOnStatusChange,
        })}
        rows={processes?.items ?? []}
        isLoading={isLoadingProcesses}
        noResultsLabel={t('no_processes_available')}
        withPagination
        totalItems={processes?.totalRows}
        totalPages={processes?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
      />
    </CustomPageContainer>
  );
};

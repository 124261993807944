import { TableColumn, TableRow } from '@/components/Table/types';
import i18next from 'i18next';
import { Trans } from 'react-i18next';

export const recurrenceColumnConfig = <T extends TableRow>(
  options?: Omit<TableColumn<T>, 'id' | 'label'>,
): TableColumn<T> => ({
  id: 'recurrence',
  label: <Trans i18nKey="recurrence" />,
  render: (value) => (
    <div className="overflow-hidden text-ellipsis">{i18next.t(value)}</div>
  ),
  acessorKey: 'amountRecurrence',
  sortable: true,
  align: 'left',
  alignHeader: 'left',
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});

import { PaginationParams } from '@/shared/types';

export abstract class OrganizationKeys {
  static ALL = ['organizations'];
  static BY_ID = (organizationId: number) => [
    ...OrganizationKeys.ALL,
    'by-id',
    organizationId,
  ];
  static BY_USER = [...OrganizationKeys.ALL, 'by-user'];
  static BY_USER_PAGED = (userId: number, pagination: PaginationParams) => [
    ...OrganizationKeys.BY_USER,
    userId,
    pagination,
  ];
  static BY_INFRASTRUCTURE_COST_ID = (infrastructureCostId?: number) => [
    ...OrganizationKeys.ALL,
    'by-infrastructure-cost-id',
    infrastructureCostId,
  ];

  static BY_INFRASTRUCTURE_COST_ID_PAGED = (
    infrastructureCostId: number,
    pagination: PaginationParams,
  ) => [
    ...OrganizationKeys.BY_INFRASTRUCTURE_COST_ID(infrastructureCostId),
    pagination,
  ];

  static PAGED = (pagination: PaginationParams) => [
    ...OrganizationKeys.ALL,
    pagination,
  ];
}

import { Card, SwitchStatus } from '@/components';
import { Button } from '@/components/Button';
import { Container } from '@/components/Container';
import { BusinessAreaModel } from '@/modules/BusinessAreas/domain';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditBusinessAreaModal } from '../../../BusinessAreasPage/components/EditBusinessAreaModal copy';

interface HeaderRowProps {
  businessArea?: BusinessAreaModel;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent>,
    id: string,
  ) => void;
  status: boolean;
  isLoading?: boolean;
}

export const HeaderRow = ({
  businessArea,
  handleStatus,
  status,
}: HeaderRowProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return (
    <Container isLoading={!businessArea}>
      <div className="flex justify-between">
        <span>
          <h2>{businessArea?.name}</h2>
          <h6>
            <span className="font-bold">
              {businessArea?.organizationName}, {businessArea?.company}
            </span>
          </h6>
        </span>

        <div className="flex h-10 flex-row gap-3">
          <div className="flex flex-row items-center gap-3 rounded-md bg-white pb-0 pl-3 pr-3 pt-0 text-sm leading-xs">
            <span className="pr-10 font-black">Status</span>
            <span className="p-0 text-americanGreen">
              {status ? 'Active' : 'Inactive'}{' '}
              {/* //TODO: remove strings hardcoded*/}
            </span>
            <SwitchStatus onChange={handleStatus} checked={status} />
          </div>
          <Button className="h-9 p-5" onClick={handleModalState}>
            {t('edit_businessArea')}
          </Button>
          {businessArea && (
            <EditBusinessAreaModal
              isOpen={isOpen}
              handleClose={handleModalState}
              businessAreaId={businessArea.id}
            />
          )}
        </div>
      </div>
      <span className="flex flex-row gap-2.5">
        <Card title={t('code')}>{businessArea?.code || ''}</Card>
      </span>
    </Container>
  );
};

import { SortDirection } from '@/components/Table/types';
import { usersRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { UserModel } from '../domain';
import { UsersKeys } from './types';

export const useGetUsers = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<UserModel[]>,
    any,
    DataWithPagination<UserModel[]>
  >,
) =>
  useQuery<DataWithPagination<UserModel[]>>(
    UsersKeys.LIST_PAGED({
      pageNumber,
      filter,
      sortBy,
      sortOrder,
    }),
    () => usersRepo.getAllUsers(pageNumber, filter, sortBy, sortOrder),
    { ...options, cacheTime: 1000 * 60 * 5 },
  );

export const useGetTenantUsers = (
  data: {
    pageNumber?: number;
    filter?: string;
    sortBy?: string;
    sortOrder?: SortDirection;
    isAccountAdmin?: boolean;
  },
  options?: UseQueryOptions<
    DataWithPagination<UserModel[]>,
    any,
    DataWithPagination<UserModel[]>
  >,
) =>
  useQuery<DataWithPagination<UserModel[]>>(
    UsersKeys.ADMIN_USERS_PAGED(
      {
        pageNumber: data.pageNumber,
        filter: data.filter,
        sortBy: data.sortBy,
        sortOrder: data.sortOrder,
      },
      data.isAccountAdmin,
    ),
    () => usersRepo.getTenantUsers(data),
    options,
  );

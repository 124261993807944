import { z } from 'zod';

export const TaskSchema = z
  .object({
    taskName: z.string().min(1, { message: 'error_field_required' }),
    organizationId: z.coerce
      .number({ required_error: 'error_field_required' })
      .min(1, { message: 'error_field_required' }),
    companyId: z.coerce
      .number({ required_error: 'error_field_required' })
      .min(1, { message: 'error_field_required' }),
    businessAreaId: z.coerce
      .number({ required_error: 'error_field_required' })
      .min(1, { message: 'error_field_required' }),
    workflowId: z.coerce.number().optional(),
    taskDescription: z.string().optional(),
    taskPeriodicity: z.string().min(1, { message: 'error_field_required' }),
    labourWorkload: z.coerce.number().positive({
      message: 'error_field_must_be_positive',
    }),
    labourWorkloadType: z.string().min(1, { message: 'error_field_required' }),
    documentation: z.any().optional(),
    averageCases: z.coerce.number().optional(),
  })
  .refine(
    (data) => data.labourWorkloadType !== 'PerCase' || data.averageCases !== 0,
    {
      message: 'Average Cases is required when Labour Workload Type is PerCase',
      path: ['averageCases'],
    },
  );

export type FormTaskSchemaType = z.infer<typeof TaskSchema>;

export interface TaskSchemaType extends FormTaskSchemaType {
  id?: number;
}

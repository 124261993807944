import {
  ApiResponseUserRates,
  ApiResponseUsers,
  PagedUserRates,
  PagedUsers,
  UserJson,
  UserProfilesJson,
} from '../types';
import { UserModel } from '../domain';
import { mapPagination } from '@/shared/mappers';
import { UserProfilesModel } from '../domain/user-profiles-model';
import { getFullString } from '@/utils';
import { separateString } from '@/utils';
import { UserRate } from '../types/user-rates';
import { UserRateModel } from '../domain/user-rate-model';

export const mapUserJsonToUserModel = (userJson: UserJson): UserModel => ({
  id: userJson.id ?? 0,
  firstName: userJson.firstName ?? '',
  lastName: userJson.lastName ?? '',
  email: userJson.email ?? '',
  status: userJson.status ?? false,
  access: userJson.access ?? false,
  connections: userJson.connections ?? 0,
  profileType: separateString(userJson.profileType || '') ?? '',
  profileTypeId: userJson.profileTypeId ?? 0,
  organizationsCount: userJson.organizationsCount ?? 0,
  companiesCount: userJson.companiesCount ?? 0,
  businessAreasCount: userJson.businessAreasCount ?? 0,
  processesCount: userJson.processesCount ?? 0,
  tasksCount: userJson.tasksCount ?? 0,
  name: getFullString(userJson.firstName ?? '', userJson.lastName ?? ''),
  organizations: userJson.organizations ?? [],
  canDelete: userJson.canDelete ?? false,
  profileImageFileId: userJson.profileImageFileId,
  accountAdmin: userJson.accountAdmin ?? false,
});

export const usersWithPaginationMapper = (
  usersJson: ApiResponseUsers<PagedUsers>,
) => {
  const { data } = usersJson;
  return {
    items: usersMapper(data.users.items),
    ...mapPagination(data.users),
  };
};

export const usersMapper = (usersJson: Array<UserJson>): Array<UserModel> =>
  usersJson.map(mapUserJsonToUserModel);

export const mapUserProfileJsonToUserProfileModel = (
  data: UserProfilesJson,
): UserProfilesModel => ({
  id: data.id ?? 0,
  name: separateString(data.name || '') ?? '',
});

export const userProfilesMapper = (
  data: Array<UserProfilesJson>,
): Array<UserProfilesModel> => data.map(mapUserProfileJsonToUserProfileModel);

export const mapUserRateJsonToUserModel = (
  userRateJson: UserRate,
): UserRateModel => ({
  id: userRateJson.id ?? 0,
  value: userRateJson.value ?? 0,
  startDate: userRateJson.startDate && new Date(userRateJson.startDate),
  endDate: userRateJson.endDate && new Date(userRateJson.endDate),
  jiraAccountId: userRateJson.jiraAccountId ?? '',
  updatedAt: userRateJson.updatedAt && new Date(userRateJson.updatedAt),
  createdAt: userRateJson.createdAt && new Date(userRateJson.createdAt),
});

export const userRatesMapper = (
  usersJson: Array<UserRate>,
): Array<UserRateModel> => usersJson.map(mapUserRateJsonToUserModel);

export const userRatesWithPaginationMapper = (
  usersJson: ApiResponseUserRates<PagedUserRates>,
) => {
  const { data } = usersJson;
  return {
    items: userRatesMapper(data.hourlyRates.items),
    ...mapPagination(data.hourlyRates),
  };
};

import { SortDirection } from '@/components/Table/types';
import { usersRepo } from '@/constants/providers';
import { UserModel } from '@/modules/Users/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { UsersKeys } from './types';

export const useGetUsersByOrganization = (
  organizationId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<UserModel[]>,
    any,
    DataWithPagination<UserModel[]>
  >,
) =>
  useQuery<DataWithPagination<UserModel[]>>(
    UsersKeys.BY_ORGANIZATION_PAGED(organizationId, {
      pageNumber,
      filter,
      sortBy,
      sortOrder,
    }),
    () =>
      usersRepo.getUsersByOrganization(
        organizationId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );

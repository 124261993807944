import {
  Button,
  CustomPageContainer,
  FilterAndPaginationContext,
  FilterSearchBar,
  Table,
} from '@/components';
import { TableRow, TableSort } from '@/components/Table/types';
import { queryClient } from '@/constants';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import { useToggleUserAccess } from '@/hooks/use-toggle-user-access';
import { useToggleUserStatus } from '@/hooks/use-toggle-user-status';
import { deleteRowColumnConfig } from '@/utils';
import {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDeleteUser, useGetUsers } from '../../queries';
import { UsersKeys } from '../../queries/types';
import { CreateUserModal } from './components/CreateUserModal';
import { getUsersColumns, getUsersColumnsNamedMapped } from './utils';

export const UsersPage = (): ReactElement => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();
  const [tableSort, setTableSort] = useState<TableSort[]>([]);

  const { onChange: onAccessChange } = useToggleUserAccess();
  const { onChange: onStatusChange } = useToggleUserStatus();

  const navigate = useNavigate();

  const { mutate: deleteOrganization } = useDeleteUser();

  const handleDelete = (organizationId: number) => {
    deleteOrganization(organizationId, {
      onError: () => {
        toast.error(t('error_deleting_user'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(UsersKeys.ALL);
        toast.success(t('success_deleting_user'));
      },
    });
  };

  const allUsersOptions = useMemo(
    () => ({
      onError: () => {
        toast.error(t('error_getting_users'));
      },
    }),
    [t],
  );

  const { searchTerm, page, onPageChange, handleSearchChange } = useContext(
    FilterAndPaginationContext,
  );

  const { data: users, isLoading: isLoadingUsers } = useGetUsers(
    page + 1,
    searchTerm,
    getUsersColumnsNamedMapped(tableSort?.[0]?.columnId ?? 'name'),
    tableSort?.[0]?.direction ?? 'ascending',
    allUsersOptions,
  );

  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const handleOnNameClick = useCallback(
    (row: TableRow) => {
      const path = `${row.id}`;
      navigate(path);
    },
    [navigate],
  );

  const onSort = (data: TableSort[]) => {
    setTableSort(data);
  };

  const handleOnAccessChange = useCallback(
    (checked: boolean, row: TableRow) => {
      onAccessChange(checked, +row.id);
    },
    [onAccessChange],
  );

  const handleOnStatusChange = useCallback(
    (checked: boolean, row: TableRow) => {
      onStatusChange(checked, +row.id);
    },
    [onStatusChange],
  );

  return (
    <CustomPageContainer>
      <CustomPageContainer.Header title={t('users')}>
        <Button onClick={handleModalState} variant="primary">
          {t('add_user')}
        </Button>
      </CustomPageContainer.Header>
      <CreateUserModal handleClose={handleModalState} isOpen={isOpen} />
      <FilterSearchBar
        total={users?.totalRows || 0}
        title={t('users')}
        onSearch={handleSearchChange}
        handleInputSearchChange={handleInputSearchChange}
        searchInputValue={searchInputValue}
      />
      <Table
        columns={[
          ...getUsersColumns({
            onNameClick: handleOnNameClick,
            onAccessChange: handleOnAccessChange,
            onStatusChange: handleOnStatusChange,
          }),
          deleteRowColumnConfig({
            disabledMessage: t('delete_user'),
            onDeleteClick: (row) => {
              handleDelete(row?.id as number);
            },
          }),
        ]}
        rows={users?.items ?? []}
        isLoading={isLoadingUsers}
        noResultsLabel={t('no_users_available')}
        withPagination
        totalItems={users?.totalRows}
        totalPages={users?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
      />
    </CustomPageContainer>
  );
};

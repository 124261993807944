import { LoggedLayout } from '@/components';
import { TenantProvider } from '@/components/TenantProvider';
import { UserModel } from '@/modules/Users/domain';
import { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface PrivateRouteProps {
  checkLoginStatus: () => { isAuthenticated: boolean; user?: UserModel };
  unauthorizedRedirectTo: string;
  showSideNav?: boolean;
  children: ReactElement | null;
}

const Redirect = ({ to }: { to: string }) => {
  const location = useLocation();

  return <Navigate to={to} state={{ from: location }} />;
};

export const PrivateRoute = ({
  children,
  checkLoginStatus,
  unauthorizedRedirectTo,
  showSideNav = true,
}: PrivateRouteProps): ReactElement => {
  const { isAuthenticated } = checkLoginStatus();
  const isLogged = isAuthenticated || false;

  if (!children) return <div />;

  return !isLogged ? (
    <Redirect to={unauthorizedRedirectTo} />
  ) : (
    <TenantProvider>
      <LoggedLayout showSideNav={showSideNav}>{children}</LoggedLayout>
    </TenantProvider>
  );
};

import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useDisableWorkflow, useEnableWorkflow, WorkflowKey } from '../queries';

export const useToggleWorkflowStatus = () => {
  const { mutate: enableWorkflow } = useEnableWorkflow();
  const { mutate: disableWorkflow } = useDisableWorkflow();
  const queryClient = useQueryClient();
  const onChange = useCallback(
    (checked: boolean, workflowId: number) => {
      const onSuccess = () => {
        queryClient.invalidateQueries(WorkflowKey.ALL);
      };

      if (checked) {
        enableWorkflow(workflowId, {
          onSuccess,
        });
      } else {
        disableWorkflow(workflowId, {
          onSuccess,
        });
      }
    },
    [queryClient, enableWorkflow, disableWorkflow],
  );

  return { onChange };
};

import { SortDirection } from '@/components/Table/types';
import { companiesRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { CompanyModel } from '../domain';
import { CompanyKeys } from './types';

export const useGetCompanies = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<CompanyModel[]>,
    any,
    DataWithPagination<CompanyModel[]>
  >,
) =>
  useQuery<DataWithPagination<CompanyModel[]>>(
    CompanyKeys.PAGED({ pageNumber, filter, sortBy, sortOrder }),
    () => companiesRepo.getCompanies(pageNumber, filter, sortBy, sortOrder),
    { ...options, cacheTime: 1000 * 60 * 5 },
  );

import { SortDirection } from '@/components/Table/types';
import { businessAreasRepo } from '@/constants/providers';
import { BusinessAreaModel } from '@/modules/BusinessAreas/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { BusinessAreasKey } from './keys';

export const useGetAllBusinessAreasByCompany = (companyId?: number) =>
  useQuery<BusinessAreaModel[]>(
    BusinessAreasKey.BY_COMPANY(companyId),
    () => {
      if (!companyId) return [];
      return businessAreasRepo.getAllBusinessAreasByCompany(companyId);
    },
    {
      enabled: !!companyId,
    },
  );

export const useGetBusinessAreasByCompany = (
  companyId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<BusinessAreaModel[]>,
    any,
    DataWithPagination<BusinessAreaModel[]>
  >,
) =>
  useQuery<DataWithPagination<BusinessAreaModel[]>>(
    BusinessAreasKey.BY_COMPANY_PAGED(companyId, {
      pageNumber,
      filter,
      sortBy,
      sortOrder,
    }),
    () =>
      businessAreasRepo.getBusinessAreasByCompany(
        companyId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );

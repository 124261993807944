import { useCreateOrganization } from '@/modules/Organizations/queries/create-organization';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';
import { OrganizationSchemaType } from '@/modules/Organizations/types';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { OrganizationModal } from '../OrganizationModal';

interface CreateOrganizationModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const CreateOrganizationModal = ({
  isOpen,
  handleClose,
}: CreateOrganizationModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateOrganization();

  const handleConfirm = (data: OrganizationSchemaType) => {
    const formData = new FormData();

    formData.append('description', data.organizationDescription ?? '');
    formData.append('name', data.organizationName);
    formData.append('status', '1');
    data.file && formData.append('file', data.file);

    mutate(formData, {
      onError: () => {
        toast.error(t('error_creating_organization'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(OrganizationKeys.ALL);
        handleClose();
        toast.success(t('success_creating_organization'));
      },
    });
  };

  return (
    <OrganizationModal
      handleClose={handleClose}
      type="create"
      handleOnSubmit={handleConfirm}
      title={t('create_organization')}
      isOpen={isOpen}
      isLoading={isLoading}
    />
  );
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../tailwind.config';

export const useGetChartColorsAndLabel = () => {
  const { t } = useTranslation();

  const americanGreen = config.theme?.extend?.colors?.americanGreen as string;
  const salmonOrange = config.theme?.extend?.colors?.salmonOrange as string;
  const redBellPepper = config.theme?.extend?.colors?.redBellPepper as string;
  const blueNuit = config.theme?.extend?.colors?.blueNuit as string;
  const fennelBlue = config.theme?.extend?.colors?.fennelBlue as string;
  const lightBlue = config.theme.extend.colors.lightBlue as string;
  const trappedDarkness = config.theme?.extend?.colors
    ?.trappedDarkness as string;

  const workflowTimeDistributionChart = useMemo(
    () => [
      {
        id: 'manualTime',
        name: t('manual_labour'),
        fill: fennelBlue,
      },
      {
        id: 'automatedTime',
        name: t('automated'),
        fill: blueNuit,
      },
    ],
    [fennelBlue, blueNuit, t],
  );

  const processTimeDistributionChart = useMemo(
    () => [
      {
        id: 'manualTime',
        name: t('manual_labour'),
        fill: fennelBlue,
      },
      {
        id: 'automatedTime',
        name: t('automated'),
        fill: blueNuit,
      },
    ],
    [fennelBlue, blueNuit, t],
  );

  const rateChart = useMemo(
    () => [
      {
        id: 'successes',
        name: t('success'),
        fill: americanGreen,
      },
      {
        id: 'businessErrors',
        name: t('business_error'),
        fill: salmonOrange,
      },
      {
        id: 'genericErrors',
        name: t('system_error'),
        fill: redBellPepper,
      },
    ],
    [americanGreen, redBellPepper, salmonOrange, t],
  );

  const rateDevInvestChart = useMemo(
    () => [
      {
        id: 'infrastructure',
        name: t('infrastructure'),
        fill: trappedDarkness,
      },
      {
        id: 'maintenance',
        name: t('maintenance'),
        fill: blueNuit,
      },
      {
        id: 'support',
        name: t('support'),
        fill: lightBlue,
      },
      {
        id: 'development',
        name: t('development'),
        fill: fennelBlue,
      },
    ],
    [blueNuit, fennelBlue, lightBlue, t, trappedDarkness],
  );

  return {
    rateChart,
    processTimeDistributionChart,
    workflowTimeDistributionChart,
    rateDevInvestChart,
  };
};

import {
  Button,
  Dropdown,
  FieldInfo,
  FilterBusinessAreaFormGroup,
  Input,
  Modal,
  TextArea,
} from '@/components';
import {
  AutomationSchema,
  AutomationSchemaType,
} from '@/modules/Automations/types/automation-schema';
import { useGetAllBusinessAreasByCompany } from '@/modules/BusinessAreas/queries';
import { useGetAllCompaniesByOrganization } from '@/modules/Companies/queries';
import { useGetAllOrganizations } from '@/modules/Organizations/queries';
import { useGetAllProcessesByBusinessArea } from '@/modules/Processes/queries';
import { useGetUsersBaseInfo } from '@/modules/Users/queries';
import { AuthUserContext } from '@/providers/authUserProvider';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ChangeEvent,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AUTOMATION_PRIORITIES } from '../../utils';

interface AutomationModalProps {
  handleOnSubmit: (e: AutomationSchemaType) => void;
  handleClose: () => void;
  title: string;
  type: 'create' | 'edit';
  isLoading: boolean;
  isOpen: boolean;
  automationName?: string;
  automationCode?: string;
  organizationId?: number;
  companyId?: number;
  businessAreaId?: number;
  processId?: number;
  workflowId?: number;
  tasks?: number[];
  description?: string;
  developerId?: number;
  stageId?: number;
  priority?: string;
  epicId?: string;
  orchestratorProcessId?: string;
  disableFields?: string[];
}

export const AutomationModal = ({
  title,
  isOpen,
  type,
  isLoading,
  handleOnSubmit,
  handleClose,
  automationName,
  automationCode,
  organizationId,
  companyId,
  businessAreaId,
  processId,
  workflowId,
  description,
  developerId,
  stageId,
  priority,
  orchestratorProcessId,
  disableFields,
  epicId,
}: AutomationModalProps): ReactElement => {
  const { t } = useTranslation();
  const {
    clearErrors,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm<AutomationSchemaType>({
    defaultValues: {
      automationName: automationName,
      automationCode: automationCode,
      epicId: epicId,
      organizationId: organizationId,
      companyId: companyId,
      businessAreaId: businessAreaId,
      processId: processId,
      description: description,
      developerId: developerId,
      priority: priority,
      stageId: stageId,
      orchestratorProcessId: orchestratorProcessId,
    },
    resolver: zodResolver(AutomationSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
  });

  const { data: organizations } = useGetAllOrganizations();
  const { data: users } = useGetUsersBaseInfo();
  const currentUser = useContext(AuthUserContext).authUser;

  const organization = watch('organizationId');
  const company = watch('companyId');
  const businessArea = watch('businessAreaId');

  const { data: companies } = useGetAllCompaniesByOrganization(organization);
  const { data: businessAreas } = useGetAllBusinessAreasByCompany(company);
  const { data: processes } = useGetAllProcessesByBusinessArea(businessArea);

  const {
    dropdownOrganizationsOptions,
    dropdownCompaniesOptions,
    dropdownBusinessAreasOptions,
    dropdownProcessesOptions,
    dropdownUsersOptions,
    dropdownStagesOptions,
    dropdownAutomationPriorityOptions,
  } = useMemo(() => {
    const stages = [
      { id: 1, name: 'PddSubmission' },
      { id: 2, name: 'PddApproval' },
      { id: 3, name: 'Development' },
      { id: 4, name: 'TestApproval' },
      { id: 5, name: 'Rollout' },
      { id: 6, name: 'Productive' },
      { id: 7, name: 'Active' },
      { id: 8, name: 'Maintenance' },
      { id: 9, name: 'Inactive' },
    ];

    return {
      dropdownOrganizationsOptions: getDropdownArray(
        organizations?.filter(
          (organization) =>
            organization.status || organization.id === organizationId,
        ) ?? [],
      ),
      dropdownCompaniesOptions: getDropdownArray(
        companies?.filter(
          (company) => company.status || company.id === companyId,
        ) ?? [],
      ),
      dropdownBusinessAreasOptions: getDropdownArray(
        businessAreas?.filter(
          (businessArea) =>
            businessArea.status || businessArea.id === businessAreaId,
        ) ?? [],
      ),
      dropdownProcessesOptions: getDropdownArray(
        processes?.filter(
          (process) => process.status || process.id === processId,
        ) ?? [],
      ),
      dropdownUsersOptions: getDropdownArray(users ?? []),
      dropdownStagesOptions: getDropdownArray(stages),
      dropdownAutomationPriorityOptions: getDropdownArray(
        AUTOMATION_PRIORITIES.map((priority) => ({
          id: priority,
          name: t(priority),
        })),
      ),
    };
  }, [
    organizations,
    companies,
    businessAreas,
    processes,
    users,
    organizationId,
    companyId,
    businessAreaId,
    processId,
    t,
  ]);

  useEffect(() => {
    clearErrors();

    setValue('automationName', automationName || '');
    setValue('automationCode', automationCode || '');
    organizationId && setValue('organizationId', organizationId);
    companyId && setValue('companyId', companyId);
    businessAreaId && setValue('businessAreaId', businessAreaId);
    processId && setValue('processId', processId);
    setValue('description', description || '');
    stageId && setValue('stageId', stageId);
    setValue('priority', priority || '');
    setValue('automationPdd', null);
    setValue('automationSdd', null);
    setValue('automationManualFile', null);
    setValue('orchestratorProcessId', orchestratorProcessId || '');
    if (developerId) {
      setValue('developerId', developerId);
    } else if (currentUser) {
      setValue('developerId', currentUser.id);
    }
    setValue('epicId', epicId || '');
  }, [
    clearErrors,
    setValue,
    isOpen,
    automationName,
    automationCode,
    organizationId,
    companyId,
    businessAreaId,
    processId,
    workflowId,
    description,
    developerId,
    stageId,
    priority,
    orchestratorProcessId,
    currentUser,
    users,
    epicId,
  ]);

  const onSubmit: SubmitHandler<AutomationSchemaType> = (data) => {
    handleOnSubmit(data);
  };

  const handleFileChange =
    (propertyName: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0] || null;

      switch (propertyName) {
        case 'automationPdd':
        case 'automationSdd':
        case 'automationManualFile':
          setValue(propertyName, file);
          break;
        default:
          setValue('automationManualFile', file);
      }
    };

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      className="h-144 w-128"
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2 flex h-112 flex-col overflow-y-auto p-2">
          <FieldInfo
            title={t('automationName')}
            errorText={
              errors.automationName?.message
                ? t(errors.automationName.message)
                : undefined
            }
            hasError={!!errors.automationName?.message}
          >
            <Input
              isDisabled={isLoading}
              className="w-full bg-brightGray italic"
              placeHolder={t('automationName')}
              name="automationName"
              register={register}
            />
          </FieldInfo>
          <FieldInfo
            title={t('orchestrator_process_id')}
            errorText={
              errors.automationName?.message
                ? t(errors.automationName.message)
                : undefined
            }
            hasError={!!errors.automationName?.message}
          >
            <Input
              isDisabled={isLoading}
              className="w-full bg-brightGray italic"
              placeHolder={t('orchestrator_process_id')}
              name="orchestratorProcessId"
              register={register}
            />
          </FieldInfo>
          <FieldInfo
            title={t('jira_epic_id')}
            errorText={
              errors.automationName?.message
                ? t(errors.automationName.message)
                : undefined
            }
            hasError={!!errors.automationName?.message}
          >
            <Input
              isDisabled={isLoading}
              className="w-full bg-brightGray italic"
              placeHolder={t('jira_epic_id')}
              name="epicId"
              register={register}
            />
          </FieldInfo>
          <FieldInfo
            title={t('automationCode')}
            errorText={
              errors.automationCode?.message
                ? t(errors.automationCode.message)
                : undefined
            }
            hasError={!!errors.automationCode?.message}
            hidden={true}
          >
            <Input
              isDisabled={true}
              className="w-full bg-brightGray italic"
              placeHolder={t('automationCode')}
              name="automationCode"
              register={register}
            />
          </FieldInfo>

          <FieldInfo
            title={t('priority')}
            errorText={
              errors.priority?.message ? t(errors.priority.message) : undefined
            }
            hasError={!!errors.priority?.message}
          >
            <Controller
              name="priority"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ...otherFieldProps } }) => (
                <Dropdown
                  {...otherFieldProps.onBlur}
                  className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                  placeholder={t('priority')}
                  options={dropdownAutomationPriorityOptions}
                  onSelect={(val) => onChange(val.value)}
                  value={value}
                />
              )}
            />
          </FieldInfo>
          <FilterBusinessAreaFormGroup<AutomationSchemaType>
            organizationOptions={dropdownOrganizationsOptions}
            companyOptions={dropdownCompaniesOptions}
            businessAreaOptions={dropdownBusinessAreasOptions}
            disableFields={disableFields}
            errors={errors}
            control={control}
          />
          <FieldInfo
            title={t('process')}
            errorText={
              errors.processId?.message
                ? t(errors.processId.message)
                : undefined
            }
            hasError={!!errors.processId?.message}
          >
            <Controller
              name="processId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ...otherFieldProps } }) => (
                <Dropdown
                  {...otherFieldProps.onBlur}
                  className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                  placeholder={t('process')}
                  options={dropdownProcessesOptions}
                  onSelect={(val) => onChange(val.value)}
                  value={value}
                />
              )}
            />
          </FieldInfo>
          <FieldInfo
            title={t('description')}
            errorText={
              errors.description?.message
                ? t(errors.description.message)
                : undefined
            }
            hasError={!!errors.description?.message}
          >
            <TextArea
              isDisabled={isLoading}
              className="h-24 w-full bg-brightGray italic"
              placeHolder={t('description')}
              name="description"
              register={register}
            />
          </FieldInfo>
          <FieldInfo
            title={t('developer')}
            errorText={
              errors.developerId?.message
                ? t(errors.developerId.message)
                : undefined
            }
            hasError={!!errors.developerId?.message}
          >
            <Controller
              name="developerId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ...otherFieldProps } }) => (
                <Dropdown
                  {...otherFieldProps.onBlur}
                  className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                  placeholder={t('developer')}
                  options={dropdownUsersOptions}
                  onSelect={(val) => onChange(val.value)}
                  value={value}
                />
              )}
            />
          </FieldInfo>
          <FieldInfo
            title={t('stage')}
            errorText={
              errors.stageId?.message ? t(errors.stageId.message) : undefined
            }
            hasError={!!errors.stageId?.message}
          >
            <Controller
              name="stageId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ...otherFieldProps } }) => (
                <Dropdown
                  {...otherFieldProps.onBlur}
                  className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                  placeholder={t('stage')}
                  options={dropdownStagesOptions}
                  onSelect={(val) => onChange(val.value)}
                  value={value}
                />
              )}
            />
          </FieldInfo>
          {type === 'create' && (
            <>
              <FieldInfo
                title={t('automationPdd')}
                hasError={!!errors.automationPdd?.message}
              >
                <div className="relative w-full bg-brightGray capitalize">
                  <Controller
                    name="automationPdd"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          id="file-upload-pdd"
                          type="file"
                          accept=".pdf, .doc, .docx"
                          className="absolute inset-0 z-50 w-full opacity-0"
                          onChange={handleFileChange('automationPdd')}
                          disabled={isLoading}
                        />
                        <label
                          htmlFor="file-upload-pdd"
                          className="flex h-10 items-center justify-center rounded-md border border-dashed border-gray-300 bg-white p-2"
                        >
                          <span className="cursor-pointer text-sm font-bold underline">
                            {field.value ? field.value.name : t('choose_file')}
                          </span>
                        </label>
                      </>
                    )}
                  />
                </div>
              </FieldInfo>
              <FieldInfo
                title={t('automationSdd')}
                hasError={!!errors.automationSdd?.message}
              >
                <div className="relative w-full bg-brightGray capitalize">
                  <Controller
                    name="automationSdd"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          id="file-upload-sdd"
                          type="file"
                          accept=".pdf, .doc, .docx"
                          className="absolute inset-0 z-50 w-full opacity-0"
                          onChange={handleFileChange('automationSdd')}
                          disabled={isLoading}
                        />
                        <label
                          htmlFor="file-upload-sdd"
                          className="flex h-10 items-center justify-center rounded-md border border-dashed border-gray-300 bg-white p-2"
                        >
                          <span className="cursor-pointer text-sm font-bold underline">
                            {field.value ? field.value.name : t('choose_file')}
                          </span>
                        </label>
                      </>
                    )}
                  />
                </div>
              </FieldInfo>
              <FieldInfo
                title={t('automation_manual_file')}
                hasError={!!errors.automationManualFile?.message}
              >
                <div className="relative w-full bg-brightGray capitalize">
                  <Controller
                    name="automationManualFile"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          id="file-upload-manual"
                          type="file"
                          accept=".pdf, .doc, .docx"
                          className="absolute inset-0 z-50 w-full opacity-0"
                          onChange={handleFileChange('automationManualFile')}
                          disabled={isLoading}
                        />
                        <label
                          htmlFor="file-upload-manual"
                          className="flex h-10 items-center justify-center rounded-md border border-dashed border-gray-300 bg-white p-2"
                        >
                          <span className="cursor-pointer text-sm font-bold underline">
                            {field.value ? field.value.name : t('choose_file')}
                          </span>
                        </label>
                      </>
                    )}
                  />
                </div>
              </FieldInfo>
            </>
          )}
        </div>
        <div className="mt-4 flex justify-between">
          <Button
            isDisabled={isLoading}
            onClick={handleClose}
            variant="outline"
            className="h-9 w-24"
          >
            {t('cancel')}
          </Button>
          <Button
            isDisabled={isLoading}
            buttonType="submit"
            className="h-9 w-24"
          >
            {type === 'create' ? t('create') : t('confirm')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

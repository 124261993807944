import { SortDirection } from '@/components/Table/types';
import { automationsRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AutomationModel } from '../domain';
import { AutomationKey } from './keys';

export const useGetAutomations = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<AutomationModel[]>,
    any,
    DataWithPagination<AutomationModel[]>
  >,
) =>
  useQuery<DataWithPagination<AutomationModel[]>>(
    AutomationKey.LIST_PAGED({ pageNumber, filter, sortBy, sortOrder }),
    () =>
      automationsRepo.getAllAutomations(pageNumber, filter, sortBy, sortOrder),
    { ...options, cacheTime: 1000 * 60 * 5 },
  );

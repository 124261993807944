import { z } from 'zod';

export const CompanySchema = z.object({
  organizationId: z.coerce
    .number({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  companyName: z.string().min(1, { message: 'error_field_required' }),
  companyCode: z.string().min(1, { message: 'error_field_required' }),
  nif: z.string().length(9, { message: 'error_field_required_length' }),
  addressLineOne: z
    .string({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  addressLineTwo: z.string(),
  postCode: z
    .string({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  country: z
    .string({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  city: z
    .string({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  jiraLink: z.string().optional(),
});

export type FormCompanySchemaType = z.infer<typeof CompanySchema>;

export interface CompanySchemaType extends FormCompanySchemaType {
  id?: number;
  fileId?: number;
  file?: File;
}

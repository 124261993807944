import { TableColumn, TableRow } from '@/components/Table/types';
import { AccessColumn } from '@/components/UserAccessColumn';
import { Trans } from 'react-i18next';

type AccessColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
  onChange: (checked: boolean, row: T) => void;
};

export const accessColumnConfig = <T extends TableRow>({
  options,
  onChange,
}: AccessColumnConfig<T>): TableColumn<T> => ({
  id: 'access',
  acessorKey: 'access',
  label: (
    <span className="capitalize">
      <Trans i18nKey="access" />
    </span>
  ),
  render: (value, row) => (
    <AccessColumn onChange={onChange} value={value} row={row} />
  ),
  sortable: true,
  ...options,
});

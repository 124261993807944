import { ProcessKey, useGetProcess } from '@/modules/Processes/queries';
import { useUpdateProcess } from '@/modules/Processes/queries/update-process';
import { ProcessSchemaType } from '@/modules/Processes/types/process-schema';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ProcessModal } from '../ProcessModal/ProcessModal';

interface EditProcessModalProps {
  isOpen: boolean;
  id: number;
  handleClose: () => void;
}

export const EditProcessModal = ({
  isOpen,
  id,
  handleClose,
}: EditProcessModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useUpdateProcess();
  const { data: process, isLoading: isLoadingProcess } = useGetProcess(id);

  const handleConfirm = (data: ProcessSchemaType) => {
    const convertedData = {
      id: data.id ?? process?.id ?? 0,
      name: data.processName || process?.name,
      description: data.description || process?.description,
      businessAreaId: Number(data.businessAreaId) || process?.businessAreaId,
      status: true,
      stage: 1,
      workflows: data.workflows.map(
        (workflow: string) => Number(workflow) || workflow,
      ),
      ownerId: Number(data.processOwnerId) || process?.owner?.id,
      code: process?.code,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_update_process'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(ProcessKey.ALL);
        handleClose();
        toast.success(t('success_update_process'));
      },
    });
  };

  return (
    <ProcessModal
      disableFields={['organizationId', 'companyId', 'businessAreaId']}
      handleClose={handleClose}
      type="edit"
      handleOnSubmit={handleConfirm}
      title={t('edit_process')}
      isOpen={isOpen}
      isLoading={isLoading || isLoadingProcess}
      processName={process?.name}
      description={process?.description}
      organizationId={process?.organizationId}
      companyId={process?.companyId}
      businessAreaId={process?.businessAreaId}
      workflows={process?.workflows}
      processOwnerId={process?.owner?.id}
    />
  );
};

import { SortDirection } from '@/components/Table/types';
import { infrastructuresRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { InfrastructureKeys } from './types';
import { InfrastructureCostModel } from '../domain';

export const useGetInfrastructureCostsByAutomation = (
  automationId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<InfrastructureCostModel[]>,
    any,
    DataWithPagination<InfrastructureCostModel[]>
  >,
) =>
  useQuery<DataWithPagination<InfrastructureCostModel[]>>(
    [
      InfrastructureKeys.GET_INFRASTRUCTURE_COSTS_BY_AUTOMATION,
      { automationId, pageNumber, filter, sortBy, sortOrder },
    ],
    () =>
      infrastructuresRepo.getInfrastructureCostsByAutomation(
        automationId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );

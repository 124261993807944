import {
  Button,
  Dropdown,
  Input,
  Modal,
  SelectDropdownOption,
} from '@/components';
import { CompanySchema, CompanySchemaType } from '@/modules/Companies/types';
import { useGetAllOrganizations } from '@/modules/Organizations/queries';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputWithError from '../../../../../../components/InputWithError/InputWithError';
import InputWithTitle from '../../../../../../components/InputWithTitle/InputWithTitle';
import { ProfileAvatarWithActions } from '@/components/ProfileAvatarWithActions';

interface CompanyModalProps {
  handleOnSubmit: (e: CompanySchemaType) => void;
  handleClose: () => void;
  title: string;
  type: 'create' | 'edit';
  isLoading: boolean;
  isOpen: boolean;
  organizationId?: number;
  companyName?: string;
  companyCode?: string;
  nif?: string;
  addressLineOne?: string;
  addressLineTwo?: string;
  postCode?: string;
  country?: string;
  city?: string;
  disableFields?: string[];
  jiraLink?: string;
  profileImageFileId?: number;
}

export const CompanyModal = ({
  title,
  isOpen,
  type,
  isLoading,
  handleOnSubmit,
  handleClose,
  organizationId,
  companyName,
  companyCode,
  nif,
  addressLineOne,
  addressLineTwo,
  postCode,
  country,
  city,
  disableFields,
  jiraLink,
  profileImageFileId,
}: CompanyModalProps): ReactElement => {
  const { t } = useTranslation();
  const {
    clearErrors,
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
    control,
    watch,
  } = useForm<CompanySchemaType>({
    defaultValues: {
      organizationId: organizationId,
      companyName: companyName || '',
      companyCode: companyCode || '',
      nif: nif?.toString() || '',
      addressLineOne: addressLineOne || '',
      addressLineTwo: addressLineTwo || '',
      postCode: postCode || '',
      country: country || '',
      city: city || '',
    },
    resolver: zodResolver(CompanySchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
  });

  const { data: organizations } = useGetAllOrganizations();

  const [initialProfileImageFileId, setInitialProfileImageFileId] = useState<
    number | undefined
  >(profileImageFileId);

  const [profileImageFile, setProfileImageFile] = useState<File>();

  const dropdownOptions: Array<SelectDropdownOption<number>> = useMemo(
    () =>
      getDropdownArray(
        organizations?.filter(
          (organization) =>
            organization.status || organizationId === organization.id,
        ) ?? [],
      ),
    [organizationId, organizations],
  );

  useEffect(() => {
    clearErrors();

    organizationId && setValue('organizationId', organizationId);
    setValue('companyName', companyName || '');
    setValue('companyCode', companyCode || '');
    setValue('nif', nif?.toString() || '');
    setValue('addressLineOne', addressLineOne || '');
    setValue('addressLineTwo', addressLineTwo || '');
    setValue('postCode', postCode || '');
    setValue('country', country || '');
    setValue('city', city || '');
    setValue('jiraLink', jiraLink || '');
  }, [
    clearErrors,
    setValue,
    isOpen,
    organizationId,
    companyName,
    companyCode,
    nif,
    addressLineOne,
    addressLineTwo,
    postCode,
    country,
    city,
    jiraLink,
  ]);

  const onSubmit: SubmitHandler<CompanySchemaType> = (data) => {
    trigger();
    if (!isValid) {
      return;
    }

    handleOnSubmit({
      ...data,
      fileId: initialProfileImageFileId,
      file: profileImageFile,
    });
  };

  const handleOnClose = useCallback(() => {
    setInitialProfileImageFileId(profileImageFileId);
    setProfileImageFile(undefined);
    handleClose();
  }, [handleClose, profileImageFileId]);

  const handlePictureFileOnChange = (file: File) => {
    setProfileImageFile(file);
    setInitialProfileImageFileId(undefined);
  };

  const handlePictureOnRemove = useCallback(() => {
    setProfileImageFile(undefined);
    setInitialProfileImageFileId(undefined);
  }, []);

  const imageSrc = useMemo(() => {
    return profileImageFile && URL.createObjectURL(profileImageFile);
  }, [profileImageFile]);

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      className="h-144 w-128"
      handleClose={handleOnClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2 flex h-112 flex-col overflow-y-auto p-2">
          <ProfileAvatarWithActions
            avatarName={watch('companyName')}
            handleOnFileChange={handlePictureFileOnChange}
            handleOnFileRemove={handlePictureOnRemove}
            avatarImageSrc={imageSrc}
            avatarImageId={initialProfileImageFileId}
          />
          <InputWithTitle title={t('companyName')}>
            <InputWithError
              errorText={
                errors.companyName?.message
                  ? t(errors.companyName.message)
                  : undefined
              }
              hasError={!!errors.companyName?.message}
            >
              <Input
                isDisabled={isLoading}
                className="w-full bg-brightGray italic"
                placeHolder={t('companyName')}
                name="companyName"
                register={register}
              />
            </InputWithError>
          </InputWithTitle>
          <InputWithTitle title={t('companyCode')}>
            <InputWithError
              errorText={
                errors.companyCode?.message
                  ? t(errors.companyCode.message)
                  : undefined
              }
              hasError={!!errors.companyCode?.message}
            >
              <Input
                isDisabled={isLoading}
                className="w-full bg-brightGray italic"
                placeHolder={t('companyCode')}
                name="companyCode"
                register={register}
              />
            </InputWithError>
          </InputWithTitle>
          <InputWithTitle title={t('jira_link')}>
            <InputWithError
              errorText={
                errors.jiraLink?.message
                  ? t(errors.jiraLink.message)
                  : undefined
              }
              hasError={!!errors.jiraLink?.message}
            >
              <Input
                isDisabled={isLoading}
                className="w-full bg-brightGray italic"
                placeHolder={t('jira_link')}
                name="jiraLink"
                register={register}
              />
            </InputWithError>
          </InputWithTitle>
          <InputWithTitle title={t('organization')}>
            <InputWithError
              errorText={
                errors.organizationId?.message
                  ? t(errors.organizationId.message)
                  : undefined
              }
              hasError={!!errors.organizationId?.message}
            >
              <Controller
                name="organizationId"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, name, value, ...otherFieldProps },
                }) => (
                  <Dropdown
                    {...otherFieldProps.onBlur}
                    className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                    placeholder={t('organization')}
                    options={dropdownOptions}
                    onSelect={(val) => onChange(val.value)}
                    value={value}
                    disabled={disableFields && disableFields.includes(name)}
                  />
                )}
              />
            </InputWithError>
          </InputWithTitle>
          <InputWithTitle title={t('nif_number')}>
            <InputWithError
              errorText={
                errors.nif?.message
                  ? t(errors.nif.message, { length: '9' })
                  : undefined
              }
              hasError={!!errors.nif?.message}
            >
              <Input
                isDisabled={isLoading}
                className="w-full bg-brightGray capitalize italic"
                placeHolder={t('enter_nif')}
                register={register}
                name="nif"
              />
            </InputWithError>
          </InputWithTitle>
          <InputWithTitle title={t('address')}>
            <InputWithError
              errorText={
                errors.addressLineOne?.message
                  ? t(errors.addressLineOne?.message)
                  : undefined
              }
              hasError={!!errors.addressLineOne?.message}
            >
              <Input
                isDisabled={isLoading}
                className="w-full bg-brightGray italic"
                placeHolder={t('address_line_x', { value: 1 })}
                register={register}
                name="addressLineOne"
              />
            </InputWithError>
          </InputWithTitle>
          <InputWithError
            errorText={
              errors.addressLineTwo?.message
                ? t(errors.addressLineTwo.message)
                : undefined
            }
            hasError={!!errors.addressLineTwo?.message}
          >
            <Input
              isDisabled={isLoading}
              className="mt-2 w-full bg-brightGray italic"
              placeHolder={t('address_line_x', { value: 2 })}
              register={register}
              name="addressLineTwo"
            />
          </InputWithError>
          <div className="flex gap-4">
            <InputWithError
              errorText={
                errors.postCode?.message
                  ? t(errors.postCode.message)
                  : undefined
              }
              hasError={!!errors.postCode?.message}
            >
              <Input
                isDisabled={isLoading}
                className="mt-2 w-full bg-brightGray capitalize italic"
                placeHolder={t('postcode')}
                register={register}
                name="postCode"
              />
            </InputWithError>
            <InputWithError
              errorText={
                errors.city?.message ? t(errors.city.message) : undefined
              }
              hasError={!!errors.city?.message}
            >
              <Input
                isDisabled={isLoading}
                className="mt-2 w-full bg-brightGray capitalize italic"
                placeHolder={t('city')}
                register={register}
                name="city"
              />
            </InputWithError>
            <InputWithError
              errorText={
                errors.country?.message ? t(errors.country.message) : undefined
              }
              hasError={!!errors.country?.message}
            >
              <Input
                isDisabled={isLoading}
                className="mt-2 w-full bg-brightGray capitalize italic"
                placeHolder={t('country')}
                register={register}
                name="country"
              />
            </InputWithError>
          </div>
        </div>
        <div className="mt-4 flex justify-between">
          <Button
            isDisabled={isLoading}
            onClick={handleOnClose}
            variant="outline"
            className="h-9 w-24"
          >
            {t('cancel')}
          </Button>
          <Button
            isDisabled={isLoading}
            buttonType="submit"
            className="h-9 w-24"
          >
            {type === 'create' ? t('create') : t('confirm')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

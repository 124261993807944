import { ProcessModel } from '../domain';
import { CreateProcessJson, EditProcessJson } from '../types';
import { ApiResponse, DataWithPagination } from '@/shared/types';

export abstract class ProcessRepository {
  abstract getAllProcesses(
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<ProcessModel[]>>;
  abstract createProcess(data: CreateProcessJson): Promise<ProcessModel>;
  abstract getProcess(id: number): Promise<ProcessModel>;
  abstract enableProcess(id: number): Promise<ApiResponse<unknown>>;
  abstract disableProcess(id: number): Promise<ApiResponse<unknown>>;
  abstract updateProcess(data: EditProcessJson): Promise<ApiResponse<unknown>>;
  abstract getProcessesByBusinessArea(
    businessAreaId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<ProcessModel[]>>;
  abstract getAllProcessesByBusinessArea(
    businessAreaId: number,
  ): Promise<ProcessModel[]>;
  abstract getProcessesByCompany(
    companyId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<ProcessModel[]>>;
  abstract getProcessesByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<ProcessModel[]>>;
  abstract getProcessesByUser(
    userId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<ProcessModel[]>>;
  abstract deleteProcess(Id: number): Promise<ApiResponse<unknown>>;
}

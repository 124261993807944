import { SortDirection } from '@/components/Table/types';
import { companiesRepo } from '@/constants/providers';
import { CompanyModel } from '@/modules/Companies/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { CompanyKeys } from './types';

export const useGetCompaniesByUser = (
  userId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<CompanyModel[]>,
    any,
    DataWithPagination<CompanyModel[]>
  >,
) =>
  useQuery<DataWithPagination<CompanyModel[]>>(
    CompanyKeys.BY_USER_PAGED(userId, {
      pageNumber,
      filter,
      sortBy,
      sortOrder,
    }),
    () =>
      companiesRepo.getCompaniesByUser(
        userId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );

import { SortDirection } from '@/components/Table/types';
import { companiesRepo } from '@/constants/providers';
import { CompanyModel } from '@/modules/Companies/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { CompanyKeys } from './types';

export const useGetCompaniesByInfrastructureCost = (
  infrastructureCostId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<CompanyModel[]>,
    any,
    DataWithPagination<CompanyModel[]>
  >,
) =>
  useQuery<DataWithPagination<CompanyModel[]>>(
    [
      CompanyKeys.BY_INFRASTRUCTURE_COST_PAGED(infrastructureCostId, {
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      }),
    ],
    () =>
      companiesRepo.getCompaniesByInfrastructureCost(
        infrastructureCostId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );

export const useGetEligibleCompaniesByInfrastructureCost = (
  infrastructureCostId?: number,
) =>
  useQuery<CompanyModel[]>(
    CompanyKeys.ELIGIBLE_BY_INFRASTRUCTURE_COST(infrastructureCostId),
    () => {
      if (!infrastructureCostId) return [];
      return companiesRepo.getEligibleCompaniesByInfrastructureCost(
        infrastructureCostId,
      );
    },
    {
      enabled: !!infrastructureCostId,
    },
  );

import {
  AmountJson,
  ApiResponseInfrastructureCostAmounts,
  ApiResponseInfrastructureCosts,
  InfrastructureCostBaseInfoJson,
  InfrastructureCostJson,
  PagedInfrastructureCostAmounts,
  PagedInfrastructureCosts,
} from '../types';
import {
  AmountModel,
  InfrastructureCostBaseInfoModel,
  InfrastructureCostModel,
} from '../domain/infrastructure-model';
import { mapPagination } from '@/shared/mappers';

export const mapInfrastructureCostJsonToInfrastructureCostModel = (
  infrastructureCostJson?: InfrastructureCostJson,
): InfrastructureCostModel => ({
  id: infrastructureCostJson?.id ?? 0,
  company: infrastructureCostJson?.company ?? '',
  organization: infrastructureCostJson?.organization ?? '',
  costTypeId: infrastructureCostJson?.costTypeId ?? 0,
  costType: infrastructureCostJson?.costType ?? '',
  name: infrastructureCostJson?.name ?? '',
  startDate: infrastructureCostJson?.startDate ?? new Date(),
  endDate: infrastructureCostJson?.endDate,
  amount: infrastructureCostJson?.amount ?? 0,
  amountRecurrence: infrastructureCostJson?.amountRecurrence ?? '',
  description: infrastructureCostJson?.description ?? '',
  status: infrastructureCostJson?.status ?? false,
  organizationsCount: infrastructureCostJson?.organizationsCount ?? 0,
  companiesCount: infrastructureCostJson?.companiesCount ?? 0,
  automationsCount: infrastructureCostJson?.automationsCount ?? 0,
  amountHistoryCount: infrastructureCostJson?.amountHistoryCount ?? 0,
});

export const infrastructureCostsWithPaginationMapper = (
  infrastructureCostJson: ApiResponseInfrastructureCosts<PagedInfrastructureCosts>,
) => {
  const { data } = infrastructureCostJson;
  return {
    items: infrastructureCostsMapper(data.infrastructureCosts.items),
    ...mapPagination(data.infrastructureCosts),
  };
};

export const infrastructureCostsMapper = (
  infrastructureCostJson: Array<InfrastructureCostJson>,
): Array<InfrastructureCostModel> =>
  infrastructureCostJson.map(
    mapInfrastructureCostJsonToInfrastructureCostModel,
  );

export const infrastructureCostBaseInfoMapper = (
  organizationBaseInfoJson: InfrastructureCostBaseInfoJson,
): InfrastructureCostBaseInfoModel => ({
  id: organizationBaseInfoJson.id ?? 0,
  name: organizationBaseInfoJson.name ?? '',
});

export const infrastructureCostAmountsWithPaginationMapper = (
  infrastructureCostJson: ApiResponseInfrastructureCostAmounts<PagedInfrastructureCostAmounts>,
) => {
  const { data } = infrastructureCostJson;
  return {
    items: infrastructureCostsMapper(data.amountHistory.items),
    ...mapPagination(data.amountHistory),
  };
};

export const infrastructureCostAmountsMapper = (
  amountJson: Array<AmountJson>,
): Array<AmountModel> => amountJson.map(mapAmountJsonToAmountModel);

export const mapAmountJsonToAmountModel = (
  amountJson?: AmountJson,
): AmountModel => ({
  id: amountJson?.id ?? 0,
  amount: amountJson?.amount ?? 0,
  startDate: amountJson?.startDate ?? new Date(),
  endDate: amountJson?.endDate ?? undefined,
});

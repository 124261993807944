import { Card } from '@/components/Card';
import { FileIcon, TrashIcon } from '@/components/Icons';
import { queryClient } from '@/constants/query-client';
import { AutomationModel, FileModel } from '@/modules/Automations/domain';
import {
  useAddDocument,
  useDeleteDocument,
} from '@/modules/Automations/queries';
import { AutomationKey } from '@/modules/Automations/queries/keys';
import { DownloadButton } from '@/modules/Files/components/DownloadButton';
import { t } from 'i18next';
import { ChangeEvent } from 'react';
import { toast } from 'react-toastify';

interface FileCardProps {
  title: string;
  files?: Array<FileModel>;
  isLoading?: boolean;
  automation?: AutomationModel;
  category: string;
}

export const FileCard = ({
  title,
  files,
  isLoading,
  automation,
  category,
}: FileCardProps) => {
  const { mutate: addDocument } = useAddDocument();
  const { mutate: deleteDocument } = useDeleteDocument();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const selectedFile = e.target.files[0];
      handleAddDocument(selectedFile);
    }
  };

  const handleAddDocument = async (file: File) => {
    const formData = new FormData();
    formData.append('id', (automation?.id ?? '').toString());
    formData.append('category', category);
    formData.append('file', file);

    addDocument(formData, {
      onError: () => {
        toast.error(t('error_adding_document'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(AutomationKey.ALL);
        toast.success(t('success_adding_document'));
      },
    });
  };

  const handleDeleteDocument = async (
    fileId: string,
    automationFileId: string,
  ) => {
    const convertedData = {
      fileId: Number(fileId),
      automationFileId: Number(automationFileId),
    };
    deleteDocument(convertedData, {
      onError: () => {
        toast.error(t('error_deleting_document'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(AutomationKey.ALL);
        toast.success(t('success_deleting_document'));
      },
    });
  };

  return (
    <Card
      title={title}
      footer={
        <div className="pointer flex cursor-pointer flex-row gap-1 text-shinyBlue">
          +
          <label
            htmlFor={`file-upload-${category}`}
            className="text-xs font-black underline"
          >
            {t('add_document')}
          </label>
          <input
            id={`file-upload-${category}`}
            type="file"
            onChange={handleFileChange}
            className="hidden"
          />
        </div>
      }
      isLoading={isLoading}
    >
      <div className="flex flex-col gap-2">
        {files?.map((file) => (
          <span key={file.id} className="items flex flex-row gap-2">
            <FileIcon />
            <DownloadButton id={file.id} name={file.name}>
              {file.name}
            </DownloadButton>
            <TrashIcon
              className="cursor-pointer text-rinseGray"
              onClick={() =>
                handleDeleteDocument(file.id, file.automationFileId)
              }
            />
          </span>
        ))}
      </div>
    </Card>
  );
};

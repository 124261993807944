import { useCreateAutomation } from '@/modules/Automations/queries';
import { AutomationKey } from '@/modules/Automations/queries/keys';
import { AutomationSchemaType } from '@/modules/Automations/types/automation-schema';
import { BusinessAreasKey } from '@/modules/BusinessAreas/queries/keys';
import { CompanyKeys } from '@/modules/Companies/queries';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';
import { TaskKey } from '@/modules/Tasks/queries';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AutomationModal } from '../AutomationModal';

interface CreateAutomationModalProps {
  isOpen: boolean;
  handleClose: () => void;
  organizationId?: number;
  companyId?: number;
  disableFields?: string[];
  businessAreaId?: number;
}

export const CreateAutomationModal = ({
  isOpen,
  handleClose,
  organizationId,
  disableFields,
  companyId,
  businessAreaId,
}: CreateAutomationModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateAutomation();

  const handleConfirm = (data: AutomationSchemaType) => {
    const convertedData = new FormData();
    convertedData.append('name', data.automationName || '');
    convertedData.append(
      'orchestratorProcessId',
      data.orchestratorProcessId || '',
    );
    convertedData.append(
      'businessAreaId',
      data.businessAreaId?.toString() || '',
    );
    convertedData.append('processId', data.processId?.toString() || '');
    convertedData.append('description', data.description || '');
    convertedData.append('priority', data.priority || '');
    data.developerId &&
      convertedData.append('developerId', data.developerId.toString());
    data.stageId && convertedData.append('stageId', data.stageId.toString());
    convertedData.append('priority', data.priority || '');
    convertedData.append('automationPdd', data.automationPdd || null);
    convertedData.append('automationSdd', data.automationSdd || null);
    convertedData.append('epicId', data.epicId || '');
    convertedData.append(
      'automationManualFile',
      data.automationManualFile || null,
    );

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_creating_automation'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(AutomationKey.ALL);
        queryClient.invalidateQueries(OrganizationKeys.ALL);
        queryClient.invalidateQueries(CompanyKeys.ALL);
        queryClient.invalidateQueries(BusinessAreasKey.ALL);
        queryClient.invalidateQueries(TaskKey.ALL);

        handleClose();
        toast.success(t('success_creating_automation'));
      },
    });
  };
  return (
    <AutomationModal
      handleClose={handleClose}
      type="create"
      handleOnSubmit={handleConfirm}
      title={t('create_automation')}
      isOpen={isOpen}
      isLoading={isLoading}
      organizationId={organizationId}
      companyId={companyId}
      disableFields={disableFields}
      businessAreaId={businessAreaId}
    />
  );
};

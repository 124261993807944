import { mapPagination } from '@/shared/mappers';
import { BusinessAreaPagination } from '@/shared/types';
import { BusinessAreaModel } from '../domain';
import {
  ApiResponseBusinessAreas,
  BusinessAreaJson,
  PagedBusinessAreas,
} from '../types';

export const mapBusinessAreaJsonToBusinessAreaModel = (
  businessAreaJson?: BusinessAreaJson,
): BusinessAreaModel => ({
  id: businessAreaJson?.id ?? 0,
  name: businessAreaJson?.name ?? '',
  status: businessAreaJson?.status ?? true,
  code: businessAreaJson?.code ?? '',
  companyId: businessAreaJson?.companyId ?? 0,
  company: businessAreaJson?.company ?? '',
  organizationId: businessAreaJson?.organizationId ?? 0,
  organizationName: businessAreaJson?.organization ?? '',
  usersCount: businessAreaJson?.usersCount ?? 0,
  processesCount: businessAreaJson?.processesCount ?? 0,
  automationsCount: businessAreaJson?.automationsCount ?? 0,
  workflowsCount: businessAreaJson?.workflowsCount ?? 0,
  tasksCount: businessAreaJson?.tasksCount ?? 0,
  predictedManualTime: businessAreaJson?.predictedManualTime ?? 0,
  predictedAverageManualTime: businessAreaJson?.predictedAverageManualTime ?? 0,
  automationRunTime: businessAreaJson?.automationRunTime ?? 0,
  averageAutomationRunTime: businessAreaJson?.averageAutomationRunTime ?? 0,
  totalInvestment: businessAreaJson?.totalInvestment ?? 0,
  totalReturn: businessAreaJson?.totalReturn ?? 0,
  averageCost: businessAreaJson?.averageCost ?? 0,
  averageManualCost: businessAreaJson?.averageManualCost ?? 0,
  profileType: businessAreaJson?.profileType ?? '',
  canDelete: businessAreaJson?.canDelete ?? false,
  businessAreas:
    (businessAreaJson?.businessAreas &&
      businessAreaJson?.businessAreas.map(
        mapBusinessAreaJsonToBusinessAreaModel,
      )) ??
    [],
  parentBusinessAreaId: businessAreaJson?.parentBusinessAreaId,
});

export const businessAreasWithPaginationMapper = (
  businessAreaJson: ApiResponseBusinessAreas<PagedBusinessAreas>,
): BusinessAreaPagination<BusinessAreaModel[]> => {
  const { data } = businessAreaJson;
  return {
    items: businessAreasMapper(data.businessAreas.items),
    ...mapPagination(data.businessAreas),
    totalBusinessAreas: data.businessAreas.totalBusinessAreas,
  };
};

export const businessAreasMapper = (
  businessAreasJson: Array<BusinessAreaJson>,
): Array<BusinessAreaModel> =>
  businessAreasJson.map(mapBusinessAreaJsonToBusinessAreaModel);

import { SortDirection } from '@/components/Table/types';
import { tasksRepo } from '@/constants/providers';
import { TaskModel } from '@/modules/Tasks/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { TaskKey } from './keys';

export const useGetTasksByBusinessArea = (
  businessAreadId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<TaskModel[]>,
    any,
    DataWithPagination<TaskModel[]>
  >,
) =>
  useQuery<DataWithPagination<TaskModel[]>>(
    TaskKey.BY_BUSINESS_AREA_PAGED(businessAreadId, {
      pageNumber,
      filter,
      sortBy,
      sortOrder,
    }),
    () =>
      tasksRepo.getTasksByBusinessArea(
        businessAreadId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );

import { UserModel } from '@/modules/Users/domain';
import React, { createContext, useState } from 'react';

interface AuthUserContextValue {
  authUser?: UserModel;
  setAuthUser: (user: UserModel) => void;
}

export const AuthUserContext = createContext<AuthUserContextValue>({
  setAuthUser: () => {},
});

export const AuthUserProvider = ({
  children,
  user,
}: {
  children: React.ReactNode;
  user?: UserModel;
}) => {
  const [authUser, setAuthUser] = useState<UserModel | undefined>(user);
  return (
    <AuthUserContext.Provider value={{ authUser, setAuthUser }}>
      {children}
    </AuthUserContext.Provider>
  );
};

import { PropsWithChildren, ReactElement } from 'react';
import { SideNavigation, ToolIcon } from '../';
import { TenantProvider } from '../TenantProvider';
import { useTranslation } from 'react-i18next';
import { Environment } from '@/constants';

type LoggedLayoutProps = {
  showSideNav?: boolean;
};

export const LoggedLayout = ({
  showSideNav,
  children,
}: PropsWithChildren<LoggedLayoutProps>): ReactElement => {
  const { t } = useTranslation();

  return (
    <TenantProvider>
      <div className="flex h-screen w-screen overflow-hidden">
        {showSideNav && <SideNavigation />}
        <main className="flex w-full flex-grow flex-col overflow-auto scroll-smooth">
          {import.meta.env.MODE.toLowerCase() !== Environment.Production && (
            <div className="fixed top-0 z-50 flex w-full items-center bg-blue-100 px-16 py-1 text-blue-500">
              <ToolIcon className="ml-2" />
              <span className="ml-2 text-md font-semibold">{t('notice')}:</span>
              <span className="ml-1 text-sm font-normal">
                {t('testing_message')}
              </span>
            </div>
          )}
          {children}
        </main>
      </div>
    </TenantProvider>
  );
};

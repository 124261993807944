import {
  AutomationsTab,
  Button,
  ChevronLeft,
  CustomPageContainer,
  ProcessesTab,
  TabItemProps,
  TableWithFilter,
  TasksTab,
  UsersTab,
  WorkflowsTab,
} from '@/components';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { TabContainer } from '@/components/TabContainer';
import { TabsWrapper } from '@/components/TabsWrapper';
import { queryClient } from '@/constants';
import { HttpError } from '@/core/http';
import { RoutePath } from '@/core/router/route-paths';
import { AutomationModel } from '@/modules/Automations/domain';
import { getAutomationColumnsNamedMapped } from '@/modules/Automations/pages/AutomationsPage/utils';
import { useGetAutomationsByBusinessArea } from '@/modules/Automations/queries';
import { getProcessesColumnsNamedMapped } from '@/modules/Processes';
import { ProcessModel } from '@/modules/Processes/domain';
import { useGetProcessesByBusinessArea } from '@/modules/Processes/queries';
import { TaskModel } from '@/modules/Tasks/domain';
import { getTasksColumnsNamedMapped } from '@/modules/Tasks/pages/TasksPage';
import { useGetTasksByBusinessArea } from '@/modules/Tasks/queries/get-tasks-by-businessArea';
import { UserModel } from '@/modules/Users/domain';
import { getUsersColumnsNamedMapped } from '@/modules/Users/pages/UsersPage/utils';
import { useGetUsersByBusinessArea } from '@/modules/Users/queries';
import { UsersKeys } from '@/modules/Users/queries/types';
import { WorkflowModel } from '@/modules/Workflows/domain';
import { getWorflowsColumnsNamedMapped } from '@/modules/Workflows/pages/WorkflowsPage/utils';
import { useGetWorkflowsByBusinessArea } from '@/modules/Workflows/queries';
import { HttpStatusCode } from 'axios';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useDisableBusinessArea,
  useEnableBusinessArea,
  useGetBusinessArea,
} from '../../queries';
import { useDisassociateBusinessAreaUser } from '../../queries/disassociate-user';
import { DisassociateBusinessAreaUserModel } from '../../types/disassociate-business-area-user-model';
import { BusinessAreaPerformanceCards } from './components/BusinessAreaPerformanceCards';
import { HeaderRow } from './components/HeaderRow';

export const BusinessAreaDetailPage = (): ReactElement => {
  const { businessAreaId: paramBusinessAreaId } = useParams<{
    businessAreaId: string;
  }>();
  const businessAreaId = +paramBusinessAreaId!;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate } = useDisassociateBusinessAreaUser();

  const {
    data: businessArea,
    isLoading: isLoadingBusinessArea,
    isError,
    error,
  } = useGetBusinessArea(businessAreaId);

  useEffect(() => {
    if (isError && error) {
      if ((error as HttpError<unknown>)?.code === HttpStatusCode.NotFound) {
        navigate(RoutePath.notFound());
      } else {
        toast.error(t('error_get_business_area_details'));
      }
    }
  }, [isError, error, navigate, t]);

  const [status, setStatus] = useState<boolean>(false);

  useEffect(() => {
    setStatus(businessArea?.status === true);
  }, [businessArea]);

  const { mutate: enableBusinessArea } = useEnableBusinessArea();
  const { mutate: disableBusinessArea } = useDisableBusinessArea();

  const handleStatus = useCallback(
    (checked: boolean) => {
      if (!businessArea?.id) {
        return toast.error(t('generic_errors'));
      }
      setStatus(checked);
      if (checked) {
        enableBusinessArea(businessArea.id, {
          onError: () => {
            toast.error(t('error_enable_business_area'));
          },
          onSuccess: () => {
            toast.success(t('success_enable_business_area'));
          },
        });
      } else {
        disableBusinessArea(businessArea.id, {
          onError: () => {
            toast.error(t('error_disable_business_area'));
          },
          onSuccess: () => {
            toast.success(t('success_disable_business_area'));
          },
        });
      }
    },
    [businessArea, enableBusinessArea, disableBusinessArea, t],
  );

  const tabs: Array<TabItemProps> = useMemo(
    () => [
      { label: t('users'), badgeNumber: businessArea?.usersCount },
      {
        label: t('processes'),
        badgeNumber: businessArea?.processesCount,
      },
      { label: t('workflows'), badgeNumber: businessArea?.workflowsCount },
      { label: t('task'), badgeNumber: businessArea?.tasksCount },
      { label: t('automations'), badgeNumber: businessArea?.automationsCount },
    ],
    [t, businessArea],
  );

  const disassociateBusinessAreaUser = useCallback(
    (userId: number) => {
      const convertedData: DisassociateBusinessAreaUserModel = {
        userId: userId ?? 0,
        businessAreaId: businessAreaId ? Number(businessAreaId) : 0,
      };

      mutate(convertedData, {
        onError: () => {
          toast.error(t('error_remove_user_from_business_area'));
        },
        onSuccess: () => {
          toast.success(t('success_remove_user_from_business_area'));
          queryClient.invalidateQueries(
            UsersKeys.BY_BUSINESS_AREA(businessAreaId),
          );
        },
      });
    },
    [mutate, t, businessAreaId],
  );

  const routeChange = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const tabList = useMemo(() => {
    if (!businessAreaId || !businessArea) {
      return [];
    }
    return [
      {
        index: 0,
        tab: (
          <TabContainer<UserModel>
            parentId={businessAreaId}
            useGetData={useGetUsersByBusinessArea}
            getColumnsNamedMapped={getUsersColumnsNamedMapped}
          >
            <UsersTab
              businessAreaId={businessArea.id}
              showRole
              unlinkData={{
                action: disassociateBusinessAreaUser,
                message: t('confirm_user_business_area_disassociate'),
                canUnlink: true,
              }}
            />
          </TabContainer>
        ),
      },
      {
        index: 1,
        tab: (
          <TabContainer<ProcessModel>
            parentId={businessAreaId}
            useGetData={useGetProcessesByBusinessArea}
            getColumnsNamedMapped={getProcessesColumnsNamedMapped}
          >
            <ProcessesTab
              canCreate
              disableFields={['organizationId', 'companyId', 'businessAreaId']}
              businessAreaId={businessArea.id}
              companyId={businessArea.companyId}
              organizationId={businessArea.organizationId}
            />
          </TabContainer>
        ),
      },
      {
        index: 2,
        tab: (
          <TabContainer<WorkflowModel>
            parentId={businessAreaId}
            useGetData={useGetWorkflowsByBusinessArea}
            getColumnsNamedMapped={getWorflowsColumnsNamedMapped}
          >
            <WorkflowsTab
              canCreate
              disableFields={['organizationId', 'companyId', 'businessAreaId']}
              businessAreaId={businessArea.id}
              companyId={businessArea.companyId}
              organizationId={businessArea.organizationId}
            />
          </TabContainer>
        ),
      },
      {
        index: 3,
        tab: (
          <TabContainer<TaskModel>
            parentId={businessAreaId}
            useGetData={useGetTasksByBusinessArea}
            getColumnsNamedMapped={getTasksColumnsNamedMapped}
          >
            <TasksTab
              disableFields={['organizationId', 'companyId', 'businessAreaId']}
              canCreate
              businessAreaId={businessArea?.id}
              companyId={businessArea?.companyId}
              organizationId={businessArea?.organizationId}
            />
          </TabContainer>
        ),
      },
      {
        index: 4,
        tab: (
          <TabContainer<AutomationModel>
            parentId={businessAreaId}
            useGetData={useGetAutomationsByBusinessArea}
            getColumnsNamedMapped={getAutomationColumnsNamedMapped}
          >
            <AutomationsTab
              canCreate
              disableFields={['organizationId', 'companyId', 'businessAreaId']}
              businessAreaId={businessArea?.id}
              companyId={businessArea?.companyId}
              organizationId={businessArea?.organizationId}
            />
          </TabContainer>
        ),
      },
    ];
  }, [businessAreaId, businessArea, disassociateBusinessAreaUser, t]);

  const breadcrumbs = useMemo(() => {
    if (!businessArea) {
      return [];
    }
    return [
      {
        label: t('organizations'),
        href: RoutePath.organizations(),
      },
      {
        label: businessArea.organizationName,
        href: RoutePath.organizationDetail(
          businessArea.organizationId.toString(),
        ),
      },
      {
        label: businessArea.company,
        href: RoutePath.companyDetail(businessArea.companyId.toString()),
      },
      {
        label: businessArea.name,
      },
    ];
  }, [businessArea, t]);

  return (
    <CustomPageContainer>
      <div className="flex flex-col gap-3">
        <Button
          className="h-6 w-14 justify-between border-blueNuit p-2 font-black text-blueNuit"
          variant="outline"
          onClick={routeChange}
        >
          <ChevronLeft />
          {t('back')}
        </Button>
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <HeaderRow
        businessArea={businessArea}
        handleStatus={handleStatus}
        status={status}
        isLoading={isLoadingBusinessArea}
      />
      <BusinessAreaPerformanceCards
        isLoading={isLoadingBusinessArea}
        data={businessArea}
      />
      <TableWithFilter>
        <TabsWrapper tabs={tabs} tabList={tabList} />
      </TableWithFilter>
    </CustomPageContainer>
  );
};

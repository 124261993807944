import { workflowsRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { WorkflowModel } from '../domain';
import { WorkflowKey } from './keys';

export const useGetWorkflow = (id: number) =>
  useQuery<WorkflowModel>(
    WorkflowKey.BY_ID(id),
    () => workflowsRepo.getWorkflow(id),
    {
      enabled: !!id,
    },
  );

import { Button, Dropdown, Modal, SelectDropdownOption } from '@/components';
import { queryClient } from '@/constants';
import {
  CompanyKeys,
  useGetEligibleCompaniesByInfrastructureCost,
} from '@/modules/Companies/queries';
import {
  useAttachAutomationInfrastructureCost,
  useAttachCompanyInfrastructureCost,
  useAttachOrganizationInfrastructureCost,
  useGetAllInfrastructureCostsByAutomationId,
} from '@/modules/Infrastructure/queries';
import { InfrastructureKeys } from '@/modules/Infrastructure/queries/types';
import {
  AttachAutomationInfrastructureCostModel,
  AttachCompanyInfrastructureCostModel,
  AttachOrganizationInfrastructureCostModel,
} from '@/modules/Infrastructure/types';
import { useGetAllOrganizations } from '@/modules/Organizations/queries';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { format } from 'date-fns';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/style.css';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface AttachEntityToInfrastructureCostModalProps {
  costId: number;
  automationId?: number;
  context: 'organization' | 'company' | 'automation';
}

export const AttachEntityToInfrastructureCostModal = ({
  costId,
  automationId,
  context,
}: AttachEntityToInfrastructureCostModalProps): ReactElement => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedCostId, setSelectedCostId] = useState<number>();
  const [dropdownEntityValue, setDropdownEntityValue] = useState<number>();
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [isStartDatePickerOpen, setIsStartDatePickerOpen] = useState(false);
  const [isEndDatePickerOpen, setIsEndDatePickerOpen] = useState(false);
  const handleModalState = () => setIsOpen(!isOpen);

  const { mutate: mutateOrganization, isLoading: isLoadingOrganization } =
    useAttachOrganizationInfrastructureCost();
  const { mutate: mutateCompany, isLoading: isLoadingCompany } =
    useAttachCompanyInfrastructureCost();
  const { mutate: mutateAutomation, isLoading: isLoadingAutomation } =
    useAttachAutomationInfrastructureCost();

  const handleConfirm = () => {
    if (context === 'organization') {
      if (!dropdownEntityValue) {
        return;
      }
      const payload: AttachOrganizationInfrastructureCostModel = {
        infrastructureCostId: costId,
        organizationId: dropdownEntityValue,
      };
      mutateOrganization(payload, {
        onError: () => {
          toast.error(
            t('error_attaching_entity_to_cost', { entity: t('organization') }),
          );
        },
        onSuccess: () => {
          queryClient.invalidateQueries([InfrastructureKeys.GET]);
          queryClient.invalidateQueries(OrganizationKeys.ALL);
          queryClient.invalidateQueries(CompanyKeys.ALL);
          handleModalState();
          toast.success(
            t('success_attaching_entity_to_cost', {
              entity: t('organization'),
            }),
          );
        },
      });
    } else if (context === 'company') {
      if (!dropdownEntityValue) {
        return;
      }
      const payload: AttachCompanyInfrastructureCostModel = {
        infrastructureCostId: costId,
        companyId: dropdownEntityValue,
      };
      mutateCompany(payload, {
        onError: () => {
          toast.error(
            t('error_attaching_entity_to_cost', { entity: t('company') }),
          );
        },
        onSuccess: () => {
          queryClient.invalidateQueries([InfrastructureKeys.GET]);
          queryClient.invalidateQueries(CompanyKeys.ALL);
          handleModalState();
          toast.success(
            t('success_attaching_entity_to_cost', { entity: t('company') }),
          );
        },
      });
    } else if (context === 'automation') {
      if (!selectedCostId) {
        return;
      }
      const payload: AttachAutomationInfrastructureCostModel = {
        infrastructureCostId: selectedCostId,
        automationId: automationId ?? 0,
        startDate: startDate,
        endDate: endDate,
      };
      mutateAutomation(payload, {
        onError: () => {
          toast.error(
            t('error_attaching_entity_to_cost', { entity: t('automation') }),
          );
        },
        onSuccess: () => {
          queryClient.invalidateQueries([InfrastructureKeys.GET]);
          queryClient.invalidateQueries([
            InfrastructureKeys.GET_INFRASTRUCTURE_COSTS_BY_AUTOMATION,
          ]);
          handleModalState();
          toast.success(
            t('success_attaching_entity_to_cost', {
              entity: t('automation'),
            }),
          );
        },
      });
    }
  };

  const { data: organizationsData } = useGetAllOrganizations();
  const { data: companiesData } =
    useGetEligibleCompaniesByInfrastructureCost(costId);
  const { data: infrastructureCostsData } =
    useGetAllInfrastructureCostsByAutomationId(automationId ?? 0);

  const costDropdownOptions = useMemo(() => {
    return getDropdownArray(infrastructureCostsData ?? []);
  }, [infrastructureCostsData]);

  const handleOnSelectCostDropdownValue = useCallback(
    (option: SelectDropdownOption<number>) => setSelectedCostId(option.value),
    [],
  );

  const entityDropdownOptions = useMemo(() => {
    if (context === 'organization') {
      return getDropdownArray(organizationsData || []);
    } else {
      return getDropdownArray(companiesData || []);
    }
  }, [context, organizationsData, companiesData]);

  const handleOnSelectEntityDropdownValue = useCallback(
    (option: SelectDropdownOption<number>) =>
      setDropdownEntityValue(option.value),
    [],
  );

  const isLoading =
    context === 'organization'
      ? isLoadingOrganization
      : context === 'company'
        ? isLoadingCompany
        : isLoadingAutomation;

  const buttonText =
    context === 'organization'
      ? t('add_organization')
      : context === 'company'
        ? t('add_company')
        : t('associate_cost');

  const modalTitle =
    context === 'organization'
      ? t('associate_organization')
      : context === 'company'
        ? t('associate_company')
        : t('associate_infrastructure_cost');

  return (
    <>
      <Button onClick={handleModalState} variant="primary">
        {buttonText}
      </Button>
      <Modal
        isOpen={isOpen}
        title={modalTitle}
        className="h-fit w-128"
        handleClose={handleModalState}
      >
        <div className="mt-6 flex flex-col gap-5">
          {context !== 'automation' && (
            <div className="flex flex-col gap-2">
              <div className="text-sm font-bold capitalize">
                {t('select_existing_entity', { entity: t(context) })}
              </div>
              <Dropdown
                className="h-10 w-full rounded-md bg-brightGray capitalize italic"
                placeholder={t('select')}
                options={entityDropdownOptions}
                value={dropdownEntityValue}
                onSelect={handleOnSelectEntityDropdownValue}
              />
            </div>
          )}
          {context === 'automation' && (
            <>
              <div className="flex flex-col gap-2">
                <div className="text-sm font-bold capitalize">
                  {t('select_infrastructure_cost')}
                </div>
                <Dropdown
                  className="h-10 w-full rounded-md bg-brightGray capitalize italic"
                  placeholder={t('select_infrastructure_cost')}
                  options={costDropdownOptions}
                  value={selectedCostId}
                  onSelect={handleOnSelectCostDropdownValue}
                />
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-sm font-bold">
                  {t('start_date')}{' '}
                  <span className="font-medium">
                    ({t('date_applied_automation')})
                  </span>
                </div>
                <div className="relative">
                  <input
                    type="text"
                    value={startDate ? format(startDate, 'dd/MM/yyyy') : ''}
                    onClick={() =>
                      setIsStartDatePickerOpen(!isStartDatePickerOpen)
                    }
                    readOnly
                    placeholder={t('select_date')}
                    className="h-10 w-full rounded-md bg-brightGray p-2 text-md capitalize italic"
                  />
                  {isStartDatePickerOpen && (
                    <div className="rounded absolute z-10 mt-1 border bg-white shadow-lg">
                      <DayPicker
                        mode="single"
                        selected={startDate}
                        onSelect={(date) => {
                          setStartDate(date);
                          setIsStartDatePickerOpen(false);
                        }}
                        timeZone="UTC"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-sm font-bold">
                  {t('end_date')}{' '}
                  <span className="font-medium">
                    ({t('date_not_applied_automation')})
                  </span>
                </div>
                <div className="relative">
                  <input
                    type="text"
                    value={endDate ? format(endDate, 'dd/MM/yyyy') : ''}
                    onClick={() => setIsEndDatePickerOpen(!isEndDatePickerOpen)}
                    readOnly
                    placeholder={t('select_date')}
                    className="h-10 w-full rounded-md bg-brightGray p-2 text-md capitalize italic"
                  />
                  {isEndDatePickerOpen && (
                    <div className="rounded absolute z-10 mt-1 border bg-white shadow-lg">
                      <DayPicker
                        mode="single"
                        selected={endDate}
                        onSelect={(date) => {
                          setEndDate(date);
                          setIsEndDatePickerOpen(false);
                        }}
                        timeZone="UTC"
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          <div className="mt-8 flex justify-between">
            <Button
              isDisabled={isLoading}
              onClick={handleModalState}
              variant="outline"
              className="h-9 w-24"
            >
              {t('cancel')}
            </Button>
            <Button
              isDisabled={isLoading}
              onClick={handleConfirm}
              className="h-9 w-24"
            >
              {t('attach')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

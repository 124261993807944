import { TableColumn, TableRow } from '@/components/Table/types';
import {
  automationsCountColumnConfig,
  businessAreasCountColumnConfig,
  deleteRowColumnConfig,
  nameWithAvatarColumnConfig,
  organizationNameColumnConfig,
  usersCountColumnConfig,
} from '@/utils';
import { processesCountColumnConfig } from '@/utils/tableColumns/processes-count-column-config';
import { toggleStatusColumnConfig } from '@/utils/tableColumns/user-status-column-config';
import { CompanyModel } from '../../domain';

type CompaniesColumnsConfig = {
  onClick?: (row?: TableRow) => void;
  disableDeleteMessage?: string;
  onDeleteClick?: (row?: TableRow) => void;
  onStatusChange: (checked: boolean, row: TableRow) => void;
};

export const getCompaniesColumns = ({
  onClick,
  disableDeleteMessage,
  onDeleteClick,
  onStatusChange,
}: CompaniesColumnsConfig): Array<TableColumn<CompanyModel>> => {
  return [
    nameWithAvatarColumnConfig({ onClick }),
    organizationNameColumnConfig(),
    toggleStatusColumnConfig({
      onChange: onStatusChange,
    }),
    usersCountColumnConfig(),
    businessAreasCountColumnConfig(),
    processesCountColumnConfig(),
    automationsCountColumnConfig({
      options: {
        className: 'w-25',
      },
    }),
    deleteRowColumnConfig({
      disabledMessage: disableDeleteMessage,
      onDeleteClick: onDeleteClick,
    }),
  ];
};

export const getCompaniesColumnsNamedMapped = (columnName: string) => {
  const organizationsColumnsNamedMapped: Record<string, string> = {
    id: 'Id',
    name: 'Name',
    organizationName: 'OrganizationName',
    status: 'Status',
    users: 'UsersCount',
    businessAreasCount: 'BusinessAreasCount',
    'business-areas': 'BusinessAreasCount',
    processesCount: 'ProcessesCount',
    automationsCount: 'AutomationsCount',
    automations: 'AutomationsCount',
  };

  return organizationsColumnsNamedMapped[columnName] ?? 'Name';
};

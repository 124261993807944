import {
  AutomationsTab,
  Button,
  ChevronLeft,
  CompaniesTab,
  CustomPageContainer,
  OrganizationsTab,
  TabItemProps,
  TableWithFilter,
} from '@/components';
import { HttpError } from '@/core/http';
import { RoutePath } from '@/core/router/route-paths';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useDisableInfrastructureCost,
  useEnableInfrastructureCost,
  useGetInfrastructureCost,
  useGetInfrastructureCostAmountHistory,
} from '../../queries';
import { HeaderRow } from './components/HeaderRow';
import { HttpStatusCode } from 'axios';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { TabsWrapper } from '@/components/TabsWrapper';
import { TabContainer } from '@/components/TabContainer';
import { OrganizationModel } from '@/modules/Organizations/domain';
import { CompanyModel } from '@/modules/Companies/domain';
import { useGetCompaniesByInfrastructureCost } from '@/modules/Companies/queries';
import { getCompaniesColumnsNamedMapped } from '@/modules/Companies/pages/CompaniesPage/utils';
import { useGetOrganizationsByInfrastructureCost } from '@/modules/Organizations/queries';
import { getOrganizationsColumnsNamedMapped } from '@/modules/Organizations/pages/OrganizationsPage/utils';
import { AutomationModel } from '@/modules/Automations/domain';
import { useGetAutomationsByInfrastructureCost } from '@/modules/Automations/queries/get-automations-by-infrastructure-cost';
import { getAutomationColumnsNamedMapped } from '@/modules/Automations/pages/AutomationsPage/utils';
import { AmountModel } from '../../domain';
import { AmountHistoryTab } from './components/AmountHistoryTab';

export const InfrastructureCostDetailPage = (): ReactElement => {
  const { infrastructureCostId } = useParams<{ infrastructureCostId: any }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: infrastructureCost,
    isLoading: isLoadingInfrastructureCost,
    isError,
    error,
  } = useGetInfrastructureCost(infrastructureCostId);

  useEffect(() => {
    if (isError && error) {
      if ((error as HttpError<unknown>)?.code === HttpStatusCode.NotFound) {
        navigate(RoutePath.notFound());
      } else {
        toast.error(t('error_get_cost_details'));
      }
    }
  }, [isError, error, navigate, t]);

  const [status, setStatus] = useState<boolean>(false);

  useEffect(() => {
    setStatus(infrastructureCost?.status === true);
  }, [infrastructureCost]);

  const { mutate: enableInfrastructureCost } = useEnableInfrastructureCost();
  const { mutate: disableInfrastructureCost } = useDisableInfrastructureCost();

  const handleStatus = useCallback(
    (checked: boolean) => {
      if (!infrastructureCost?.id) {
        return toast.error(t('generic_errors'));
      }
      setStatus(checked);
      if (checked) {
        enableInfrastructureCost(infrastructureCost?.id, {
          onError: () => {
            toast.error(t('error_enable_infrastructure_cost'));
          },
          onSuccess: () => {
            toast.success(t('success_enable_infrastructure_cost'));
          },
        });
      } else {
        disableInfrastructureCost(infrastructureCost?.id, {
          onError: () => {
            toast.error(t('error_disable_infrastructure_cost'));
          },
          onSuccess: () => {
            toast.success(t('success_disable_infrastructure_cost'));
          },
        });
      }
    },
    [
      infrastructureCost,
      enableInfrastructureCost,
      disableInfrastructureCost,
      t,
    ],
  );

  const tabs: Array<TabItemProps> = useMemo(
    () => [
      {
        label: t('organizations'),
        badgeNumber: infrastructureCost?.organizationsCount,
      },
      {
        label: t('companies'),
        badgeNumber: infrastructureCost?.companiesCount,
      },
      {
        label: t('automations'),
        badgeNumber: infrastructureCost?.automationsCount,
      },
      {
        label: t('amount_history'),
        badgeNumber: infrastructureCost?.amountHistoryCount,
      },
    ],
    [t, infrastructureCost],
  );

  const routeChange = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const tabList = useMemo(() => {
    if (!infrastructureCostId) {
      return [];
    }
    return [
      {
        tab: (
          <TabContainer<OrganizationModel>
            parentId={parseInt(infrastructureCostId)}
            useGetData={useGetOrganizationsByInfrastructureCost}
            getColumnsNamedMapped={getOrganizationsColumnsNamedMapped}
          >
            <OrganizationsTab canAttachCost costId={infrastructureCostId} />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<CompanyModel>
            parentId={parseInt(infrastructureCostId)}
            useGetData={useGetCompaniesByInfrastructureCost}
            getColumnsNamedMapped={getCompaniesColumnsNamedMapped}
          >
            <CompaniesTab canAttachCost costId={infrastructureCostId} />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<AutomationModel>
            parentId={parseInt(infrastructureCostId)}
            useGetData={useGetAutomationsByInfrastructureCost}
            getColumnsNamedMapped={getAutomationColumnsNamedMapped}
          >
            <AutomationsTab canCreate={false} />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<AmountModel>
            parentId={parseInt(infrastructureCostId)}
            useGetData={useGetInfrastructureCostAmountHistory}
          >
            <AmountHistoryTab
              canCreate
              costId={parseInt(infrastructureCostId)}
            />
          </TabContainer>
        ),
      },
    ];
  }, [infrastructureCostId]);

  const breadcrumbs = useMemo(() => {
    if (!infrastructureCost) {
      return [];
    }
    return [
      {
        label: t('infrastructure_costs'),
        href: RoutePath.infrastructureCosts(),
      },
      {
        label: infrastructureCost.name,
      },
    ];
  }, [infrastructureCost, t]);

  return (
    <CustomPageContainer>
      <div className={'flex flex-col gap-3'}>
        <Button
          className="h-6 w-14 justify-between border-blueNuit p-2 font-black text-blueNuit"
          variant="outline"
          onClick={routeChange}
        >
          <ChevronLeft />
          {t('back')}
        </Button>
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <HeaderRow
        infrastructureCost={infrastructureCost}
        handleStatus={handleStatus}
        status={status}
        isLoading={isLoadingInfrastructureCost}
      />
      <TableWithFilter>
        <TabsWrapper tabs={tabs} tabList={tabList} />
      </TableWithFilter>
    </CustomPageContainer>
  );
};

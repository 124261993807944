import { RouterMap } from './core/components/RouterMap';
import { RouterProvider } from './core/contexts';
import { RoutePath } from './core/router';
import { useCheckLogin } from './hooks/use-check-login.hook';
import { AuthUserProvider } from './providers/authUserProvider';
import { routerConfiguration } from './router-configuration';

export const App = () => {
  return (
    <RouterProvider
      checkLoginStatus={useCheckLogin}
      unauthorizedRedirectTo={RoutePath.loadingPage()}
    >
      <AuthUserProvider>
        <RouterMap routes={routerConfiguration} />
      </AuthUserProvider>
    </RouterProvider>
  );
};

import { Button, Table } from '@/components';
import { TabContext } from '@/components/TabContainer';
import { t } from 'i18next';
import { useCallback, useContext, useMemo, useState } from 'react';
import { roundToDecimal } from '@/utils/roundToDecimals';
import { getAmountHistoryColumns } from '../../../InfrastructureCostPage/utils';
import { CreateAmountModal } from '../CreateAmountModal';
import { EditAmountModal } from '../EditAmountModal';
import { AmountModel } from '@/modules/Infrastructure/domain';

export const AmountHistoryTab = ({
  canCreate = true,
  costId,
}: {
  canCreate?: boolean;
  costId: number;
}) => {
  const [isOpenEditModal, setIsEditOpenModal] = useState<boolean>(false);
  const [selectedAmount, setSelectedAmount] = useState<AmountModel>();

  const {
    data: amounts,
    isLoading: isLoadingamounts,
    isOpen,
    page,
    onPageChange,
    handleModalState,
    onSort,
  } = useContext(TabContext);

  const handleEditModalState = useCallback(
    (hourlyRateId: number) => {
      const hourlyRate = (amounts?.items as AmountModel[]).find(
        (rate) => rate.id === hourlyRateId,
      );
      setSelectedAmount(hourlyRate);
      setIsEditOpenModal(true);
    },
    [amounts],
  );

  const handleEditModalClose = useCallback(() => {
    setIsEditOpenModal(false);
    setSelectedAmount(undefined);
  }, []);

  const lastChange = useMemo(() => {
    const date =
      amounts?.items[0]?.updatedAt ??
      amounts?.items[1]?.updatedAt ??
      amounts?.items[0]?.startDate ??
      new Date();

    return t('last_rate_changed', {
      date: new Date(date).toLocaleString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }),
    });
  }, [amounts]);

  const minDate = useMemo(() => {
    if (amounts?.items?.[0]?.startDate) {
      const date = new Date(amounts?.items?.[0]?.startDate);
      return new Date(date.setDate(date.getDate() + 1));
    }
  }, [amounts]);

  const columnsToEdit = useMemo(() => {
    return (amounts?.items as AmountModel[])
      ?.slice(0, 2)
      .map((column) => column.id);
  }, [amounts]);

  return (
    <>
      <div className="flex flex-row justify-between">
        <span className="font-bold text-blueNuit">{t('amount_history')}</span>
        {canCreate && (
          <Button onClick={handleModalState} variant="primary">
            {t('add_amount')}
          </Button>
        )}
      </div>

      <CreateAmountModal
        isOpen={isOpen}
        handleClose={handleModalState}
        minDate={minDate}
        infrastructureCostId={costId}
      />
      {selectedAmount && (
        <EditAmountModal
          isOpen={isOpenEditModal}
          handleClose={handleEditModalClose}
          infrastructureCostAmountId={selectedAmount?.id}
          startDate={selectedAmount?.startDate}
          endDate={selectedAmount?.endDate}
          amount={selectedAmount?.amount}
          infrastructureCostId={costId}
        />
      )}
      <div className="flex flex-col">
        <h1>{roundToDecimal(amounts?.items[0]?.amount || 0)}€</h1>
        <span className="text-xs text-primaryBlue">{lastChange}</span>
      </div>
      <Table
        columns={getAmountHistoryColumns({
          onClick: handleEditModalState,
          columnsToEdit: columnsToEdit,
        })}
        rows={amounts?.items ?? []}
        isLoading={isLoadingamounts}
        noResultsLabel={t('no_rates_available')}
        withPagination
        totalItems={amounts?.totalRows}
        totalPages={amounts?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
      />
    </>
  );
};

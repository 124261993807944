import { PaginationParams } from '@/shared/types';

export abstract class UsersKeys {
  static ALL = ['users'];
  static LIST = [...UsersKeys.ALL, 'list'];
  static LIST_PAGED = (pagination: PaginationParams) => [
    ...UsersKeys.LIST,
    pagination,
  ];
  static ADMIN_USERS = [...UsersKeys.ALL, 'admin-users'];
  static ADMIN_USERS_PAGED = (
    pagination: PaginationParams,
    isAccountAdmin?: boolean,
  ) => [...UsersKeys.ADMIN_USERS, pagination, isAccountAdmin];
  static BY_ID = (userId: number) => [...UsersKeys.ALL, 'by-id', userId];
  static BASE_INFO = [...UsersKeys.ALL, 'base-info'];
  static BY_BUSINESS_AREA = (businessAreaId: number) => [
    ...UsersKeys.ALL,
    'by-business-area',
    businessAreaId,
  ];
  static BY_BUSINESS_AREA_PAGED = (
    businessAreaId: number,
    pagination: PaginationParams,
  ) => [...UsersKeys.BY_BUSINESS_AREA(businessAreaId), pagination];
  static BY_COMPANY = (companyId: number) => [
    ...UsersKeys.ALL,
    'by-company',
    companyId,
  ];
  static BY_COMPANY_PAGED = (
    companyId: number,
    pagination: PaginationParams,
  ) => [...UsersKeys.BY_COMPANY(companyId), pagination];

  static BY_ORGANIZATION = (organizationId: number) => [
    ...UsersKeys.ALL,
    'by-organization',
    organizationId,
  ];
  static BY_ORGANIZATION_PAGED = (
    organizationId: number,
    pagination: PaginationParams,
  ) => [...UsersKeys.BY_ORGANIZATION(organizationId), pagination];
  static BY_TASK = (taskId: number) => [...UsersKeys.ALL, 'by-task', taskId];
  static BY_TASK_PAGED = (taskId: number, pagination: PaginationParams) => [
    ...UsersKeys.BY_TASK(taskId),
    pagination,
  ];
  static USERS_PROFILES = [...UsersKeys.ALL, 'profiles'];
  static CURRENT_USER = ['current-user'];
  static USER_RATES = (userId: number) => [...UsersKeys.ALL, 'rates', userId];
  static USER_RATES_PAGED = (userId: number, pagination: PaginationParams) => [
    ...UsersKeys.USER_RATES(userId),
    pagination,
  ];
}

import { PrivateRoute } from '@/core/components/PrivateRoute';
import { UserModel } from '@/modules/Users/domain';
import { ReactElement } from 'react';
import { RouteObject } from 'react-router-dom';

export interface RouteEntry {
  path: string;
  component?: () => ReactElement | null;
  exact?: boolean;
  routes?: RouteEntry[];
  requiresAuth?: boolean;
  unauthorizedRedirectTo?: string;
  showSideNav?: boolean;
}

const routesConfigurationMap =
  (authSettings: AuthSettings) =>
  (route: RouteEntry): RouteObject => {
    const {
      path,
      component: Component,
      exact = true,
      requiresAuth,
      routes,
      unauthorizedRedirectTo,
      showSideNav = true,
    } = route;

    const resolvedPath = [path.startsWith('/') ? path.substring(1) : path];

    if (!exact) resolvedPath.push('*');

    const reactRouter: RouteObject = {
      path: resolvedPath.join('/'),
    };

    if (Component && !routes) {
      const {
        checkLoginStatus,
        unauthorizedRedirectTo: defaultUnauthorizedRedirectTo,
      } = authSettings;

      reactRouter.element =
        requiresAuth && checkLoginStatus ? (
          <PrivateRoute
            showSideNav={showSideNav}
            checkLoginStatus={checkLoginStatus}
            unauthorizedRedirectTo={
              unauthorizedRedirectTo ?? defaultUnauthorizedRedirectTo ?? '/'
            }
          >
            <Component />
          </PrivateRoute>
        ) : (
          <Component />
        );
    }

    if (routes) {
      reactRouter.children = routes.map(routesConfigurationMap(authSettings));
    }

    return reactRouter;
  };

interface AuthSettings {
  checkLoginStatus?: () => { isAuthenticated: boolean; user?: UserModel };
  unauthorizedRedirectTo?: string;
}

export const buildRouterConfiguration = (
  config: RouteEntry[],
  authSettings: AuthSettings,
) => config.map(routesConfigurationMap(authSettings));

import { ApiResponse } from '@/shared/types';
import { DataWithPagination } from '@/shared/types/pagination';
import { OrganizationBaseInfo, OrganizationModel } from '../domain';

export abstract class OrganizationRepository {
  abstract updateOrganization(data: FormData): Promise<OrganizationModel>;
  abstract createOrganization(data: FormData): Promise<OrganizationModel>;
  abstract deleteOrganization(Id: number): Promise<ApiResponse<unknown>>;
  abstract getAllOrganizations(
    pageNumber: number,
    filter: string,
  ): Promise<DataWithPagination<OrganizationModel[]>>;
  abstract getAllOrganizationsWithoutPagination(): Promise<
    OrganizationBaseInfo[]
  >;
  abstract getOrganization(orgId: number): Promise<OrganizationModel>;
  abstract enableOrganization(id: number): Promise<ApiResponse<unknown>>;
  abstract disableOrganization(id: number): Promise<ApiResponse<unknown>>;
  abstract getOrganizationsByUser(
    userId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<OrganizationModel[]>>;
  abstract getOrganizationsByInfrastructureCost(
    infrastructureCostId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<OrganizationModel[]>>;
  abstract getAllOrganizationsByInfrastructureCost(
    infrastructureCostId: number,
  ): Promise<OrganizationModel[]>;
}

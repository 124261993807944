import { SortDirection } from '@/components/Table/types';
import { automationsRepo } from '@/constants/providers';
import { AutomationModel } from '@/modules/Automations/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AutomationKey } from './keys';

export const useGetAutomationsByInfrastructureCost = (
  infrastructureCostId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<AutomationModel[]>,
    any,
    DataWithPagination<AutomationModel[]>
  >,
) =>
  useQuery<DataWithPagination<AutomationModel[]>>(
    AutomationKey.BY_INFRASTRUCTURE_COST_PAGED(infrastructureCostId, {
      pageNumber,
      filter,
      sortBy,
      sortOrder,
    }),
    () =>
      automationsRepo.getAutomationsByInfrastructureCost(
        infrastructureCostId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );

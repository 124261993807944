import { SwitchStatus } from '@/components';
import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { Container } from '@/components/Container';
import { WorkflowModel } from '@/modules/Workflows/domain';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditWorkflowModal } from '../../../WorkflowsPage/components/EditWorkflowModal';

interface HeaderRowProps {
  workflow?: WorkflowModel;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string,
  ) => void;
  status: boolean;
}

export const HeaderRow = ({
  workflow,
  handleStatus,
  status,
}: HeaderRowProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return (
    <Container isLoading={!workflow}>
      <div className="flex justify-between">
        <span>
          <h2>{workflow?.name}</h2>
          <h6>
            <span className="font-bold">
              {workflow?.organization},{workflow?.company},
              {workflow?.businessAreaName}
            </span>
          </h6>
        </span>
        <div className="flex h-10 flex-row gap-3">
          <div className="flex flex-row items-center gap-3 rounded-md bg-white pb-0 pl-3 pr-3 pt-0 text-sm leading-xs">
            <span className="pr-10 font-black">Status</span>
            <span
              className={`p-0 ${status ? 'text-americanGreen' : 'brightGray'}`}
            >
              {status ? 'Active' : 'Inactive'}
            </span>
            <SwitchStatus onChange={handleStatus} checked={status} />
          </div>
          <Button className="h-9 p-5" onClick={handleModalState}>
            {t('edit_workflow')}
          </Button>
          {workflow?.id && (
            <EditWorkflowModal
              isOpen={isOpen}
              id={workflow?.id}
              handleClose={handleModalState}
            />
          )}
        </div>
      </div>
      <div className="flex gap-2.5">
        <Card title={t('workflow_description')}>
          {<p>{workflow?.description}</p>}
        </Card>
        <Card title={t('estimated_cost')}>
          {<p>{workflow?.estimatedCost.toFixed(2)} €</p>}
        </Card>
      </div>
    </Container>
  );
};

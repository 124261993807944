import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { MultiSelectDropdown } from '..';
import { SelectDropdownOption } from '../Dropdown';

interface MultiSelectDropdownFieldProps<
  T extends FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
> {
  name: TName;
  placeholder: string;
  control: Control<T, T>;
  dropdownOptions: SelectDropdownOption<number>[];
}

export const MultiSelectDropdownField = <T extends FieldValues>({
  name,
  control,
  dropdownOptions,
  placeholder,
}: MultiSelectDropdownFieldProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field: { onChange, value, ...otherFieldProps } }) => (
        <MultiSelectDropdown
          {...otherFieldProps}
          className="h-10 w-full rounded-md bg-brightGray capitalize italic"
          placeholder={placeholder}
          options={dropdownOptions}
          onSelect={(option) => {
            const newValue = value.includes(option.value)
              ? value.filter((val: T) => val !== option.value)
              : [...value, option.value];
            onChange(newValue);
          }}
          value={value}
        />
      )}
    />
  );
};

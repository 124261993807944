import { DEFAULT_PAGE_SIZE, SortDirection } from '@/components/Table/types';
import { HttpClient } from '@/core/http/http-client';
import { ApiResponse, DataWithPagination } from '@/shared/types';
import { TaskModel } from '../domain';
import { taskMapper, tasksMapper, tasksWithPaginationMapper } from '../mappers';
import {
  ApiResponseTask,
  ApiResponseTasks,
  DeleteTaskDocumentJson,
  OrderTask,
  PagedTasks,
  TaskJson,
} from '../types';
import { AssociateAutomationModel } from '../types/associate-automation-model';
import { AttachUserTaskModel } from '../types/attach-user-task-model';
import { DisassociateUserModel } from '../types/disassociate-user-model';
import { TaskRepository } from './task.repository';

export class HttpTasksRepository implements TaskRepository {
  constructor(private readonly client: HttpClient) {}

  async getTasks(
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<TaskModel[]>> {
    const tasksJson = await this.client.get<ApiResponseTasks<PagedTasks>>({
      url: '/me/tasks',
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return tasksWithPaginationMapper(tasksJson);
  }

  async getTask(taskId: number): Promise<TaskModel> {
    const taskJson = await this.client.get<ApiResponseTask<TaskJson>>({
      url: `${taskId}`,
    });

    return taskMapper(taskJson?.data.task);
  }

  enableTask = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/enable`,
    });
  };

  attachUserTask = async (data: AttachUserTaskModel): Promise<void> => {
    return await this.client.post({
      body: data,
      url: `associate-user`,
    });
  };

  disableTask = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/disable`,
    });
  };

  orderTask = async (data: OrderTask): Promise<ApiResponse<unknown>> => {
    return await this.client.patch(`${data.id}`, data);
  };

  createTask = async (data: FormData): Promise<number> => {
    return await this.client.post<number>({ body: data });
  };

  updateTask = async (data: FormData): Promise<unknown> => {
    return await this.client.put<undefined>(`${data.get('id')}`, data);
  };

  deleteTask = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.delete(`${id}`);
  };

  async getAllTasksByProcess(processId: number): Promise<TaskModel[]> {
    const tasks = await this.client.get<ApiResponseTasks<TaskJson[]>>({
      url: `/processes/${processId}/tasks/base-info`,
    });

    return tasksMapper(tasks.data.tasks);
  }

  async getTasksByOrganizationId(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<TaskModel[]>> {
    const tasks = await this.client.get<ApiResponseTasks<PagedTasks>>({
      url: `/organizations/${organizationId}/tasks`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return tasksWithPaginationMapper(tasks);
  }

  async getTasksByCompanyId(
    companyId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<TaskModel[]>> {
    const tasks = await this.client.get<ApiResponseTasks<PagedTasks>>({
      url: `/companies/${companyId}/tasks`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return tasksWithPaginationMapper(tasks);
  }

  async getTasksByBusinessArea(
    businessAreaId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<TaskModel[]>> {
    const tasks = await this.client.get<ApiResponseTasks<PagedTasks>>({
      url: `/business-areas/${businessAreaId}/tasks`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return tasksWithPaginationMapper(tasks);
  }

  async getTasksByUserId(
    userId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<TaskModel[]>> {
    const tasks = await this.client.get<ApiResponseTasks<PagedTasks>>({
      url: `/users/${userId}/tasks`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return tasksWithPaginationMapper(tasks);
  }

  addDocument = async (data: FormData): Promise<unknown> => {
    return await this.client.post<unknown>({
      url: `${data.get('id')}/document`,
      body: data,
    });
  };

  deleteDocument = async (data: DeleteTaskDocumentJson): Promise<unknown> => {
    return await this.client.delete<unknown>('document', data);
  };

  async getAllTasksWithoutWorkflow(
    businessAreaId: number,
  ): Promise<TaskModel[]> {
    const tasks = await this.client.get<ApiResponseTasks<TaskJson[]>>({
      url: `/business-areas/${businessAreaId}/tasks/base-info`,
    });

    return tasksMapper(tasks.data.tasks);
  }

  associateAutomation = async (
    data: AssociateAutomationModel,
  ): Promise<void> => {
    return await this.client.post({
      body: data,
      url: `associate-automation`,
    });
  };

  disassociateAutomation = async (
    data: AssociateAutomationModel,
  ): Promise<void> => {
    return await this.client.post({
      body: data,
      url: `disassociate-automation`,
    });
  };

  disassociateUser = async (data: DisassociateUserModel): Promise<void> => {
    return await this.client.post({
      body: data,
      url: `disassociate-user`,
    });
  };
}

import { SortDirection } from '@/components/Table/types';
import { businessAreasRepo } from '@/constants/providers';
import { BusinessAreaModel } from '@/modules/BusinessAreas/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { BusinessAreasKey } from './keys';

export const useGetBusinessAreasByOrganization = (
  organizationId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<BusinessAreaModel[]>,
    any,
    DataWithPagination<BusinessAreaModel[]>
  >,
) =>
  useQuery<DataWithPagination<BusinessAreaModel[]>>(
    BusinessAreasKey.BY_ORGANIZATION_PAGED(organizationId, {
      pageNumber,
      filter,
      sortBy,
      sortOrder,
    }),
    () =>
      businessAreasRepo.getBusinessAreasByOrganization(
        organizationId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );

import { getTotalDurationBySeconds } from '.';
import { round } from './math.util';

export const convertTimeMinutesToHours = (milliseconds: number) => {
  const totalSeconds = milliseconds / 1000;
  const totalMinutes = totalSeconds / 60;
  const totalHours = Math.floor(totalMinutes / 60);

  if (totalMinutes < 1) {
    const seconds = Math.round(totalSeconds);
    return { hours: 0, minutes: 0, seconds };
  }

  if (totalHours === 0) {
    return {
      minutes: Math.trunc(totalMinutes),
      seconds: Math.round(totalSeconds % 60),
    };
  }

  const minutesLeft = Math.round(totalMinutes % 60);
  return {
    hours: totalHours,
    minutes: minutesLeft,
  };
};

export const convertSecondsToTimeMinutesToHours = (seconds: number) => {
  const milliseconds = seconds * 1000;
  return convertTimeMinutesToHours(milliseconds);
};

export const convertTimeToString = (time: {
  minutes: number;
  hours?: number;
  seconds?: number;
}) => {
  const hour =
    time.hours !== 0 && time.hours !== undefined ? `${time.hours}h` : '';
  const minute = time.minutes > 0 ? `${time.minutes}m` : '';
  const seconds = time.seconds && time.seconds > 0 ? `${time.seconds}s` : '';

  return [hour, minute, seconds].join(' ').trim();
};

export const convertSecondsToTimeString = (seconds: number) => {
  if (seconds < 1) {
    return '<0s';
  }
  const time = convertSecondsToTimeMinutesToHours(seconds);
  return convertTimeToString(time);
};

export const convertTimeToHoursToString = (time: number) => {
  const timeInHours = convertTimeMinutesToHours(time);

  return convertTimeToString(timeInHours);
};

export const convertMinutesToHours = (minutes: number) => {
  return Math.round(minutes / 60);
};

export const secondsToTimeDurationString = (seconds: number) => {
  const { days, hours, minutes } = getTotalDurationBySeconds(seconds);
  const totalDays = round(days);
  const totalHours = round(hours);
  const totalMinutes = round(minutes, 0);

  if (Math.floor(hours / 100) > 0) {
    return `${totalDays} d`;
  }

  if (Math.floor(minutes / 100) > 0) {
    return `${totalHours} h`;
  }

  return `${totalMinutes} m`;
};

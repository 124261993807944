import { useQuery } from '@tanstack/react-query';
import { AutomationKey } from './keys';
import { AutomationModel } from '../domain';
import { QueryOptions } from '@/modules/types';
import { automationsRepo } from '@/constants/providers';

export const useGetAutomation = (
  id: number,
  options?: QueryOptions<AutomationModel>,
) =>
  useQuery<AutomationModel>(
    AutomationKey.BY_ID(id),
    () => automationsRepo.getAutomation(id),
    {
      ...options,
      enabled: !!id,
    },
  );

import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useDisableAutomation, useEnableAutomation } from '../queries';
import { AutomationKey } from '../queries/keys';

export const useToggleAutomationStatus = () => {
  const { mutate: enableAutomation } = useEnableAutomation();
  const { mutate: disableAutomation } = useDisableAutomation();
  const queryClient = useQueryClient();

  const onChange = useCallback(
    (checked: boolean, automationId: number) => {
      const onSuccess = () => {
        queryClient.invalidateQueries(AutomationKey.ALL);
      };

      if (checked) {
        enableAutomation(automationId, {
          onSuccess,
        });
      } else {
        disableAutomation(automationId, {
          onSuccess,
        });
      }
    },
    [disableAutomation, enableAutomation, queryClient],
  );

  return { onChange };
};

import { TableColumn, TableRow } from '@/components/Table/types';
import { format } from 'date-fns';
import { Trans } from 'react-i18next';

type StartDateColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const startDateColumnConfig = <T extends TableRow>({
  options,
}: StartDateColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'startDate',
  label: <Trans i18nKey="start_date" />,
  render: (value) => (
    <div className="overflow-hidden text-ellipsis">
      {value && new Date(value).getFullYear() > 1
        ? format(new Date(value), 'MMMM d, yyyy')
        : 'N/A'}
    </div>
  ),
  acessorKey: 'startDate',
  sortable: true,
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});

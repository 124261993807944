import { EditPencil, UnLink } from '@/components';
import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type ActionsEditRowColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
  onEditClick?: (row: T) => void;
  onUnlinkClick?: (row: T) => void;
  canEdit?: (row: T) => boolean;
  canUnlink?: boolean;
};

export const actionsEditRowColumnConfig = <T extends TableRow>({
  options,
  onEditClick,
  onUnlinkClick,
  canEdit = () => true,
  canUnlink = false,
}: ActionsEditRowColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'actions',
  label: <Trans i18nKey="actions" />,
  sortable: false,
  render: (_, row) => (
    <div className="flex items-center space-x-2">
      {canEdit(row) && (
        <EditPencil
          className="cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onEditClick?.(row);
          }}
        />
      )}
      {canUnlink && (
        <UnLink
          className="cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onUnlinkClick?.(row);
          }}
        />
      )}
    </div>
  ),
  ...options,
});

import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { ProcessKey, useDisableProcess, useEnableProcess } from '../queries';

export const useToggleProcessStatus = () => {
  const { mutate: enableProcess } = useEnableProcess();
  const { mutate: disableProcess } = useDisableProcess();
  const queryClient = useQueryClient();

  const onChange = useCallback(
    (checked: boolean, processId: number) => {
      const onSuccess = () => {
        queryClient.invalidateQueries(ProcessKey.ALL);
      };

      if (checked) {
        enableProcess(processId, {
          onSuccess,
        });
      } else {
        disableProcess(processId, {
          onSuccess,
        });
      }
    },
    [queryClient, enableProcess, disableProcess],
  );

  return { onChange };
};

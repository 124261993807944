import { useDisableUser, useEnableUser } from '@/modules/Users/queries';
import { UsersKeys } from '@/modules/Users/queries/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export const useToggleUserStatus = () => {
  const { mutate: enableUser } = useEnableUser();
  const { mutate: disableUser } = useDisableUser();
  const queryClient = useQueryClient();
  const onChange = useCallback(
    (checked: boolean, userId: number) => {
      const onSuccess = () => {
        queryClient.invalidateQueries(UsersKeys.LIST);
      };
      if (checked) {
        enableUser(userId, {
          onSuccess,
        });
      } else {
        disableUser(userId, {
          onSuccess,
        });
      }
    },
    [disableUser, enableUser, queryClient],
  );

  return { onChange };
};

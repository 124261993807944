import { Button } from '@/components';
import { queryClient } from '@/constants';
import { UsersKeys } from '@/modules/Users/queries/types';
import { useUsersCsvImportByEntityType } from '@/modules/Users/queries/users-csv-import';
import { ChangeEvent, ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getEntityType } from '../../utils';

interface AddCsvTabModalProps {
  handleClose: () => void;
  organizationId?: number;
  companyId?: number;
  businessAreaId?: number;
  taskId?: number;
}

export const AddCsvTabModal = ({
  handleClose,
  organizationId,
  companyId,
  businessAreaId,
  taskId,
}: AddCsvTabModalProps): ReactElement => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File>();

  const { mutate: importCsvUser, isLoading: isLoading } =
    useUsersCsvImportByEntityType();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setSelectedFile(selectedFile);
    }
  };

  const entityType = useMemo(
    () => getEntityType({ organizationId, companyId, businessAreaId, taskId }),
    [organizationId, companyId, businessAreaId, taskId],
  );

  const handleSubmit = () => {
    if (!selectedFile) {
      return;
    }
    const formData = new FormData();
    const id = organizationId ?? companyId ?? businessAreaId ?? taskId;
    formData.append('entityId', id?.toString() ?? '');
    formData.append('entityType', entityType?.toString() ?? '');
    formData.append('users', selectedFile);

    importCsvUser(formData, {
      onError: () => {
        toast.error(t('error_import_csv_users'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(UsersKeys.ALL);
        toast.success(t('success_import_csv_users'));
        handleClose();
      },
    });
  };

  return (
    <div className="pointer flex flex-col gap-5">
      <a
        href={`${window.location.origin}/add_user_template.csv`}
        className="pointer flex cursor-pointer flex-row gap-1 font-black text-shinyBlue underline"
      >
        {t('download_template_csv_file')}
      </a>
      <div className="pointer flex  cursor-pointer justify-center border border-dotted border-intercoastalGray p-1 text-intercoastalGray">
        <label
          htmlFor="import-user-csv-file-upload"
          className="cursor-pointer font-black underline"
        >
          {t('upload_the_completed_csv_file')}
        </label>
        <input
          accept=".csv"
          id="import-user-csv-file-upload"
          type="file"
          onChange={handleFileChange}
          className="hidden"
        />
      </div>
      <div className="mt-8 flex justify-between">
        <Button
          isDisabled={isLoading}
          onClick={handleClose}
          variant="outline"
          className="h-9 w-24"
        >
          {t('cancel')}
        </Button>
        <Button
          onClick={handleSubmit}
          isDisabled={isLoading || !selectedFile}
          variant="alternative"
          buttonType="submit"
          className="h-9 w-24"
        >
          {t('add')}
        </Button>
      </div>
    </div>
  );
};

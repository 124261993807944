export abstract class FileUtility {
  static downloadBlob(blob: Blob, name: string) {
    const file = new File([blob], name);
    const fileUrl = URL.createObjectURL(file);
    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    anchor.download = name;
    anchor.rel = 'noreferrer';
    anchor.target = '_blank';
    anchor.click();
    URL.revokeObjectURL(fileUrl);
  }
}

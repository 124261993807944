import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AmountModal } from '../AmountModal';
import { AmountJson, AmountSchemaType } from '@/modules/Infrastructure/types';
import { useUpdateInfrastructureCostAmount } from '@/modules/Infrastructure/queries';
import { InfrastructureKeys } from '@/modules/Infrastructure/queries/types';

interface EditAmountModalProps {
  isOpen: boolean;
  handleClose: () => void;
  startDate?: Date;
  endDate?: Date;
  infrastructureCostAmountId: number;
  infrastructureCostId?: number;
  amount?: number;
}

export const EditAmountModal = ({
  isOpen,
  handleClose,
  startDate,
  endDate,
  infrastructureCostAmountId,
  infrastructureCostId,
  amount,
}: EditAmountModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useUpdateInfrastructureCostAmount();

  const handleConfirm = (data: AmountSchemaType) => {
    const convertedData: AmountJson = {
      amount: Number(data.amount),
      startDate: data.startDate,
      endDate: data.endDate,
      id: infrastructureCostAmountId,
      infrastructureCostId: infrastructureCostId,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_updating_infrastructure_amount'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          InfrastructureKeys.GET_INFRASTRUCTURE_COSTS_AMOUNT_HISTORY,
        ]);
        handleClose();
        toast.success(t('success_updating_infrastructure_amount'));
      },
    });
  };

  return (
    <AmountModal
      handleOnSubmit={handleConfirm}
      handleClose={handleClose}
      isLoading={isLoading}
      type="edit"
      isOpen={isOpen}
      title={t('edit_infrastructure_amount')}
      startDate={startDate}
      endDate={endDate}
      amount={amount}
    />
  );
};

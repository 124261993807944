import { useQuery } from '@tanstack/react-query';
import { CompanyModel } from '../domain';
import { CompanyKeys } from './types';
import { companiesRepo } from '@/constants/providers';

export const useGetCompany = (id: number) =>
  useQuery<CompanyModel>(
    CompanyKeys.BY_ID(id),
    () => companiesRepo.getCompany(id),
    {
      enabled: !!id,
    },
  );

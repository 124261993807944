import { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { PREVIOUS_TENANT_ID, TENANT_ID_SELECTED } from '../TenantProvider';

const KeycloakSession = () => {
  const minValidityInSeconds = 10;
  const { initialized, keycloak } = useKeycloak();

  useEffect(() => {
    const updateToken = async () => {
      if (initialized && keycloak) {
        await keycloak.updateToken(minValidityInSeconds).then((isRefreshed) => {
          if (isRefreshed) {
            sessionStorage.removeItem(TENANT_ID_SELECTED);
            sessionStorage.removeItem(PREVIOUS_TENANT_ID);
          }
        });
      }
    };

    updateToken();
  }, [initialized, keycloak]);

  return null;
};

export default KeycloakSession;

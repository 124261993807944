import { automationsRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { AutomationKey } from './keys';

export const useGetAutomationPerformance = (automationId: number) =>
  useQuery(
    AutomationKey.PEFORMANCE(automationId),
    () => automationsRepo.getAutomationPerformance(automationId),
    {
      enabled: !!automationId,
      cacheTime: 5 * 60 * 1000, // 5 minutes
    },
  );

import { useInvalidateBusinessAreaQueries } from '@/modules/BusinessAreas/hooks/use-invalidate-business-areas-queries';
import { useGetBusinessArea } from '@/modules/BusinessAreas/queries';
import { useUpdateBusinessArea } from '@/modules/BusinessAreas/queries/update-business-area';
import { BusinessAreaSchemaType } from '@/modules/BusinessAreas/types/businessArea-schema';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { BusinessAreaModal } from '../BusinessAreaModal';

interface EditBusinessAreaModal {
  isOpen: boolean;
  handleClose: () => void;
  businessAreaId: number;
}

export const EditBusinessAreaModal = ({
  isOpen,
  handleClose,
  businessAreaId,
}: EditBusinessAreaModal) => {
  const { t } = useTranslation();
  const invalidateQueries = useInvalidateBusinessAreaQueries();

  const { mutate, isLoading } = useUpdateBusinessArea();

  const { data: businessArea, isLoading: isLoadingGet } =
    useGetBusinessArea(businessAreaId);

  const handleConfirm = (data: BusinessAreaSchemaType) => {
    const convertedData = {
      id: data.id ?? businessArea?.id,
      companyId: Number(data.companyId),
      name: data.businessAreaName ?? businessArea?.name,
      code: data.businessAreaCode ?? businessArea?.code,
      status: true,
      parentBusinessAreaId: data.parentBusinessAreaId,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_update_businessArea'));
      },
      onSuccess: () => {
        invalidateQueries();
        handleClose();
        toast.success(t('success_update_businessArea'));
      },
    });
  };

  return (
    !isLoading && (
      <BusinessAreaModal
        handleClose={handleClose}
        type="edit"
        handleOnSubmit={handleConfirm}
        isOpen={isOpen}
        isLoading={isLoading || isLoadingGet}
        title={t('edit_business_area')}
        companyId={businessArea?.companyId}
        organizationId={businessArea?.organizationId}
        businessAreaName={businessArea?.name}
        businessAreaCode={businessArea?.code}
        parentBusinessAreaId={businessArea?.parentBusinessAreaId}
        id={businessAreaId}
      />
    )
  );
};

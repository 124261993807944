import { format } from 'date-fns';

type DateDifference = {
  displayCurrentDate: string;
  daysToGo: number;
};

export const getDateDifference = (date?: string): DateDifference => {
  if (!date)
    return {
      displayCurrentDate: format(new Date(), 'dd LLL yyyy'),
      daysToGo: 0,
    };

  const currentDate = new Date(date);
  const todaysDate = new Date();

  const currentDateMs = currentDate.getTime();
  const todaysDateMs = todaysDate.getTime();

  const diffBtwDates = currentDateMs - todaysDateMs;
  const aDayInMs = 1000 * 60 * 60 * 24;

  const daysToGo = Math.round(diffBtwDates / aDayInMs);
  const displayCurrentDate = format(currentDate, 'dd LLL yyyy');

  return { displayCurrentDate, daysToGo };
};

import { SortDirection } from '@/components/Table/types';
import { workflowsRepo } from '@/constants/providers';
import { WorkflowModel } from '@/modules/Workflows/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { WorkflowKey } from './keys';

export const useGetWorkflowsByBusinessArea = (
  businessAreaId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<WorkflowModel[]>,
    any,
    DataWithPagination<WorkflowModel[]>
  >,
) =>
  useQuery<DataWithPagination<WorkflowModel[]>>(
    WorkflowKey.BY_BUSINESS_AREA_PAGED(businessAreaId, {
      pageNumber,
      filter,
      sortBy,
      sortOrder,
    }),
    () =>
      workflowsRepo.getWorkflowsByBusinessArea(
        businessAreaId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );

import { useMutation } from '@tanstack/react-query';
import { EditUserAccountAdminJson, EditUserJson } from '../types';
import { usersRepo } from '@/constants/providers';
import { ApiResponse } from '@/shared/types';

export const useUpdateUser = () =>
  useMutation<ApiResponse<unknown>, unknown, EditUserJson>(
    usersRepo.updateUser,
  );

export const useUpdateUserAccountAdmin = () =>
  useMutation<ApiResponse<unknown>, unknown, EditUserAccountAdminJson>(
    usersRepo.updateAccountAdmin,
  );

import { Environment } from '@/constants';
import { useGetAutomationPerformance } from '@/modules/Automations/queries';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import {
  AutomationPerformanceCards,
  AutomationPerformanceCharts,
  ROIDateCard,
} from './components';

export const AutomationPerformanceTab = (): ReactElement => {
  const { automationId: automationIdParam } = useParams<{
    automationId: string;
  }>();
  const automationId = +automationIdParam!;

  const {
    data: automationPerformance,
    isLoading: isLoadingAutomationPerformance,
  } = useGetAutomationPerformance(automationId);

  return (
    <div>
      <AutomationPerformanceCards
        automationPerformance={automationPerformance}
        isLoading={isLoadingAutomationPerformance}
      />
      <AutomationPerformanceCharts
        automationPerformance={automationPerformance}
      />
      {import.meta.env.MODE.toLowerCase() !== Environment.Production && ( //TODO: Remove this after testing
        <ROIDateCard
          ROI={automationPerformance?.ROI}
          initialInvestment={automationPerformance?.investment.development}
          totalInvestment={automationPerformance?.totalInvestment}
        />
      )}
    </div>
  );
};

import { SortDirection } from '@/components/Table/types';
import { tasksRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { TaskModel } from '../domain';
import { TaskKey } from './keys';

export const useGetTasks = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<TaskModel[]>,
    any,
    DataWithPagination<TaskModel[]>
  >,
) =>
  useQuery<DataWithPagination<TaskModel[]>>(
    TaskKey.LIST_PAGED({
      pageNumber,
      filter,
      sortBy,
      sortOrder,
    }),
    () => tasksRepo.getTasks(pageNumber, filter, sortBy, sortOrder),
    { ...options, cacheTime: 1000 * 60 * 5 },
  );

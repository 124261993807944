import { TableColumn, TableRow } from '@/components/Table/types';
import {
  businessAreaNameColumnConfig,
  codeColumnConfig,
  deleteRowColumnConfig,
  estimatedCostColumnConfig,
  nameWithAvatarColumnConfig,
  statusColumnConfig,
  tasksCountColumnConfig,
  workflowsColumnConfig,
} from '@/utils';
import { processCompanyNameColumnConfig } from '@/utils/tableColumns/process-company-name-column-config';
import { processOrganizationNameColumnConfig } from '@/utils/tableColumns/process-organization-name-column-config';
import { toggleStatusColumnConfig } from '@/utils/tableColumns/user-status-column-config';

type CompaniesColumnsConfig = {
  onClick?: (row?: TableRow) => void;
  disableDeleteMessage?: string;
  onDeleteClick?: (row?: TableRow) => void;
  onStatusChange?: (checked: boolean, row: TableRow) => void;
};

export const getProcessesColumns = ({
  onClick,
  disableDeleteMessage,
  onDeleteClick,
  onStatusChange,
}: CompaniesColumnsConfig = {}): Array<TableColumn<TableRow>> => {
  return [
    nameWithAvatarColumnConfig({ onClick }),
    codeColumnConfig(),
    processOrganizationNameColumnConfig(),
    processCompanyNameColumnConfig(),
    businessAreaNameColumnConfig(),
    onStatusChange
      ? toggleStatusColumnConfig({
          onChange: onStatusChange,
        })
      : statusColumnConfig(),
    workflowsColumnConfig(),
    tasksCountColumnConfig(),
    estimatedCostColumnConfig(),
    deleteRowColumnConfig({
      disabledMessage: disableDeleteMessage,
      onDeleteClick: onDeleteClick,
    }),
  ];
};

export const getProcessesColumnsNamedMapped = (columnName: string) => {
  const organizationsColumnsNamedMapped: Record<string, string> = {
    id: 'Id',
    name: 'Name',
    code: 'Code',
    businessAreaName: 'BusinessAreaName',
    tasks: 'TasksCount',
    workflowsCount: 'WorkflowsCount',
    runTime: 'RunTime',
    status: 'Status',
    estimatedCost: 'EstimatedCost',
    organizationName: 'Organization',
    company: 'Company',
  };

  return organizationsColumnsNamedMapped[columnName] ?? 'Name';
};

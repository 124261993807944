import { Container } from '@/components';
import { PerformanceCard } from '@/components/PerformanceCard';
import { useGetTagLabel } from '@/hooks';
import {
  calculateValueDifference,
  convertSecondsToTimeString,
  ValueDifference,
} from '@/utils';
import { roundToDecimal } from '@/utils/roundToDecimals';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface PerformanceCardsContainerProps {
  isLoading: boolean;
  predictedManualTime?: number;
  predictedAverageManualTime?: number;
  automationRunTime?: number;
  averageAutomationRunTime?: number;
  totalInvestment?: number;
  totalReturn?: number;
  averageCost?: number;
  averageManualCost?: number;
  subtitleInvestmentLabel?: string;
  investmentReturnDifference?: ValueDifference;
}

export const PerformanceCardsContainer = ({
  isLoading,
  predictedManualTime,
  predictedAverageManualTime,
  automationRunTime,
  averageAutomationRunTime,
  totalInvestment,
  totalReturn,
  averageCost,
  averageManualCost,
  subtitleInvestmentLabel,
  investmentReturnDifference,
}: PerformanceCardsContainerProps): ReactElement => {
  const { t } = useTranslation();
  const {
    performance: performanceTotalTime,
    timeDifference: timeDifferenceTotalTime,
    difference: differenceTotalTime,
  } = calculateValueDifference(
    Math.round(predictedManualTime ?? 0),
    Math.round(automationRunTime ?? 0),
  );

  const {
    performance: performanceAverageTime,
    timeDifference: timeDifferenceAverageTime,
    difference: differenceAverageTime,
  } = calculateValueDifference(
    Math.round(predictedAverageManualTime ?? 0),
    Math.round(averageAutomationRunTime ?? 0),
  );

  const {
    performance: performanceTotalReturn,
    timeDifference: timeDifferenceTotalReturn,
    difference: differenceTotalReturn,
  } = investmentReturnDifference ??
  calculateValueDifference(totalInvestment || 0, totalReturn || 0, 'coin');

  const {
    performance: performanceAverageReturn,
    timeDifference: timeDifferenceAverageReturn,
    difference: differenceAverageReturn,
  } = calculateValueDifference(
    averageManualCost ?? 0,
    averageCost ?? 0,
    'coin',
  );

  return (
    <div className="flex flex-wrap gap-2">
      <Container title={t('time_saved')}>
        <div className="flex flex-row gap-2">
          <PerformanceCard
            isLoading={isLoading}
            title={t('total_time_saved')}
            subtitle={`${convertSecondsToTimeString(Number(predictedManualTime) || 0)} ${t('predicted_manual_time')}`}
            performance={performanceTotalTime}
            value={`${timeDifferenceTotalTime} ${useGetTagLabel(differenceTotalTime)}`}
          >
            {convertSecondsToTimeString(Number(automationRunTime || 0))}
          </PerformanceCard>
          <PerformanceCard
            isLoading={isLoading}
            title={t('average_time_saved_per_case')}
            subtitle={`${convertSecondsToTimeString(Number(predictedAverageManualTime) || 0)} ${t('predicted_manual_time')}`}
            performance={performanceAverageTime}
            value={`${timeDifferenceAverageTime} ${useGetTagLabel(
              differenceAverageTime,
            )}`}
          >
            {convertSecondsToTimeString(Number(averageAutomationRunTime || 0))}
          </PerformanceCard>
        </div>
      </Container>
      <Container title={t('investment_return')}>
        <div className="flex flex-row gap-2">
          <PerformanceCard
            isLoading={isLoading}
            title={t('total_return')}
            subtitle={`(${roundToDecimal(totalInvestment || 0)}€ ${subtitleInvestmentLabel || t('estimated_manual_cost')})`}
            performance={performanceTotalReturn}
            value={`${roundToDecimal(timeDifferenceTotalReturn as number) || 0}€ ${useGetTagLabel(
              differenceTotalReturn,
              'coin',
            )}`}
          >
            {roundToDecimal(totalReturn || 0)}€
          </PerformanceCard>
          <PerformanceCard
            isLoading={isLoading}
            title={t('average_return_per_case')}
            subtitle={`(${roundToDecimal(averageManualCost || 0)}€ ${t('minimum_value')})`}
            value={`${roundToDecimal(timeDifferenceAverageReturn as number) || 0}€ ${useGetTagLabel(
              differenceAverageReturn,
              'coin',
            )}`}
            performance={performanceAverageReturn}
          >
            {roundToDecimal(averageCost || 0)}€
          </PerformanceCard>
        </div>
      </Container>
    </div>
  );
};

import { TenantModel } from '../domain';
import { TenantRepository } from './tenant.repository';
import { HttpClient } from '@/core/http/http-client';
import { ApiResponseTenant, ApiResponseTenants, TenantJson } from '../types';
import { mapTenantJsonToTenantModel, tenantsMapper } from '../mappers';

export class HttpTenantRepository extends TenantRepository {
  constructor(private readonly client: HttpClient) {
    super();
  }

  async getTenants(): Promise<TenantModel[]> {
    const tenants = await this.client.get<ApiResponseTenants<TenantJson[]>>({
      url: `me/by-ids`,
    });

    return tenantsMapper(tenants.data.tenants);
  }

  async getTenantById(tenantId: number): Promise<TenantModel> {
    const tenant = await this.client.get<ApiResponseTenant<TenantJson>>({
      url: `${tenantId}`,
    });

    return mapTenantJsonToTenantModel(tenant.data.tenant);
  }
}

import { TableColumn, TableRow } from '@/components/Table/types';
import { UserModel } from '@/modules/Users/domain';
import {
  actionsAdminUsersRowColumnConfig,
  emailColumnConfig,
  nameWithAvatarColumnConfig,
} from '@/utils';
import { toggleStatusColumnConfig } from '@/utils/tableColumns/user-status-column-config';

type UsersColumnsConfig = {
  onDetailsClick: (row: TableRow) => void;
  onUnlinkClick: (row: TableRow) => void;
  onStatusChange: (checked: boolean, row: TableRow) => void;
  isLoadingUserUpdate?: boolean;
};

export const getUsersColumns = ({
  onDetailsClick,
  onUnlinkClick,
  onStatusChange,
  isLoadingUserUpdate,
}: UsersColumnsConfig): Array<TableColumn<UserModel>> => {
  return [
    nameWithAvatarColumnConfig({ onClick: onDetailsClick }),
    emailColumnConfig(),
    toggleStatusColumnConfig({
      onChange: onStatusChange,
    }),
    actionsAdminUsersRowColumnConfig({
      onUnlinkClick,
      isLoadingUserUpdate,
    }),
  ];
};

export const getUsersColumnsNamedMapped = (columnName: string) => {
  const userColumnsNamedMapped: Record<string, string> = {
    id: 'Id',
    name: 'FullName',
    email: 'Email',
    status: 'Status',
  };

  return userColumnsNamedMapped[columnName] ?? 'FullName';
};

import { Button, Modal } from '@/components';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface ConfirmModalProps {
  isOpen: boolean;
  handleClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
}

export const ConfirmModal = ({
  isOpen,
  handleClose,
  onConfirm,
  title,
  message,
}: ConfirmModalProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      className="h-fit w-2/5"
      handleClose={handleClose}
    >
      <div className="mt-6 flex flex-col gap-5">
        <p>{message}</p>
        <div className="mt-8 flex justify-between">
          <Button onClick={handleClose} variant="outline" className="h-9 w-24">
            {t('cancel')}
          </Button>
          <Button onClick={onConfirm} className="h-9 w-24">
            {t('im_sure')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

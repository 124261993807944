import { Tooltip, TrashIcon } from '@/components';
import { TableColumn, TableRow } from '@/components/Table/types';
import React from 'react';
import { Trans } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

type DeleteRowColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
  onDeleteClick?: (row: T) => void;
  disabledMessage?: string;
};

export const deleteRowColumnConfig = <
  T extends TableRow & { canDelete?: boolean },
>({
  options,
  onDeleteClick,
  disabledMessage,
}: DeleteRowColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'action-delete',
  label: <Trans i18nKey="actions" />,
  sortable: false,
  render: (_, row) => {
    const isDeleteDisabled = row.canDelete === false;
    const DeleteCell = () => {
      const icon = (
        <TrashIcon
          className={twMerge(
            'text-gray-500',
            isDeleteDisabled
              ? 'opacity-50'
              : 'cursor-pointer hover:text-gray-700',
          )}
          onClick={(e: React.MouseEvent) => {
            if (!isDeleteDisabled) {
              e.preventDefault();
              e.stopPropagation();
              onDeleteClick?.(row);
            }
          }}
        />
      );

      return isDeleteDisabled ? (
        <Tooltip content={disabledMessage} placement="top" showArrow>
          {icon}
        </Tooltip>
      ) : (
        icon
      );
    };

    return <DeleteCell />;
  },
  ...options,
});

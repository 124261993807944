interface CardsTitleProps {
  title?: string;
  company?: string;
  businessArea?: string;
  organization?: string;
}

export const CardsTitle = ({
  title,
  company,
  businessArea,
  organization,
}: CardsTitleProps) => {
  return (
    <div>
      <span>
        <h2>{title}</h2>
        <h6>
          <span className="font-bold">
            {organization}, {company},
          </span>
          <span className="font-normal"> {businessArea}</span>
        </h6>
      </span>
    </div>
  );
};

import { useTranslation } from 'react-i18next';
import { TableRow } from '../Table/types';
import { ToggleColumn } from '../ToggleColumn';

export const AccessColumn = <T extends TableRow>({
  onChange,
  value,
  row,
}: {
  onChange: (checked: boolean, row: T) => void;
  value: boolean;
  row: T;
}) => {
  const { t } = useTranslation();

  return (
    <ToggleColumn
      label={t('access')}
      onChange={onChange}
      row={row}
      value={value}
    />
  );
};

import {
  Button,
  Dropdown,
  FieldInfo,
  FilterBusinessAreaFormGroup,
  Input,
  TextArea,
} from '@/components';
import { useGetAllBusinessAreasByCompany } from '@/modules/BusinessAreas/queries';
import { useGetAllCompaniesByOrganization } from '@/modules/Companies/queries';
import { useGetAllOrganizations } from '@/modules/Organizations/queries';
import { useGetAllProcessesByBusinessArea } from '@/modules/Processes/queries';
import { WorkflowSchema, WorkflowSchemaType } from '@/modules/Workflows/types';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

interface WorkflowFormProps {
  handleOnSubmit: (e: WorkflowSchemaType) => void;
  handleClose: () => void;
  type: 'create' | 'edit';
  isLoading: boolean;
  isOpen: boolean;
  organizationId?: number;
  companyId?: number;
  businessAreaId?: number;
  processId?: number;
  description?: string;
  workflowName?: string;
  disableFields?: string[];
}

export const WorkflowForm = ({
  isOpen,
  type,
  isLoading,
  handleOnSubmit,
  handleClose,
  organizationId,
  companyId,
  businessAreaId,
  processId,
  description,
  workflowName,
  disableFields,
}: WorkflowFormProps) => {
  const {
    clearErrors,
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
    control,
    watch,
  } = useForm<WorkflowSchemaType>({
    defaultValues: useMemo(() => {
      return {
        workflowName: workflowName || '',
        description: description || '',
        organizationId: organizationId,
        companyId: companyId,
        businessAreaId: businessAreaId,
        processId: processId,
      };
    }, [
      organizationId,
      companyId,
      workflowName,
      businessAreaId,
      processId,
      description,
    ]),
    resolver: zodResolver(WorkflowSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
  });

  const { data: organizations } = useGetAllOrganizations();

  const organization = watch('organizationId');
  const company = watch('companyId');
  const businessArea = watch('businessAreaId');

  const { data: companies } = useGetAllCompaniesByOrganization(organization);

  const { data: businessAreas } = useGetAllBusinessAreasByCompany(company);

  const { data: processes } = useGetAllProcessesByBusinessArea(businessArea);

  const {
    dropdownOrganizationsOptions,
    dropdownCompaniesOptions,
    dropdownBusinessAreasOptions,
    dropdownProcessesOptions,
  } = useMemo(() => {
    return {
      dropdownOrganizationsOptions: getDropdownArray(
        organizations?.filter(
          (organization) =>
            organization.status || organization.id === organizationId,
        ) ?? [],
      ),
      dropdownCompaniesOptions: getDropdownArray(
        companies?.filter(
          (company) => company.status || company.id === companyId,
        ) ?? [],
      ),
      dropdownBusinessAreasOptions: getDropdownArray(
        businessAreas?.filter(
          (businessArea) =>
            businessArea.status || businessArea.id === businessAreaId,
        ) ?? [],
      ),
      dropdownProcessesOptions: getDropdownArray(
        processes?.filter(
          (process) => process.status || process.id === processId,
        ) ?? [],
      ),
    };
  }, [
    organizations,
    companies,
    businessAreas,
    processes,
    organizationId,
    companyId,
    businessAreaId,
    processId,
  ]);

  useEffect(() => {
    clearErrors();

    organizationId && setValue('organizationId', organizationId);
    companyId && setValue('companyId', companyId);
    businessAreaId && setValue('businessAreaId', businessAreaId);
    setValue('processId', processId ?? 0);
    setValue('workflowName', workflowName || '');
    setValue('description', description || '');
  }, [
    clearErrors,
    setValue,
    isOpen,
    organizationId,
    companyId,
    businessAreaId,
    processId,
    workflowName,
    description,
  ]);

  const onSubmit: SubmitHandler<WorkflowSchemaType> = (data) => {
    trigger();
    if (!isValid) {
      return;
    }

    handleOnSubmit(data);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-2 flex h-112 flex-col overflow-y-auto p-2">
        <FieldInfo
          title={t('workflow_name')}
          errorText={
            errors.workflowName?.message
              ? t(errors.workflowName.message)
              : undefined
          }
          hasError={!!errors.workflowName?.message}
        >
          <Input
            isDisabled={isLoading}
            className="w-full bg-brightGray italic"
            placeHolder={t('workflow_name')}
            name="workflowName"
            register={register}
          />
        </FieldInfo>

        <FilterBusinessAreaFormGroup<WorkflowSchemaType>
          organizationOptions={dropdownOrganizationsOptions}
          companyOptions={dropdownCompaniesOptions}
          businessAreaOptions={dropdownBusinessAreasOptions}
          disableFields={disableFields}
          hiddenFields={disableFields}
          errors={errors}
          control={control}
        />
        <FieldInfo
          title={t('process')}
          errorText={
            errors.processId?.message ? t(errors.processId.message) : undefined
          }
          hasError={!!errors.processId?.message}
          hidden={disableFields && disableFields.includes('processId')}
        >
          <Controller
            name="processId"
            control={control}
            render={({
              field: { onChange, value, name, ...otherFieldProps },
            }) => (
              <Dropdown
                {...otherFieldProps.onBlur}
                className="h-10 w-full rounded-md  bg-brightGray italic"
                placeholder={t('process')}
                options={dropdownProcessesOptions}
                onSelect={(val) => onChange(val.value)}
                value={value}
                disabled={disableFields && disableFields.includes(name)}
              />
            )}
          />
        </FieldInfo>
        <FieldInfo
          title={t('description')}
          errorText={
            errors.description?.message
              ? t(errors.description.message)
              : undefined
          }
          hasError={!!errors.description?.message}
        >
          <TextArea
            isDisabled={isLoading}
            className="h-24 w-full bg-brightGray"
            placeHolder={t('description')}
            name="description"
            register={register}
          />
        </FieldInfo>
      </div>
      <div className="mt-4 flex justify-between">
        <Button
          isDisabled={isLoading}
          onClick={handleClose}
          variant="outline"
          className="h-9 w-24"
        >
          {t('cancel')}
        </Button>
        <Button isDisabled={isLoading} buttonType="submit" className="h-9 w-24">
          {type === 'create' ? t('create') : t('confirm')}
        </Button>
      </div>
    </form>
  );
};

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MouseEvent, ReactNode, useCallback, useState } from 'react';
import { LinkBehavior } from '..';
import { ArrowCollapse, EditPencil, HandlerIcon, UnLink } from '../Icons';

export const Collapse = ({
  children,
  title,
  titleHref,
  subText,
  defaultExpanded = true,
  draggable = false,
  action,
  editAction,
  id = 0,
  extraButton,
}: {
  children: ReactNode;
  title?: string;
  titleHref?: string;
  subText?: string;
  defaultExpanded?: boolean;
  draggable?: boolean;
  action?: (id: number) => void;
  editAction?: (id: number) => void;
  id?: number;
  extraButton?: ReactNode;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded);

  const { setNodeRef, attributes, listeners, transform, transition } =
    useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded((isExpanded) => !isExpanded);
  }, []);

  return (
    <div
      className={`${isExpanded && "h-auto bg-[url('assets/icons/grey_dot.svg')] bg-repeat-y"} pb-5`}
      style={{ ...style, backgroundPositionX: '6px' }}
      ref={setNodeRef}
    >
      <div className="row-auto flex h-5 w-full cursor-pointer items-center gap-2">
        <div
          className="flex flex-row items-center gap-2"
          onClick={toggleIsExpanded}
        >
          <ArrowCollapse
            className={`transition-all duration-300 ease-in-out ${isExpanded ? 'rotate-0' : 'rotate-180'}`}
          />
        </div>
        <div
          className="flex min-h-10 w-full items-center justify-between gap-4 rounded-md px-2 transition-colors hover:bg-lightPurple"
          onClick={toggleIsExpanded}
        >
          <div className="flex items-center">
            <span className="mr-1 text-sm font-bold">
              {titleHref ? (
                <LinkBehavior className="hover:underline" href={titleHref}>
                  {title}
                </LinkBehavior>
              ) : (
                title
              )}
            </span>
            {subText && <span className="text-sm">{subText}</span>}
          </div>
          <div className="flex flex-row items-center gap-4">
            {extraButton && extraButton}
            {draggable && (
              <span
                className="flex cursor-pointer"
                {...attributes}
                {...listeners}
              >
                <HandlerIcon />
              </span>
            )}
            {action && (
              <span
                className="flex cursor-pointer"
                onClick={(e: MouseEvent) => {
                  e.stopPropagation();
                  e.preventDefault();
                  action(id);
                }}
              >
                <UnLink className="text-gray-500 opacity-50" />
              </span>
            )}
            {editAction && (
              <span
                className="flex cursor-pointer"
                onClick={(e: MouseEvent) => {
                  e.stopPropagation();
                  e.preventDefault();
                  editAction(id);
                }}
              >
                <EditPencil className="text-gray-500 opacity-50" />
              </span>
            )}
          </div>
        </div>
      </div>
      <div
        className={`transition-height ml-5 mt-5 overflow-hidden duration-300 ease-in-out ${isExpanded ? 'h-auto' : 'h-0'}`}
      >
        {children}
      </div>
    </div>
  );
};

import { twMerge } from 'tailwind-merge';
import { LinkBehavior } from '..';

export type BreadcrumbItem = {
  label: string;
  className?: string;
  href?: string;
};

type BreadcrumbsProps = {
  items: BreadcrumbItem[];
};

export const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  if (!items.length) return null;
  return (
    <div className="text-xs">
      {items.map((item) => (
        <span
          key={item.label}
          className={twMerge(
            "whitespace-pre after:content-['_/_'] last:font-black last:after:content-['']",
            item.className,
          )}
        >
          {item.href ? (
            <LinkBehavior key={item.label} href={item.href}>
              {item.label}
            </LinkBehavior>
          ) : (
            item.label
          )}
        </span>
      ))}
    </div>
  );
};

import { automationsRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { AutomationKey } from './keys';

export const useGetAssociatedTasks = (automationId: number) =>
  useQuery(
    AutomationKey.TASK_PERFORMANCE(automationId),
    () => automationsRepo.getTasksPerformanceByAutomationId(automationId),
    {
      enabled: !!automationId,
    },
  );

import {
  convertSecondsToTimeString,
  getTotalDurationBySeconds,
  trunc,
} from '@/utils';
import { ReactElement, useMemo } from 'react';
import { Container } from '../Container';
import { PieChart, PieData } from '../PieChart';

enum ValueType {
  hours = 'h',
  percentage = '%',
  coin = '€',
}

interface PieChartCardProps {
  data: Array<PieData>;
  chartTitle: string;
  valueType: keyof typeof ValueType;
  subtitle?: string;
  tooltip?: string;
  convert?: boolean; //TODO: this prop is to convert count/percentage to Time in Total label on Process and Workflow details page. Rename it or find another way to do it
}
export const PieChartCard = ({
  data,
  valueType,
  chartTitle,
  subtitle,
  convert,
  tooltip,
}: PieChartCardProps): ReactElement => {
  const formatNumber = (num: number): string => {
    const roundToTwoDecimals = (value: number): number => {
      return Math.round(value * 100) / 100;
    };

    const formatDecimal = (value: number): string => {
      return value.toFixed(2).replace(/\.?0+$/, '');
    };

    if (num >= 1000000) {
      const millions = roundToTwoDecimals(num / 1000000);
      return formatDecimal(millions) + 'M';
    } else if (num >= 1000) {
      const thousands = roundToTwoDecimals(num / 1000);
      return formatDecimal(thousands) + 'k';
    }
    return formatDecimal(num);
  };

  const total = useMemo(
    () =>
      data.reduce((acc, obj) => {
        let value = obj.value ?? 0;

        if (valueType === 'coin') {
          value = Math.trunc(obj.value);
        }

        return acc + value;
      }, 0),
    [data, valueType],
  );

  const tagsData = useMemo(() => {
    if (valueType === 'percentage') {
      return data.map((obj) => ({
        ...obj,
        value: obj.value ? Math.round((obj.value / Number(total)) * 100) : 0,
      }));
    }

    if (valueType === 'coin') {
      return data.map((obj) => ({
        ...obj,
        value: trunc(obj.value, 2),
      }));
    }

    if (valueType === 'hours') {
      return data.map((obj) => {
        const seconds = obj.value ?? 0;

        const { hours } = getTotalDurationBySeconds(seconds);
        return {
          ...obj,
          value: trunc(hours, 2),
        };
      });
    }

    return data;
  }, [data, total, valueType]);

  const formattedTotal = useMemo(() => {
    if (valueType === 'hours') {
      const { hours } = getTotalDurationBySeconds(total);
      return Math.trunc(hours).toString() ?? '0h';
    }

    if (valueType === 'coin') {
      return formatNumber(Math.trunc(total));
    }

    if (convert) {
      return convertSecondsToTimeString(total) ?? '0h';
    }

    return formatNumber(Math.round(total));
  }, [total, valueType, convert]);

  const formatTagValue = (value: number) => {
    if (valueType === 'percentage') {
      return `${formatNumber(value)}%`;
    }
    if (valueType === 'coin') {
      return `${formatNumber(value)}€`;
    }
    if (valueType === 'hours') {
      return `${value}${ValueType[valueType]}`;
    }
    return formatNumber(value);
  };

  return (
    <Container
      title={chartTitle}
      className="text-sm font-black"
      tooltip={tooltip}
    >
      <div className="flex items-stretch gap-5">
        <div className="flex items-center justify-center self-stretch">
          <PieChart
            data={data}
            mainLabel={formattedTotal}
            subtitle={subtitle}
          />
        </div>
        <div className="flex flex-col items-stretch justify-center gap-1">
          <ul>
            {tagsData.map(({ name, value, fill }) => (
              <li key={name} className="mb-1">
                <div className="flex min-w-52 items-center justify-between gap-2">
                  <div className="inline-flex items-center gap-2">
                    <span
                      className="inline-block size-3 rounded-full"
                      style={{ backgroundColor: fill }}
                    ></span>
                    <span className="font-medium">{name}</span>
                  </div>
                  <span className="font-black">{formatTagValue(value)}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Container>
  );
};

import { processesRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { ProcessModel } from '../domain';
import { ProcessKey } from './keys';

export const useGetProcess = (id: number) =>
  useQuery<ProcessModel>(
    ProcessKey.BY_ID(id),
    () => processesRepo.getProcess(id),
    {
      enabled: !!id,
    },
  );

import { useGetOrganization } from '@/modules/Organizations/queries/get-organization';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';
import { useUpdateOrganization } from '@/modules/Organizations/queries/update-organization';
import { OrganizationSchemaType } from '@/modules/Organizations/types';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { OrganizationModal } from '../OrganizationModal';

interface EditOrganizationModalProps {
  isOpen: boolean;
  organizationId: number;
  handleClose: () => void;
}

export const EditOrganizationModal = ({
  isOpen,
  organizationId,
  handleClose,
}: EditOrganizationModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useUpdateOrganization();
  const { data: organization, isLoading: isLoadingGet } =
    useGetOrganization(organizationId);

  const handleConfirm = (data: OrganizationSchemaType) => {
    if (!organizationId) {
      return toast.error(t('generic_errors'));
    }

    const formData = new FormData();

    formData.append('id', (data.id ?? organizationId).toString());
    formData.append('description', data.organizationDescription ?? '');
    formData.append('name', data.organizationName);
    formData.append('status', '1');
    data.file && formData.append('file', data.file);
    data.fileId && formData.append('fileId', data.fileId.toString());

    mutate(formData, {
      onError: () => {
        toast.error(t('error_update_organization'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(OrganizationKeys.ALL);
        handleClose();
        toast.success(t('success_update_organization'));
      },
    });
  };

  return (
    <OrganizationModal
      handleClose={handleClose}
      type="edit"
      handleOnSubmit={handleConfirm}
      title={t('edit_organization')}
      isOpen={isOpen}
      isLoading={isLoading || isLoadingGet}
      name={organization?.name}
      description={organization?.description}
      profileImageFileId={organization?.profileImageFileId}
    />
  );
};

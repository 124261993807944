import { ApiResponse, DataWithPagination } from '@/shared/types';
import { UserModel } from '../domain';
import {
  CreateUserJson,
  EditUserJson,
  EditUserRoleJson,
  HourlyRateJson,
  ChangePasswordJson,
} from '../types';
import { UserProfilesModel } from '../domain/user-profiles-model';
import { UserRateModel } from '../domain/user-rate-model';

export abstract class UserRepository {
  abstract getAllUsers(
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<UserModel[]>>;
  abstract getAllUsersBaseInfo(searchTerm?: string): Promise<Array<UserModel>>;
  abstract getUser(userId: number): Promise<UserModel>;
  abstract enableUser(id: number): Promise<ApiResponse<unknown>>;
  abstract disableUser(id: number): Promise<ApiResponse<unknown>>;
  abstract addAccess(id: number): Promise<ApiResponse<unknown>>;
  abstract removeAccess(id: number): Promise<ApiResponse<unknown>>;
  abstract createUser(
    userJson: CreateUserJson,
  ): Promise<ApiResponse<{ data: { userId: number } }>>;
  abstract getUserProfiles(): Promise<UserProfilesModel[]>;
  abstract getUsersByBusinessArea(
    businessAreaId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<UserModel[]>>;
  abstract getUsersByCompany(
    companyId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<UserModel[]>>;
  abstract getUsersByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<UserModel[]>>;
  abstract getUsersByTask(
    taskId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<UserModel[]>>;
  abstract updateUser(userJson: EditUserJson): Promise<ApiResponse<unknown>>;
  abstract updateRole(
    userJson: EditUserRoleJson,
  ): Promise<ApiResponse<unknown>>;
  abstract getCurrentUser(token: string, tenantId: number): Promise<UserModel>;
  abstract csvUserImport(data: FormData): Promise<void>;
  abstract getUserRate(
    userId: number,
  ): Promise<DataWithPagination<UserRateModel[]>>;
  abstract createUserRate(
    userJson: HourlyRateJson,
  ): Promise<ApiResponse<{ data: { userId: number } }>>;
  abstract updateUserRate(
    userJson: HourlyRateJson,
  ): Promise<ApiResponse<unknown>>;
  abstract changePassword(
    changePasswordJson: ChangePasswordJson,
  ): Promise<ApiResponse<unknown>>;
  abstract deleteUser(Id: number): Promise<ApiResponse<unknown>>;
}

import {
  Button,
  CustomPageContainer,
  FilterAndPaginationContext,
  FilterSearchBar,
  Table,
} from '@/components';
import { TableRow, TableSort } from '@/components/Table/types';
import { queryClient } from '@/constants';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useToggleTaskStatus } from '../../hooks/use-toggle-task-status';
import { TaskKey, useDeleteTask, useGetTasks } from '../../queries';
import { CreateTaskModal } from './components/CreateTaskModal';
import { getTasksColumns, getTasksColumnsNamedMapped } from './utils';

export const TaskPage = (): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const [tableSort, setTableSort] = useState<TableSort[]>([]);
  const navigate = useNavigate();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();
  const { onChange: onTaskStatusChange } = useToggleTaskStatus();

  const { searchTerm, page, onPageChange, handleSearchChange } = useContext(
    FilterAndPaginationContext,
  );

  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const {
    data: tasks,
    isLoading: isLoadingTasks,
    isError,
  } = useGetTasks(
    page + 1,
    searchTerm,
    getTasksColumnsNamedMapped(tableSort?.[0]?.columnId ?? 'name'),
    tableSort?.[0]?.direction ?? 'ascending',
  );

  const { mutate: deleteTask } = useDeleteTask();

  const handleDelete = (taskId: number) => {
    deleteTask(taskId, {
      onError: () => {
        toast.error(t('error_deleting_task'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(TaskKey.ALL);
        toast.success(t('success_deleting_task'));
      },
    });
  };

  useEffect(() => {
    if (isError) {
      toast.error(t('error_getting_tasks'));
    }
  }, [isError, t]);

  const routeChange = useCallback(
    (row?: TableRow) => {
      const path = `${row?.id}`;
      navigate(path);
    },
    [navigate],
  );

  const handleOnStatusChange = useCallback(
    (checked: boolean, row: TableRow) => {
      onTaskStatusChange(checked, +row.id);
    },
    [onTaskStatusChange],
  );

  return (
    <CustomPageContainer>
      <CustomPageContainer.Header title={t('tasks')}>
        <Button onClick={handleModalState} variant="primary" className="h-9">
          {t('create_task')}
        </Button>
      </CustomPageContainer.Header>
      <FilterSearchBar
        total={tasks?.totalRows || 0}
        title={t('tasks')}
        onSearch={handleSearchChange}
        handleInputSearchChange={handleInputSearchChange}
        searchInputValue={searchInputValue}
      />
      <CreateTaskModal handleClose={handleModalState} isOpen={isOpen} />
      <Table
        columns={getTasksColumns({
          onClick: routeChange,
          disableDeleteMessage: t('delete_task'),
          onDeleteClick(row) {
            handleDelete(row?.id as number);
          },
          onStatusChange: handleOnStatusChange,
        })}
        rows={tasks?.items ?? []}
        isLoading={isLoadingTasks}
        noResultsLabel={t('no_tasks_available')}
        withPagination
        totalItems={tasks?.totalRows}
        totalPages={tasks?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={setTableSort}
      />
    </CustomPageContainer>
  );
};

import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

interface InputWithTitleProps {
  title: string;
  hidden?: boolean;
}

const InputWithTitle = ({
  title,
  children,
  hidden,
}: PropsWithChildren<InputWithTitleProps>) => {
  return (
    <div
      className={twMerge('mt-4 flex w-full flex-col gap-2', hidden && 'hidden')}
    >
      <div className="text-sm font-bold capitalize">{title}</div>
      {children}
    </div>
  );
};

export default InputWithTitle;

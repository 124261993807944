import { usersRepo } from '@/constants/providers';
import { QueryOptions } from '@/modules/types';
import { useQuery } from '@tanstack/react-query';
import { UserModel } from '../domain';
import { UsersKeys } from './types';

export const useGetUser = (userId: number, options?: QueryOptions<UserModel>) =>
  useQuery<UserModel>(
    UsersKeys.BY_ID(userId),
    () => usersRepo.getUser(userId),
    {
      ...options,
    },
  );

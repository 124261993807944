import { z } from 'zod';

export const InfrastructureCostSchema = z.object({
  costTypeId: z.number().min(1, { message: 'error_field_required' }),
  name: z.string({ required_error: 'error_field_required' }),
  amount: z.string().min(1, { message: 'error_field_required' }),
  amountRecurrence: z.string({ required_error: 'error_field_required' }),
  description: z.string().optional(),
  startDate: z.date({ required_error: 'error_field_required' }),
  endDate: z.date().optional(),
});

export type FormInfrastructureCostSchemaType = z.infer<
  typeof InfrastructureCostSchema
>;

export interface InfrastructureCostSchemaType
  extends FormInfrastructureCostSchemaType {
  id?: number;
}

export const EditInfrastructureCostSchema = z.object({
  costTypeId: z.number().min(1, { message: 'error_field_required' }),
  name: z.string({ required_error: 'error_field_required' }),
  amountRecurrence: z.string({ required_error: 'error_field_required' }),
  description: z.string().optional(),
});

export const AmountSchema = z.object({
  amount: z.string().min(1, { message: 'error_field_required' }),
  startDate: z.preprocess(
    (val) => {
      const date =
        typeof val === 'string' || val instanceof Date
          ? new Date(val)
          : undefined;
      return isNaN(date?.getTime() ?? NaN) ? undefined : date;
    },
    z.date({ required_error: 'error_field_required' }),
  ),
  endDate: z.preprocess((val) => {
    const date =
      typeof val === 'string' || val instanceof Date
        ? new Date(val)
        : undefined;
    return isNaN(date?.getTime() ?? NaN) ? undefined : date;
  }, z.date().optional()),
});

export type FormAmountSchemaType = z.infer<typeof AmountSchema>;

export interface AmountSchemaType extends FormAmountSchemaType {
  id?: number;
}

export type FormEditInfrastructureCostSchemaType = z.infer<
  typeof EditInfrastructureCostSchema
>;

export interface EditInfrastructureCostSchemaType
  extends FormEditInfrastructureCostSchemaType {
  id?: number;
}

import { forwardRef } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

export const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  return (
    <RouterLink
      data-testid="custom-link"
      ref={ref}
      to={href}
      {...other}
      className={
        other.className ??
        'text-primaryBlue hover:text-spartanBlue active:text-plumBlue'
      }
    />
  );
});

LinkBehavior.displayName = 'LinkBehavior';

import { workflowsRepo } from '@/constants/providers';
import { WorkflowModel } from '@/modules/Workflows/domain';
import { useQuery } from '@tanstack/react-query';
import { WorkflowKey } from './keys';

export const useGetAllWorkflowsByProcess = (processId: number) =>
  useQuery<WorkflowModel[]>(
    WorkflowKey.BY_PROCESS(processId),
    () => workflowsRepo.getWorkflowsByProcessId(processId),
    {
      enabled: !!processId,
    },
  );

export const useGetAllWorkflowsByProcessIdAutomation = (processId: number) =>
  useQuery<WorkflowModel[]>(
    WorkflowKey.BY_PROCESS_AUTOMATION(processId),
    () => workflowsRepo.getWorkflowsByProcessIdAutomations(processId),
    {
      enabled: !!processId,
      cacheTime: 5 * 60 * 1000, // 5 minutes
    },
  );

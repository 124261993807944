import { SelectDropdownOption } from '@/components';

type Option<T> = {
  name?: string;
  id: T;
};

export const getDropdownArray = <T>(
  data: Option<T>[],
): Array<SelectDropdownOption<T>> => {
  return data.map((val) => ({
    label: val.name ?? '',
    value: val.id,
  }));
};

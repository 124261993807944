import { ChangeTenantModal } from '@/components/ChangeTenantModal';
import {
  PREVIOUS_TENANT_ID,
  TENANT_ID_SELECTED,
  TenantContext,
} from '@/components/TenantProvider';
import { RoutePath } from '@/core/router';
import { useGetUserByAccessTokenAndTenantId } from '@/modules/Users/queries';
import { AuthUserContext } from '@/providers/authUserProvider';
import { useKeycloak } from '@react-keycloak/web';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetUserTenants } from '../queries';

export const TenantPicker = () => {
  const { data: userTenants, isLoading } = useGetUserTenants();
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const { setAuthUser } = useContext(AuthUserContext);
  const { onTenantIdChange: onTenantIdContextChange } =
    useContext(TenantContext);

  const [selectedTenantId, setSelectedTenantId] = useState<number | undefined>(
    sessionStorage.getItem(TENANT_ID_SELECTED)
      ? +sessionStorage.getItem(TENANT_ID_SELECTED)!
      : undefined,
  );

  const { data: currentUser } = useGetUserByAccessTokenAndTenantId(
    keycloak.token,
    selectedTenantId,
  );

  const [isTenantModalOpen, setIsTenantModalOpen] = useState(false);
  const handleModalClose = useCallback(() => setIsTenantModalOpen(false), []);

  const onTenantIdChange = useCallback(
    (tenantId: number) => {
      setSelectedTenantId(tenantId);
      onTenantIdContextChange(tenantId);
      sessionStorage.setItem(TENANT_ID_SELECTED, tenantId.toString());
      handleModalClose();
    },
    [handleModalClose, onTenantIdContextChange],
  );

  useEffect(() => {
    if (!userTenants || isLoading) {
      return;
    }

    if (userTenants.length === 1) {
      onTenantIdChange(userTenants[0].id);
    } else if (userTenants.length > 1 && !selectedTenantId) {
      setIsTenantModalOpen(true);
    }
  }, [isLoading, onTenantIdChange, selectedTenantId, userTenants]);

  useEffect(() => {
    if (!selectedTenantId || !currentUser) {
      return;
    }

    const redirectTo = localStorage.getItem('redirectAfterLogin');
    localStorage.removeItem('redirectAfterLogin');
    if (
      redirectTo &&
      sessionStorage.getItem(PREVIOUS_TENANT_ID) === selectedTenantId.toString()
    ) {
      navigate(redirectTo);
      return;
    }

    sessionStorage.setItem(PREVIOUS_TENANT_ID, selectedTenantId.toString());

    const firstOrganization = currentUser.organizations[0];

    setAuthUser(currentUser);

    navigate(
      !firstOrganization || currentUser.organizations.length > 1
        ? RoutePath.organizations()
        : RoutePath.organizationDetail(firstOrganization.toString()),
    );
  }, [currentUser, navigate, selectedTenantId, setAuthUser, userTenants]);

  return (
    <ChangeTenantModal
      isOpen={isTenantModalOpen}
      handleClose={handleModalClose}
      handleSave={onTenantIdChange}
      isLoading={isLoading}
      selectedTenantId={selectedTenantId}
      tenants={userTenants ?? []}
    />
  );
};

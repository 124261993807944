import { SortDirection } from '@/components/Table/types';
import { processesRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ProcessModel } from '../domain';
import { ProcessKey } from './keys';

export const useGetProcesses = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<ProcessModel[]>,
    any,
    DataWithPagination<ProcessModel[]>
  >,
) =>
  useQuery<DataWithPagination<ProcessModel[]>>(
    ProcessKey.LIST_PAGED({ pageNumber, filter, sortBy, sortOrder }),
    () => processesRepo.getAllProcesses(pageNumber, filter, sortBy, sortOrder),
    { ...options, cacheTime: 1000 * 60 * 5 },
  );

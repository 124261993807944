import { usersRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { UserModel } from '../domain';
import { UsersKeys } from './types';

export const useGetUserByAccessTokenAndTenantId = (
  token: string | undefined,
  tenantId: number | undefined,
) =>
  useQuery<UserModel | undefined>(
    [...UsersKeys.CURRENT_USER, token, tenantId],
    () => {
      if (!token || !tenantId) return;
      return usersRepo.getUserByAccessTokenAndTenantId(token, tenantId);
    },
    {
      enabled: !!token && !!tenantId,
    },
  );

export const useGetCurrentUser = () =>
  useQuery<UserModel | undefined>(UsersKeys.CURRENT_USER, () => {
    return usersRepo.getCurrentUser();
  });

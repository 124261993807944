import { BusinessAreasKey } from '@/modules/BusinessAreas/queries/keys';
import { CompanyKeys } from '@/modules/Companies/queries';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';
import { ProcessKey } from '@/modules/Processes/queries';
import { WorkflowKey, useCreateWorkflow } from '@/modules/Workflows/queries';
import { WorkflowSchemaType } from '@/modules/Workflows/types';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { WorkflowForm } from '../WorkflowForm';
import { WorkflowModal } from '../WorkflowModal';

interface CreateWorkflowModalProps {
  isOpen: boolean;
  handleClose: () => void;
  processId?: number;
  organizationId?: number;
  companyId?: number;
  businessAreaId?: number;
  disableFields?: string[];
}

export const CreateWorkflowModal = ({
  isOpen,
  handleClose,
  processId,
  organizationId,
  companyId,
  businessAreaId,
  disableFields,
}: CreateWorkflowModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateWorkflow();

  const handleConfirm = (data: WorkflowSchemaType) => {
    const convertedData = {
      id: data.id ?? 0,
      name: data.workflowName,
      description: data.description,
      businessAreaId: Number(data.businessAreaId),
      processId: data.processId ? Number(data.processId) : undefined,
      status: true,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_creating_workflow'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(WorkflowKey.ALL);
        queryClient.invalidateQueries(OrganizationKeys.ALL);
        queryClient.invalidateQueries(BusinessAreasKey.ALL);
        queryClient.invalidateQueries(CompanyKeys.ALL);
        queryClient.invalidateQueries(ProcessKey.ALL);
        handleClose();
        toast.success(t('success_creating_workflow'));
      },
    });
  };
  if (location.pathname.includes('processes')) {
    return (
      <WorkflowForm
        handleClose={handleClose}
        type="create"
        handleOnSubmit={handleConfirm}
        isOpen={isOpen}
        isLoading={isLoading}
        processId={processId}
        organizationId={organizationId}
        companyId={companyId}
        businessAreaId={businessAreaId}
        disableFields={disableFields}
      />
    );
  }

  return (
    <WorkflowModal
      handleClose={handleClose}
      type="create"
      handleOnSubmit={handleConfirm}
      title={t('create_workflow')}
      isOpen={isOpen}
      isLoading={isLoading}
      processId={processId}
      organizationId={organizationId}
      companyId={companyId}
      businessAreaId={businessAreaId}
      disableFields={disableFields}
    />
  );
};

import {
  Button,
  ChevronLeft,
  Collapse,
  CustomPageContainer,
  EditPencil,
  Table,
  UnLink,
} from '@/components';
import { BreadcrumbItem, Breadcrumbs } from '@/components/Breadcrumbs';
import { TableRow } from '@/components/Table/types';
import { queryClient } from '@/constants';
import { HttpError } from '@/core/http';
import { RoutePath } from '@/core/router';
import { TaskModel } from '@/modules/Tasks/domain';
import { EditTaskModal } from '@/modules/Tasks/pages/TasksPage/components/EditTaskModal';
import { useOrderTask } from '@/modules/Tasks/queries';
import { WorkflowModel } from '@/modules/Workflows/domain';
import { WorkflowTaskModal } from '@/modules/Workflows/pages/WorkflowDetailsPage/components';
import { EditWorkflowModal } from '@/modules/Workflows/pages/WorkflowsPage/components/EditWorkflowModal';
import {
  useDisassociateTaskFromWorkflow,
  useOrderWorkflow,
} from '@/modules/Workflows/queries';
import {
  automatedColumnConfig,
  businessAreaNameColumnConfig,
  codeColumnConfig,
  estimatedCostColumnConfig,
  nameWithAvatarColumnConfig,
  periodColumnConfig,
  statusColumnConfig,
  usersCountColumnConfig,
  workloadColumnConfig,
} from '@/utils';
import { dividerColumnConfig } from '@/utils/tableColumns/divider-column-config';
import { processCompanyNameColumnConfig } from '@/utils/tableColumns/process-company-name-column-config';
import { processOrganizationNameColumnConfig } from '@/utils/tableColumns/process-organization-name-column-config';
import {
  Active,
  DndContext,
  Over,
  UniqueIdentifier,
  useDroppable,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { HttpStatusCode } from 'axios';
import { t } from 'i18next';
import {
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ProcessKey,
  useDisableProcess,
  useDisassociateWorkflowFromProcess,
  useEnableProcess,
  useGetProcess,
} from '../../queries';
import { HeaderRow, ProcessPerformanceCards } from './components';
import { ProcessPerformanceCharts } from './components/ProcessPerformanceCharts';
import { ProcessWorkflowModal } from './components/ProcessWorkflowModal';

export const ProcessDetailPage = (): ReactElement => {
  const [workflows, setWorkflows] = useState<WorkflowModel[]>([]);
  const [status, setStatus] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenTaskModal, setIsOpenTaskModal] = useState<boolean>(false);
  const [isOpenEditTaskModal, setIsOpenEditTaskModal] =
    useState<boolean>(false);
  const [isOpenEditWorkflowModal, setIsOpenEditWorkflowModal] =
    useState<boolean>(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState<WorkflowModel>();
  const [selectedTask, setSelectedTask] = useState<number>();
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<number>();

  const navigate = useNavigate();
  const { processId } = useParams<{ processId: any }>();

  const {
    data: process,
    isLoading: isLoadingProcess,
    isError,
    error,
  } = useGetProcess(processId);

  const handleModalState = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseAddTaskModal = () => {
    setIsOpenTaskModal(false);
    setSelectedWorkflow(undefined);
  };

  const handleAddTaskModalState = (workflow: WorkflowModel) => {
    setIsOpenTaskModal(!isOpen);
    setSelectedWorkflow(workflow);
  };

  const handleCloseEditTaskModal = () => {
    setIsOpenEditTaskModal(false);
    setSelectedTask(undefined);
  };

  const handleEditTaskModalState = (taskId: number) => {
    setIsOpenEditTaskModal(!isOpen);
    setSelectedTask(taskId);
  };

  const handleCloseEditWorkflowModal = () => {
    setIsOpenEditWorkflowModal(false);
    setSelectedWorkflowId(undefined);
  };

  const handleEditWorkflowModalState = (workflowId: number) => {
    setIsOpenEditWorkflowModal(!isOpen);
    setSelectedWorkflowId(workflowId);
  };

  useEffect(() => {
    const workflowsClone = [...(process?.workflows ?? [])];
    workflowsClone.sort((workflowsA, workflowsB) =>
      workflowsA.order > workflowsB.order ? 1 : -1,
    );
    setWorkflows(workflowsClone);
  }, [process?.workflows]);

  useEffect(() => {
    if (isError && error) {
      if ((error as HttpError<unknown>)?.code === HttpStatusCode.NotFound) {
        navigate(RoutePath.notFound());
      } else {
        toast.error(t('error_get_process_details'));
      }
    }
  }, [isError, error, navigate]);

  useEffect(() => {
    setStatus(process?.status === true);
  }, [process]);

  const { mutate: enableProcess } = useEnableProcess();
  const { mutate: disableProcess } = useDisableProcess();
  const { mutate: orderTask } = useOrderTask();
  const { mutate: orderWorkflow } = useOrderWorkflow();
  const { mutate: disassociateWorkflow } = useDisassociateWorkflowFromProcess();
  const { mutate: disassociateTask } = useDisassociateTaskFromWorkflow();

  const handleDisassociateWorkflow = useCallback(
    (id: number) => {
      disassociateWorkflow(
        { processId: processId, workflowId: id },
        {
          onError: () => {
            toast.error(t('error_disassociate_workflow'));
          },
          onSuccess: () => {
            toast.success(t('success_disassociate_workflow'));
            queryClient.invalidateQueries(ProcessKey.ALL);
          },
        },
      );
    },
    [disassociateWorkflow, processId],
  );

  const handleDisassociateTask = useCallback(
    (workflowId: number, id: number) => {
      if (!workflowId || !id) {
        return toast.error(t('generic_errors'));
      }
      disassociateTask(
        {
          workflowId,
          taskId: id,
        },
        {
          onError: () => {
            toast.error(t('error_disassociate_tasks_from_workflow'));
          },
          onSuccess: () => {
            toast.success(t('success_disassociate_tasks_from_workflow'));
            queryClient.invalidateQueries(ProcessKey.ALL);
          },
        },
      );
    },
    [disassociateTask],
  );

  const handleStatus = useCallback(
    (checked: boolean) => {
      if (!process?.id) {
        return toast.error(t('generic_errors'));
      }
      setStatus(checked);
      if (checked) {
        enableProcess(process?.id, {
          onError: () => {
            toast.error(t('error_enable_process'));
          },
          onSuccess: () => {
            toast.success(t('success_enable_process'));
            queryClient.invalidateQueries(ProcessKey.ALL);
          },
        });
      } else {
        disableProcess(process?.id, {
          onError: () => {
            toast.error(t('error_disable_process'));
          },
          onSuccess: () => {
            toast.success(t('success_disable_process'));
            queryClient.invalidateQueries(ProcessKey.ALL);
          },
        });
      }
    },
    [process, enableProcess, disableProcess],
  );

  const routeChange = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const getWorkflowPos = (id?: UniqueIdentifier) =>
    workflows.findIndex((workflow) => workflow?.id === id);

  const handleDragEndWorkflow = async (event: {
    active: Active;
    over: Over | null;
  }) => {
    const { active, over } = event;
    if (active.id === over?.id) return;

    const originalPos = getWorkflowPos(active.id);
    const newPos = getWorkflowPos(over?.id);
    const newPositions = arrayMove(workflows, originalPos, newPos);
    setWorkflows(newPositions);

    const payload = {
      id: active.id as number,
      order: newPositions.findIndex((el) => el.id === active.id) + 1,
    };
    return orderWorkflow(payload, {
      onError: () => {
        toast.error(t('error_order_workflow'));
        setWorkflows([]);
      },
    });
  };

  const getTaskPos = (workflowId: number, id?: UniqueIdentifier) => {
    const tempWorkflow = workflows.find((el) => el.id === workflowId);
    return tempWorkflow?.tasks.findIndex((task) => task?.id === id);
  };

  const handleDragEndTask = async (
    event: { active: Active; over: Over | null },
    workflowId: number,
  ) => {
    const { active, over } = event;

    if (active.id === over?.id) return;

    const originalPos = getTaskPos(workflowId, active.id);
    const newPos = getTaskPos(workflowId, over?.id);
    const tempWorkflow = workflows.find((el) => el.id === workflowId);

    if (
      tempWorkflow?.tasks &&
      originalPos !== undefined &&
      newPos !== undefined
    ) {
      const newPositions = arrayMove(tempWorkflow.tasks, originalPos, newPos);
      const workflowIndex = workflows.findIndex((el) => el.id === workflowId);
      workflows[workflowIndex].tasks = newPositions as TaskModel[];

      setWorkflows(() =>
        Object.assign([], {
          ...workflows,
          [workflowIndex]: { ...workflows[workflowIndex], tasks: newPositions },
        }),
      );
      const payload = {
        id: active.id as number,
        order: newPositions.findIndex((el) => el.id === active.id) + 1,
      };

      return orderTask(payload, {
        onError: () => {
          toast.error(t('error_order_task'));
          setWorkflows([]);
        },
      });
    }
  };

  const { setNodeRef } = useDroppable({
    id: 'workflow',
  });

  const routeChangeTask = (row?: TableRow) => {
    const path = `/tasks/${row?.id}`;
    navigate(path);
  };

  const breadcrumbs = useMemo<BreadcrumbItem[]>(() => {
    if (!process) return [];
    return [
      {
        label: process.organization,
        href: RoutePath.organizationDetail(process.organizationId.toString()),
      },
      {
        label: process.company,
        href: RoutePath.companyDetail(process.companyId.toString()),
      },
      {
        label: process.businessAreaName,
        href: RoutePath.businessAreaDetail(process.businessAreaId.toString()),
      },
      {
        label: t('processes'),
        href: RoutePath.processes(),
      },
      {
        label: process.name,
      },
    ];
  }, [process]);

  return (
    <CustomPageContainer>
      <div className="flex flex-col gap-3">
        <Button
          className="h-6 w-14 justify-between border-blueNuit p-2 font-black text-blueNuit"
          variant="outline"
          onClick={routeChange}
        >
          <ChevronLeft />
          {t('back')}
        </Button>
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <HeaderRow
        process={process}
        handleStatus={handleStatus}
        status={status}
        isLoading={isLoadingProcess}
      />
      <ProcessPerformanceCharts processPerformance={process} />
      <ProcessPerformanceCards isLoading={isLoadingProcess} data={process} />
      <div className="flex flex-row justify-between pb-5">
        <h2>{t('process')}</h2>
      </div>
      <WorkflowTaskModal
        handleClose={handleCloseAddTaskModal}
        isOpen={isOpenTaskModal}
        disableFields={['businessAreaId', 'organizationId', 'companyId']}
        workflow={selectedWorkflow}
      />
      <>
        <DndContext onDragEnd={handleDragEndWorkflow}>
          <SortableContext
            items={workflows || []}
            strategy={verticalListSortingStrategy}
          >
            <div className="h-full" ref={setNodeRef}>
              {workflows &&
                workflows.map((workflow, index) => (
                  <Collapse
                    title={workflow.name}
                    titleHref={RoutePath.workflowDetail(workflow.id.toString())}
                    subText={`(${workflow.tasks.length} Tasks)`}
                    key={index}
                    draggable
                    action={handleDisassociateWorkflow}
                    editAction={handleEditWorkflowModalState}
                    id={workflow.id}
                    extraButton={
                      <Button
                        onClick={(e: MouseEvent) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleAddTaskModalState(workflow);
                        }}
                        variant="text"
                        className="h-9 font-bold text-blueNuit underline"
                      >
                        + {t('add_task')}
                      </Button>
                    }
                  >
                    <DndContext
                      onDragEnd={(event) =>
                        handleDragEndTask(event, workflow.id)
                      }
                    >
                      <Table
                        columns={[
                          nameWithAvatarColumnConfig({
                            onClick: routeChangeTask,
                          }),
                          codeColumnConfig(),
                          processOrganizationNameColumnConfig(),
                          processCompanyNameColumnConfig(),
                          businessAreaNameColumnConfig(),
                          statusColumnConfig(),
                          usersCountColumnConfig(),
                          dividerColumnConfig({ key: 'divider-1' }),
                          periodColumnConfig(),
                          workloadColumnConfig(),
                          estimatedCostColumnConfig(),
                          dividerColumnConfig({ key: 'divider-2' }),
                          automatedColumnConfig(),
                        ]}
                        rows={
                          workflows.find((el) => el.id == workflow.id)?.tasks ||
                          []
                        }
                        noResultsLabel={t('no_tasks_available')}
                        draggable
                        actionCallbackDisassociate={(rowId) =>
                          handleDisassociateTask(workflow.id, rowId)
                        }
                        actionCallbackEdit={(rowId) =>
                          handleEditTaskModalState(rowId)
                        }
                        renderUnlink={() => (
                          <UnLink className="text-gray-500 opacity-50" />
                        )}
                        renderEdit={() => (
                          <EditPencil className="text-gray-500 opacity-50" />
                        )}
                        showDots
                      />
                    </DndContext>
                  </Collapse>
                ))}
            </div>
          </SortableContext>
        </DndContext>
      </>
      <Button
        className="h-6 w-full justify-between border-blueNuit p-2 font-black text-blueNuit"
        variant="text"
        onClick={handleModalState}
      >
        <span className="flex flex-row">+ {t('add_workflow')}</span>
      </Button>
      <ProcessWorkflowModal
        handleClose={handleModalState}
        isOpen={isOpen}
        process={process}
        disableFields={[
          'processId',
          'organizationId',
          'companyId',
          'businessAreaId',
        ]}
      />
      {selectedTask && (
        <EditTaskModal
          isOpen={isOpenEditTaskModal}
          id={selectedTask}
          handleClose={handleCloseEditTaskModal}
        />
      )}
      {selectedWorkflowId && (
        <EditWorkflowModal
          isOpen={isOpenEditWorkflowModal}
          id={selectedWorkflowId}
          handleClose={handleCloseEditWorkflowModal}
        />
      )}
    </CustomPageContainer>
  );
};

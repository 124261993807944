import { useCallback, useEffect, useState } from 'react';
import { useGetFileBlob } from '../mutation/use-get-file-blob';
import { FileUtility } from '../util/file.util';

type FileDownload = {
  id: string;
  name: string;
};

export const useDownloadFile = () => {
  const [selectedFile, setSelectedFile] = useState<FileDownload>();
  const { data, isLoading } = useGetFileBlob(selectedFile?.id);

  const onFileSelect = useCallback((file: FileDownload) => {
    setSelectedFile({ ...file });
  }, []);

  useEffect(() => {
    if (!data || !selectedFile) {
      return;
    }

    FileUtility.downloadBlob(data, selectedFile.name);
    setSelectedFile(undefined); // Reset selected file after download
  }, [data, selectedFile]);

  return {
    selectedFile,
    onFileSelect,
    isLoading,
  };
};

import { Button, ChevronLeft, CustomPageContainer } from '@/components';
import { BreadcrumbItem, Breadcrumbs } from '@/components/Breadcrumbs';
import { HttpError } from '@/core/http';
import { RoutePath } from '@/core/router/route-paths';
import { HttpStatusCode } from 'axios';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useDisableAutomation,
  useEnableAutomation,
  useGetAutomation,
} from '../../queries';
import { AutomationPerformance } from './components/AutomationPerformance';
import { FilesRow } from './components/FilesRow';
import { HeaderRow } from './components/HeaderRow';
import { SchedulingRow } from './components/SchedulingRow';

export const AutomationDetailPage = (): ReactElement => {
  const { automationId: automationIdParam } = useParams<{
    automationId: string;
  }>();
  const automationId = +automationIdParam!;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [status, setStatus] = useState<boolean>(false);

  const { mutate: enableAutomation } = useEnableAutomation();
  const { mutate: disableAutomation } = useDisableAutomation();

  const {
    data: automation,
    isLoading: isLoadingAutomation,
    isError,
    error,
  } = useGetAutomation(automationId);

  useEffect(() => {
    setStatus(automation?.status === true);
    if (
      isError &&
      error &&
      (error as HttpError<unknown>)?.code === HttpStatusCode.NotFound
    ) {
      navigate(RoutePath.notFound());
    }
  }, [isError, error, navigate, automation]);

  const handleStatus = useCallback(
    (checked: boolean) => {
      if (!automation?.id) {
        return toast.error(t('generic_errors'));
      }
      setStatus(checked);
      if (checked) {
        enableAutomation(automation?.id, {
          onError: () => {
            toast.error(t('error_enable_automation'));
          },
          onSuccess: () => {
            toast.success(t('success_enable_automation'));
          },
        });
      } else {
        disableAutomation(automation?.id, {
          onError: () => {
            toast.error(t('error_disable_automation'));
          },
          onSuccess: () => {
            toast.success(t('success_disable_automation'));
          },
        });
      }
    },
    [automation?.id, t, enableAutomation, disableAutomation],
  );

  const routeChange = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const breadcrumbs = useMemo<BreadcrumbItem[]>(() => {
    if (!automation) {
      return [];
    }

    return [
      {
        label: automation.organization,
        href: RoutePath.organizationDetail(
          automation.organizationId.toString(),
        ),
      },
      {
        label: automation.company,
        href: RoutePath.companyDetail(automation.companyId.toString()),
      },
      {
        label: automation.businessAreaName,
        href: RoutePath.businessAreaDetail(
          automation.businessAreaId.toString(),
        ),
      },
      {
        label: t('automations'),
        href: RoutePath.automations(),
      },
      {
        label: automation.name,
      },
    ];
  }, [automation, t]);

  return (
    <CustomPageContainer>
      <div className="flex flex-col gap-3">
        <Button
          className="h-6 w-14 justify-between border-blueNuit p-2 font-black text-blueNuit"
          variant="outline"
          onClick={routeChange}
        >
          <ChevronLeft />
          {t('back')}
        </Button>
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <span className="flex flex-col gap-5">
        <HeaderRow
          automation={automation}
          isLoading={isLoadingAutomation}
          handleStatus={handleStatus}
          status={status}
        />
        <SchedulingRow
          scheduling={automation?.scheduling}
          averageCases={automation?.averageCases}
          isLoading={isLoadingAutomation}
        />
        <FilesRow automation={automation} isLoading={isLoadingAutomation} />
        <AutomationPerformance
          company={automation?.company}
          businessArea={automation?.businessAreaName}
          organization={automation?.organization}
          processId={automation?.processId}
          automationId={automation?.id}
          tasksCount={automation?.tasks?.length}
          infrastructureCostsCount={automation?.infrastructureCostsCount}
        />
      </span>
    </CustomPageContainer>
  );
};

import { PaginationParams } from '@/shared/types';

export abstract class CompanyKeys {
  static ALL = ['companies'];
  static PAGED = (pagination: PaginationParams) => [
    ...CompanyKeys.ALL,
    pagination,
  ];
  static BY_ID = (companyId: number) => [
    ...CompanyKeys.ALL,
    'by-id',
    companyId,
  ];
  static BY_ORGANIZATION = (organizationId?: number) => [
    ...CompanyKeys.ALL,
    'by-organization',
    organizationId,
  ];
  static BY_ORGANIZATION_PAGED = (
    organizationId: number,
    pagination: PaginationParams,
  ) => [...CompanyKeys.BY_ORGANIZATION(organizationId), pagination];
  static BY_USER = (userId: number) => [...CompanyKeys.ALL, 'by-user', userId];
  static BY_USER_PAGED = (userId: number, pagination: PaginationParams) => [
    ...CompanyKeys.BY_USER(userId),
    pagination,
  ];
  static BY_INFRASTRUCTURE_COST = (infrastructureCostId: number) => [
    ...CompanyKeys.ALL,
    'by-infrastructure-cost',
    infrastructureCostId,
  ];
  static BY_INFRASTRUCTURE_COST_PAGED = (
    infrastructureCostId: number,
    pagination: PaginationParams,
  ) => [
    ...CompanyKeys.BY_INFRASTRUCTURE_COST(infrastructureCostId),
    pagination,
  ];

  static ELIGIBLE_BY_INFRASTRUCTURE_COST = (infrastructureCostId?: number) => [
    ...CompanyKeys.ALL,
    'eligible-by-infrastructure-cost',
    infrastructureCostId,
  ];
}

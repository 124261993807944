import { ReactElement, useCallback, useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

export interface ModalProps {
  isOpen: boolean;
  className: string;
  children: ReactElement;
  title: string;
  handleClose: () => void;
  allowClickOutsideToClose?: boolean;
}

export const Modal = ({
  isOpen,
  children,
  title,
  className = '',
  handleClose,
  allowClickOutsideToClose = true,
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutSide = useCallback(
    (e: MouseEvent) => {
      if (
        allowClickOutsideToClose &&
        !modalRef?.current?.contains(e.target as Node) &&
        isOpen
      ) {
        handleClose();
      }
    },
    [allowClickOutsideToClose, isOpen, handleClose],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  }, [handleClickOutSide]);

  return (
    isOpen && (
      <>
        <div
          className={twMerge(
            'absolute bottom-0 left-0 right-0 top-0 z-10 h-full w-full bg-black opacity-60',
          )}
        />
        <div
          className={twMerge(
            'absolute bottom-0 left-20 right-0 top-0 z-20 m-auto h-fit w-fit bg-white p-5',
            className,
          )}
          ref={modalRef}
        >
          <div>
            <div className="text-xl font-bold">{title}</div>
          </div>
          {children}
        </div>
      </>
    )
  );
};

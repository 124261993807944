import { organizationsRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { OrganizationModel } from '../domain';
import { OrganizationKeys } from './keys';

export const useGetOrganization = (orgId: number) =>
  useQuery<OrganizationModel>(
    OrganizationKeys.BY_ID(orgId),
    () => organizationsRepo.getOrganization(orgId),
    {
      enabled: !!orgId,
      cacheTime: 1000 * 60 * 5,
    },
  );

import { tasksRepo } from '@/constants/providers';
import { TaskModel } from '@/modules/Tasks/domain';
import { useQuery } from '@tanstack/react-query';
import { TaskKey } from './keys';

export const useGetAllTasksWithoutWorkflow = (businessAreaId: number) =>
  useQuery<TaskModel[]>(
    TaskKey.BY_BUSINESS_AREA_WITHOUT_WORKFLOW(businessAreaId),
    () => tasksRepo.getAllTasksWithoutWorkflow(businessAreaId),
    {
      enabled: !!businessAreaId,
    },
  );

import { filesRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FileQueryKey } from './keys';

export const useGetFileBlob = (id?: string) => {
  const { t } = useTranslation();
  const query = useQuery(
    [FileQueryKey.GET_FILE_BLOB, id],
    () => {
      if (!id) {
        return;
      }
      return filesRepo.getBloblById(id);
    },
    {
      enabled: !!id,
      refetchInterval: 0,
    },
  );

  useEffect(() => {
    if (!query.isError) {
      return;
    }

    toast.error(t('error_fetching_file'));
  }, [query.isError, t]);

  return query;
};

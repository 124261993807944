import { BusinessAreaModel } from '@/modules/BusinessAreas/domain';

export type BusinessAreaHierarchyRow = BusinessAreaModel & {
  rows: BusinessAreaHierarchyRow[];
};

export const getBusinessAreasChildRows = (
  businessAreas: BusinessAreaModel[],
): BusinessAreaHierarchyRow[] => {
  if (businessAreas.length === 0) {
    return [];
  }

  return businessAreas.map((businessArea) => ({
    ...businessArea,
    rows: getBusinessAreasChildRows(businessArea.businessAreas),
  }));
};

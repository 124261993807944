import { workflowsRepo } from '@/constants/providers';
import { WorkflowModel } from '@/modules/Workflows/domain';
import { useQuery } from '@tanstack/react-query';
import { WorkflowKey } from './keys';

export const useGetAllWorkflowsByBusinessArea = (
  businessAreaId: number,
  withProcess: boolean = true,
) =>
  useQuery<WorkflowModel[]>(
    WorkflowKey.BY_BUSINESS_AREA(businessAreaId, withProcess),
    () =>
      workflowsRepo.getWorkflowsByBusinessAreaId(businessAreaId, withProcess),
    {
      enabled: !!businessAreaId,
    },
  );

import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';
import { secondsToTimeDurationString } from '..';

type RunTimeColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const runTimeColumnConfig = <T extends TableRow>({
  options,
}: RunTimeColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'runTime',
  label: <Trans i18nKey="run_time" />,
  render: (value) => (
    <div className="overflow-hidden text-ellipsis">
      {secondsToTimeDurationString(value)}
    </div>
  ),
  acessorKey: 'runTime',
  sortable: false, //TODO: Implement sorting for this column on server-side.
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});

import { TableColumn, TableRow } from '@/components/Table/types';
import {
  automationsCountColumnConfig,
  codeColumnConfig,
  deleteRowColumnConfig,
  nameWithAvatarColumnConfig,
  processesCountColumnConfig,
  tasksCountColumnConfig,
  usersCountColumnConfig,
  workflowsColumnConfig,
} from '@/utils';
import { businessCompanyNameColumnConfig } from '@/utils/tableColumns/business-company-name-column-config';
import { businessOrganizationNameColumnConfig } from '@/utils/tableColumns/business-organization-name-column-config';
import { toggleStatusColumnConfig } from '@/utils/tableColumns/user-status-column-config';
import { BusinessAreaModel } from '../../domain';

type BusinessAreasColumnsConfig = {
  onClick?: (row?: TableRow) => void;
  disableDeleteMessage?: string;
  onDeleteClick?: (row?: TableRow) => void;
  onStatusChange: (checked: boolean, row: TableRow) => void;
};

export const getBusinessAreasColumns = ({
  onClick,
  disableDeleteMessage,
  onDeleteClick,
  onStatusChange,
}: BusinessAreasColumnsConfig): Array<TableColumn<BusinessAreaModel>> => {
  return [
    nameWithAvatarColumnConfig({ onClick }),
    codeColumnConfig(),
    toggleStatusColumnConfig({
      onChange: onStatusChange,
    }),
    businessOrganizationNameColumnConfig(),
    businessCompanyNameColumnConfig(),
    usersCountColumnConfig(),
    processesCountColumnConfig(),
    workflowsColumnConfig(),
    tasksCountColumnConfig(),
    automationsCountColumnConfig({
      options: {
        className: 'w-25',
      },
    }),
    deleteRowColumnConfig({
      disabledMessage: disableDeleteMessage,
      onDeleteClick: onDeleteClick,
    }),
  ];
};
export const getBusinessAreaColumnsNamedMapped = (columnName: string) => {
  const organizationsColumnsNamedMapped: Record<string, string> = {
    id: 'Id',
    name: 'Name',
    code: 'Code',
    status: 'Status',
    organizationName: 'Organization',
    CompanyName: 'CompanyName',
    company: 'Company',
    businessAreasCount: 'BusinessAreasCount',
    users: 'UsersCount',
    processesCount: 'ProcessesCount',
    tasksCount: 'TasksCount',
    tasks: 'TasksCount',
    workflowsCount: 'WorkflowsCount',
    automationsCount: 'AutomationsCount',
    automations: 'AutomationsCount',
  };

  return organizationsColumnsNamedMapped[columnName] ?? 'Name';
};

import { Card, Container } from '@/components';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { getDateDifference } from '@/utils';
import { ROI } from '@/core/domain/global-performance-model';

interface ROIDateCardProps {
  ROI?: ROI;
  initialInvestment?: number;
  totalInvestment?: number;
}

export const ROIDateCard = ({
  ROI,
  totalInvestment,
}: ROIDateCardProps): ReactElement => {
  const { t } = useTranslation();

  const { displayCurrentDate: displayInitialROIDate } = getDateDifference(
    ROI?.initialRoiDate,
  );

  const { displayCurrentDate: displayCurrentROIDate } = getDateDifference(
    ROI?.currentRoiDate,
  );

  return (
    <Container title={t('return_on_investment')}>
      <div className="flex flex-row gap-2">
        <Card
          title={t('initial_roi_date')}
          subtitle={`(${totalInvestment}€ ${t('initial_investment')})`}
        >
          <h1>{displayInitialROIDate}</h1>
        </Card>
        <Card
          title={t('current_roi_date')}
          subtitle={`(${totalInvestment}€ ${t('total_investment')})`}
        >
          <h1>{displayCurrentROIDate}</h1>
        </Card>
      </div>
    </Container>
  );
};

import { UnLink } from '@/components';
import { Spinner } from '@/components/Spinner';
import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type ActionsDetailsRowColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
  onUnlinkClick: (row: T) => void;
  isLoadingUserUpdate?: boolean;
};

export const actionsAdminUsersRowColumnConfig = <T extends TableRow>({
  options,
  onUnlinkClick,
  isLoadingUserUpdate = false,
}: ActionsDetailsRowColumnConfig<T>): TableColumn<T> => ({
  id: 'actions',
  label: <Trans i18nKey="actions" />,
  sortable: false,
  render: (_, row) =>
    isLoadingUserUpdate ? (
      <Spinner className="h-4 w-4" />
    ) : (
      <UnLink
        className="cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onUnlinkClick(row);
        }}
      />
    ),
  ...options,
});

import { ReactElement, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { Card } from '../Card';
import { CardSkeleton } from '../CardSkeleton';
import { Tag } from '../Tag';
import { performanceCardColors, PerformanceType } from './utils';

export interface PerformanceCardProps {
  title: string;
  subtitle?: string;
  children?: ReactNode;
  className?: string;
  value?: string;
  isLoading?: boolean;
  performance?: PerformanceType;
}

export const PerformanceCard = ({
  title,
  subtitle,
  children,
  className,
  value,
  isLoading,
  performance = PerformanceType.Neutral,
}: PerformanceCardProps): ReactElement => {
  return !isLoading ? (
    <Card
      title={title}
      subtitle={subtitle}
      className={className}
      footer={
        <Tag className={twMerge(performanceCardColors[performance], 'w-full')}>
          <span>{value}</span>
        </Tag>
      }
    >
      <h1>{children}</h1>
    </Card>
  ) : (
    <CardSkeleton />
  );
};

import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { CompanyKeys, useDisableCompany, useEnableCompany } from '../queries';

export const useToggleCompanyStatus = () => {
  const { mutate: enableCompany } = useEnableCompany();
  const { mutate: disableCompany } = useDisableCompany();

  const queryClient = useQueryClient();

  const onChange = useCallback(
    (checked: boolean, organizationId: number) => {
      const onSuccess = () => {
        queryClient.invalidateQueries(CompanyKeys.ALL);
      };

      if (checked) {
        enableCompany(organizationId, {
          onSuccess,
        });
      } else {
        disableCompany(organizationId, {
          onSuccess,
        });
      }
    },
    [disableCompany, enableCompany, queryClient],
  );

  return { onChange };
};

import { CrossDelete } from '@/components';
import { twMerge } from 'tailwind-merge';

export const List = ({
  items,
  deleteUser,
  className,
}: {
  items: Array<{ id: number; value: string; role?: number }>;
  deleteUser: (index: number) => void;
  className?: string;
}) => {
  return (
    <div
      className={twMerge(
        'divide-y',
        items.length > 0 && 'border-b-1 border-t-1',
        className,
      )}
    >
      {items.map((item, index) => (
        <div
          key={index}
          className="flex h-auto flex-row items-center justify-between p-2"
        >
          {item.value as string}
          <div className="flex justify-end">
            <div
              className="mx-5 flex h-auto cursor-pointer items-center justify-center"
              onClick={() => deleteUser(index)}
            >
              <CrossDelete />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

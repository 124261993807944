import { BusinessAreaJson, CreateBusinessAreasJson } from '../types';
import { BusinessAreaModel } from '../domain';
import { ApiResponse, DataWithPagination } from '@/shared/types';

export abstract class BusinessAreaRepository {
  abstract createBusinessArea(
    createBusinessAreasJson: CreateBusinessAreasJson,
  ): Promise<BusinessAreaModel>;
  abstract getAllBusinessAreas(
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<BusinessAreaModel[]>>;
  abstract getBusinessArea(businessAreaId: number): Promise<BusinessAreaModel>;
  abstract enableBusinessArea(id: number): Promise<ApiResponse<unknown>>;
  abstract disableBusinessArea(id: number): Promise<ApiResponse<unknown>>;
  abstract updateBusinessArea(
    data: BusinessAreaJson,
  ): Promise<BusinessAreaModel>;
  abstract getBusinessAreasByCompany(
    companyId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<BusinessAreaModel[]>>;
  abstract getAllBusinessAreasByCompany(
    companyId: number,
  ): Promise<BusinessAreaModel[]>;
  abstract getBusinessAreasByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<BusinessAreaModel[]>>;
  abstract getBusinessAreasByUser(
    userId: number,
    pageNumber?: number,
    filter?: string,
  ): Promise<DataWithPagination<BusinessAreaModel[]>>;
  abstract deleteBusinessArea(Id: number): Promise<ApiResponse<unknown>>;
}

import { SwitchStatus, UserAvatar } from '@/components';
import { Button } from '@/components/Button';
import { CardSkeleton } from '@/components/CardSkeleton';
import { Container } from '@/components/Container';
import { UserModel } from '@/modules/Users/domain';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditUserModal } from '../EditUserModal';

interface HeaderRowProps {
  user?: UserModel;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string,
  ) => void;
  handleAccess: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string,
  ) => void;
  status: boolean;
}

export const HeaderRow = ({
  user,
  handleStatus,
  handleAccess,
}: HeaderRowProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  if (!user)
    return (
      <Container>
        <CardSkeleton />
      </Container>
    );

  return (
    <Container>
      <div className="flex justify-between">
        <div className="flex items-center gap-5">
          <UserAvatar name={user?.name} imageId={user?.profileImageFileId} />
          <div>
            <h2>{user?.name}</h2>
            <h4>{user?.email}</h4>
          </div>
        </div>
        <div className="flex h-10 flex-row gap-3">
          <div className="flex flex-row items-center gap-3 rounded-md bg-white pb-0 pl-3 pr-3 pt-0 text-sm leading-xs">
            <span className="pr-10 font-black capitalize">{t('access')}</span>
            <span className="p-0 text-americanGreen">{user?.access}</span>
            <SwitchStatus onChange={handleAccess} checked={!!user?.access} />
          </div>
          <div className="flex flex-row items-center gap-3 rounded-md bg-white pb-0 pl-3 pr-3 pt-0 text-sm leading-xs">
            <span className="pr-10 font-black capitalize">{t('status')}</span>
            <span className="p-0 text-americanGreen">{user?.status}</span>
            <SwitchStatus onChange={handleStatus} checked={!!user?.status} />
          </div>
          <EditUserModal
            isOpen={isOpen}
            handleClose={handleModalState}
            disableFields={['email']}
            user={user}
          />
          <Button className="h-9 p-5" onClick={handleModalState}>
            {t('edit_user')}
          </Button>
        </div>
      </div>
    </Container>
  );
};

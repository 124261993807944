import { Spinner } from '@/components/Spinner';
import { RoutePath } from '@/core/router';
import { useKeycloak } from '@react-keycloak/web';
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const LoadingPage = (): ReactElement => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  useEffect(() => {
    if (keycloak.authenticated) {
      navigate(RoutePath.tenantPicker());
    }
  }, [keycloak.authenticated, navigate]);

  return (
    <div className="flex h-screen items-center justify-center">
      <Spinner />
    </div>
  );
};

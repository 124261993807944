import { businessAreasRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { BusinessAreasKey } from './keys';

type ParentableBusinessAreaParams = {
  companyId?: number;
  businessAreaId?: number;
};

export const useEligibleParentBusinessAreasQuery = (
  params: ParentableBusinessAreaParams,
) => {
  return useQuery({
    queryKey: BusinessAreasKey.ELIGIBLE_PARENT_BUSINESS_AREAS(
      params.companyId,
      params.businessAreaId,
    ),
    queryFn: () => {
      if (!params.companyId) {
        return [];
      }
      return businessAreasRepo.getParentableBusinessAreas(
        params.companyId,
        params.businessAreaId,
      );
    },
    enabled: !!params.companyId,
  });
};

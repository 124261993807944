import { ProtectedHttpClient } from '@/core/http';
import { FileRepository } from './file.repository';

export class HttpFileRepository implements FileRepository {
  constructor(private readonly httpClient: ProtectedHttpClient) {}

  async getBloblById(id: string): Promise<Blob> {
    return await this.httpClient.get<Blob>({
      url: `/files/${id}`,
      config: {
        responseType: 'blob',
      },
    });
  }
}

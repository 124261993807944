import { PerformanceCardsContainer } from '@/components/PerformanceCardsContainer';
import { AutomationPerformanceModel } from '@/modules/Automations/domain';
import { calculateValueDifference } from '@/utils';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface AutomationPerformanceCardsProps {
  isLoading: boolean;
  automationPerformance?: AutomationPerformanceModel;
}

export const AutomationPerformanceCards = ({
  isLoading,
  automationPerformance,
}: AutomationPerformanceCardsProps): ReactElement => {
  const { t } = useTranslation();
  const investmentReturnDifference = calculateValueDifference(
    automationPerformance?.totalReturn || 0,
    automationPerformance?.totalInvestment || 0,
    'coin',
  );

  return (
    <PerformanceCardsContainer
      predictedManualTime={automationPerformance?.predictedManualTime || 0}
      predictedAverageManualTime={
        automationPerformance?.predictedAverageManualTime || 0
      }
      automationRunTime={automationPerformance?.automationRunTime || 0}
      averageAutomationRunTime={
        automationPerformance?.averageAutomationRunTime || 0
      }
      totalInvestment={automationPerformance?.totalInvestment || 0}
      totalReturn={automationPerformance?.totalReturn || 0}
      averageCost={automationPerformance?.averageCost || 0}
      averageManualCost={automationPerformance?.averageManualCost || 0}
      isLoading={isLoading}
      subtitleInvestmentLabel={t('total_investment')}
      investmentReturnDifference={investmentReturnDifference}
    />
  );
};

import { ArrowUpIcon, Button, Dropdown, SwitchStatus } from '@/components';
import { Card } from '@/components/Card';
import { Container } from '@/components/Container';
import { Tag } from '@/components/Tag';
import { AutomationModel } from '@/modules/Automations/domain';
import { useUpdateStage } from '@/modules/Automations/queries';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { EditAutomationModal } from '../../../AutomationsPage/components/EditAutomationModal';

interface HeaderRowProps {
  automation?: AutomationModel;
  isLoading?: boolean;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent>,
    id: string,
  ) => void;
  status: boolean;
}

export const HeaderRow = ({
  automation,
  isLoading,
  handleStatus,
  status,
}: HeaderRowProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);
  const [selectedStage, setSelectedStage] = useState(automation?.stageId || 0);
  const { mutate } = useUpdateStage();

  useEffect(() => {
    if (automation?.stageId) {
      setSelectedStage(automation?.stageId);
    }
  }, [automation?.stageId]);

  const stages = [
    { id: 1, name: 'PddSubmission' },
    { id: 2, name: 'PddApproval' },
    { id: 3, name: 'Development' },
    { id: 4, name: 'TestApproval' },
    { id: 5, name: 'Rollout' },
    { id: 6, name: 'Productive' },
    { id: 7, name: 'Active' },
    { id: 8, name: 'Maintenance' },
    { id: 9, name: 'Inactive' },
  ];

  const dropdownStagesOptions = getDropdownArray(stages);

  const handleChange = async (value: number) => {
    setSelectedStage(value);

    const convertedData = {
      id: automation?.id ?? 0,
      stage: value,
    };
    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_update_stage'));
      },
      onSuccess: () => {
        toast.success(t('success_update_stage'));
      },
    });
  };

  return (
    <Container isLoading={!automation}>
      <div className="flex justify-between">
        <div>
          <h2>{automation?.name}</h2>
          <h6>
            {automation?.organization && (
              <span className="font-bold">
                {automation?.organization}, {automation?.company},
              </span>
            )}
            <span className="font-normal"> {automation?.businessAreaName}</span>
          </h6>
        </div>
        <div className="flex h-10 flex-row gap-3">
          <div className="flex flex-row items-center gap-3 rounded-md bg-white pb-0 pl-3 pr-3 pt-0 text-sm leading-xs">
            <span className="pr-10 font-black">Status</span>
            <span
              className={`p-0 ${status ? 'text-americanGreen' : 'brightGray'}`}
            >
              {status ? 'Active' : 'Inactive'}
            </span>
            <SwitchStatus onChange={handleStatus} checked={status} />
          </div>
          <div className="flex h-10 flex-row items-center gap-3 rounded-md bg-white pb-0 pl-3 pr-3 pt-0 text-sm leading-xs">
            <span className="font-black">Stage</span>
            <Dropdown
              className="w-25"
              placeholder={t('stage')}
              options={dropdownStagesOptions}
              onSelect={(val) => handleChange(val.value)}
              value={selectedStage}
            />
            <ArrowUpIcon className="rotate-180	" />
          </div>
          <Button className="h-9 p-5" onClick={handleModalState}>
            {t('edit_automation')}
          </Button>
          {automation?.id && (
            <EditAutomationModal
              isOpen={isOpen}
              id={automation.id}
              handleClose={handleModalState}
            />
          )}
        </div>
      </div>
      <Card title={t('description')} isLoading={isLoading}>
        <p>{automation?.description}</p>
      </Card>
      <div className="flex gap-2.5">
        <Card title={t('stage')} isLoading={isLoading}>
          <Tag className="bg-blue-500 text-white">
            {t(`${automation?.stage}`)}
          </Tag>
        </Card>
        <Card title={t('version')} isLoading={isLoading}>
          {automation?.version}
        </Card>
        <Card title={t('code')} isLoading={isLoading}>
          {automation?.code}
        </Card>
        <Card title={t('developer')} isLoading={isLoading}>
          {automation?.developer}
        </Card>
      </div>
    </Container>
  );
};

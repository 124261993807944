import {
  companiesRepo,
  organizationsRepo,
  usersRepo,
} from '@/constants/providers';
import { DeleteProfileImageJson } from '@/modules/types';
import { useMutation } from '@tanstack/react-query';

export const useUploadProfileUserPicture = () =>
  useMutation<unknown, unknown, FormData>(usersRepo.uploadProfilePicture);

export const useDeleteProfileUserPicture = () =>
  useMutation<unknown, unknown, DeleteProfileImageJson>(
    usersRepo.deleteProfilePicture,
  );

export const useUploadProfileCompanyPicture = () =>
  useMutation<unknown, unknown, FormData>(companiesRepo.uploadProfilePicture);

export const useDeleteProfileCompanyPicture = () =>
  useMutation<unknown, unknown, DeleteProfileImageJson>(
    companiesRepo.deleteProfilePicture,
  );

export const useUploadProfileOrganizationPicture = () =>
  useMutation<unknown, unknown, FormData>(
    organizationsRepo.uploadProfilePicture,
  );

export const useDeleteProfileOrganizationPicture = () =>
  useMutation<unknown, unknown, DeleteProfileImageJson>(
    organizationsRepo.deleteProfilePicture,
  );

import { CompanyKeys } from '@/modules/Companies/queries';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { BusinessAreasKey } from '../queries/keys';

export const useInvalidateBusinessAreaQueries = () => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries(BusinessAreasKey.ALL);
    queryClient.invalidateQueries(CompanyKeys.ALL);
    queryClient.invalidateQueries(OrganizationKeys.ALL);
  }, [queryClient]);
};

import { TableColumn, TableRow } from '@/components/Table/types';
import {
  automatedColumnConfig,
  businessAreaNameColumnConfig,
  codeColumnConfig,
  deleteRowColumnConfig,
  estimatedCostColumnConfig,
  nameWithAvatarColumnConfig,
  periodColumnConfig,
  usersCountColumnConfig,
  workloadColumnConfig,
} from '@/utils';
import { dividerColumnConfig } from '@/utils/tableColumns/divider-column-config';
import { processCompanyNameColumnConfig } from '@/utils/tableColumns/process-company-name-column-config';
import { processOrganizationNameColumnConfig } from '@/utils/tableColumns/process-organization-name-column-config';
import { taskWorkflowNameColumnConfig } from '@/utils/tableColumns/task-workflow-name-column-config';
import { toggleStatusColumnConfig } from '@/utils/tableColumns/user-status-column-config';
import { TaskModel } from '../../domain';

type TasksColumnsConfig = {
  onClick?: (row?: TableRow) => void;
  disableDeleteMessage?: string;
  onDeleteClick?: (row?: TableRow) => void;
  onStatusChange: (checked: boolean, row: TableRow) => void;
};

export const getTasksColumns = ({
  onClick,
  disableDeleteMessage,
  onDeleteClick,
  onStatusChange,
}: TasksColumnsConfig): Array<TableColumn<TaskModel>> => {
  return [
    nameWithAvatarColumnConfig({ onClick }),
    codeColumnConfig(),
    processOrganizationNameColumnConfig(),
    processCompanyNameColumnConfig(),
    businessAreaNameColumnConfig(),
    taskWorkflowNameColumnConfig(),
    toggleStatusColumnConfig({
      onChange: onStatusChange,
    }),
    usersCountColumnConfig(),
    dividerColumnConfig({ key: 'divider-1' }),
    periodColumnConfig(),
    workloadColumnConfig(),
    estimatedCostColumnConfig(),
    dividerColumnConfig({ key: 'divider-2' }),
    automatedColumnConfig(),
    deleteRowColumnConfig({
      disabledMessage: disableDeleteMessage,
      onDeleteClick: onDeleteClick,
    }),
  ];
};
export const getTasksColumnsNamedMapped = (columnName: string) => {
  const organizationsColumnsNamedMapped: Record<string, string> = {
    id: 'Id',
    name: 'Name',
    code: 'Code',
    businessAreaName: 'BusinessAreaName',
    status: 'Status',
    processesCount: 'UsersCount',
    period: 'Period',
    workload: 'Workload',
    estimatedCost: 'EstimatedCost',
    automated: 'Automated',
    users: 'UsersCount',
    organizationName: 'Organization',
    company: 'Company',
    workflow: 'Workflow',
  };

  return organizationsColumnsNamedMapped[columnName] ?? 'Name';
};

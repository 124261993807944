import { TENANT_ID_SELECTED } from '@/components/TenantProvider';
import keycloak from '@/keycloak';
import { RoutePath } from '../router';
import { HttpClient } from './http-client';

export class ProtectedHttpClient extends HttpClient {
  constructor(resource: string) {
    super(resource);

    this.setOnRequest((config, headers) => {
      const token = keycloak.token;

      return {
        ...config,
        headers: {
          ...(headers ?? {}),
          Authorization: `Bearer ${token}`,
          'Tenant-Id': sessionStorage.getItem(TENANT_ID_SELECTED),
        },
      };
    });

    this.setOnResponse({
      error(error) {
        if (error.status === 404) {
          window.location.href = RoutePath.notFound();
          return;
        }
        return error;
      },
    });
  }
}

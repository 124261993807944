import {
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  MouseEvent,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { useOutsideClick } from '@/hooks';
import { Button, DropdownInput } from '..';

export interface SelectDropdownOption<T> {
  label: string;
  value: T;
}

interface DropdownProps<T> {
  className?: string;
  placeholder?: string;
  buttonRef?: RefObject<HTMLButtonElement>;
  options: SelectDropdownOption<T>[];
  value?: T;
  onSelect?: (option: SelectDropdownOption<T>) => void;
  disabled?: boolean;
}

export function Dropdown<T>({
  className,
  options,
  placeholder,
  buttonRef,
  value,
  onSelect,
  disabled,
}: DropdownProps<T>): ReactElement {
  const buttonWrapperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [internalValue, setInternalValue] = useState<T | undefined>(value);

  const handleOutsideClick = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useOutsideClick(buttonWrapperRef, handleOutsideClick, isOpen);

  const selectedOptionLabel = useMemo(() => {
    return options?.find((option) => option.value === internalValue)?.label;
  }, [internalValue, options]);

  const handleOptionClick = useCallback(
    (option: SelectDropdownOption<T>) => (event: MouseEvent) => {
      if (!onSelect) {
        setInternalValue(option.value);
      }
      onSelect?.(option);
      setIsOpen(false);

      buttonRef?.current?.focus();

      event.preventDefault();
      event.stopPropagation();
    },
    [buttonRef, onSelect],
  );

  const handleSelectDropdownButtonClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <div ref={buttonWrapperRef} className={twMerge('relative', className)}>
      <DropdownInput
        placeholder={placeholder}
        isOpen={isOpen}
        buttonRef={buttonRef}
        inputValue={selectedOptionLabel}
        onButtonClick={handleSelectDropdownButtonClick}
        disabled={disabled}
      >
        <ul className="max-h-48">
          {options.map((opt: SelectDropdownOption<T>) => {
            return (
              <li className="group flex" key={`${opt.value}`}>
                <Button
                  variant="text"
                  className="flex w-full flex-grow text-ellipsis whitespace-nowrap bg-white p-2 text-start text-primaryBlue outline-none hover:bg-spartanBlue hover:text-white focus:bg-spartanBlue focus:text-white group-last:rounded-b-md"
                  onClick={handleOptionClick(opt)}
                >
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                    {opt.label}
                  </span>
                </Button>
              </li>
            );
          })}
        </ul>
      </DropdownInput>
    </div>
  );
}

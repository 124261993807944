import {
  Button,
  Dropdown,
  FieldInfo,
  FilterBusinessAreaFormGroup,
  Input,
  Modal,
  TextArea,
} from '@/components';
import { useGetAllBusinessAreasByCompany } from '@/modules/BusinessAreas/queries';
import { useGetAllCompaniesByOrganization } from '@/modules/Companies/queries';
import { useGetAllOrganizations } from '@/modules/Organizations/queries';
import {
  ProcessSchema,
  ProcessSchemaType,
} from '@/modules/Processes/types/process-schema';
import { useGetUsersBaseInfo } from '@/modules/Users/queries';
import { WorkflowModel } from '@/modules/Workflows/domain';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReactElement, useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ProcessModalProps {
  handleOnSubmit: (e: ProcessSchemaType) => void;
  handleClose: () => void;
  title: string;
  type: 'create' | 'edit';
  isLoading: boolean;
  isOpen: boolean;
  organizationId?: number;
  companyId?: number;
  businessAreaId?: number;
  description?: string;
  processName?: string;
  disableFields?: string[];
  workflows?: WorkflowModel[];
  processOwnerId?: number;
}

export const ProcessModal = ({
  title,
  isOpen,
  type,
  isLoading,
  handleOnSubmit,
  handleClose,
  organizationId,
  companyId,
  businessAreaId,
  description,
  processName,
  disableFields,
  workflows,
  processOwnerId,
}: ProcessModalProps): ReactElement => {
  const { t } = useTranslation();
  const {
    clearErrors,
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
    control,
    watch,
  } = useForm<ProcessSchemaType>({
    defaultValues: {
      organizationId: organizationId,
      companyId: companyId,
      businessAreaId: businessAreaId,
      description: description || '',
      processName: processName,
    },
    resolver: zodResolver(ProcessSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
  });

  const { data: organizations } = useGetAllOrganizations();

  const organization = watch('organizationId');
  const company = watch('companyId');

  const { data: companies } = useGetAllCompaniesByOrganization(organization);
  const { data: businessAreas } = useGetAllBusinessAreasByCompany(company);

  const {
    dropdownOrganizationsOptions,
    dropdownCompaniesOptions,
    dropdownBusinessAreasOptions,
  } = useMemo(() => {
    return {
      dropdownOrganizationsOptions: getDropdownArray(
        organizations?.filter(
          (organization) =>
            organization.status || organization.id === organizationId,
        ) ?? [],
      ),
      dropdownCompaniesOptions: getDropdownArray(
        companies?.filter(
          (company) => company.status || company.id === companyId,
        ) ?? [],
      ),
      dropdownBusinessAreasOptions: getDropdownArray(businessAreas ?? []),
    };
  }, [organizations, companies, businessAreas, organizationId, companyId]);

  useEffect(() => {
    clearErrors();

    organizationId && setValue('organizationId', organizationId);
    companyId && setValue('companyId', companyId);
    businessAreaId && setValue('businessAreaId', businessAreaId);
    setValue('description', description || '');
    setValue('processName', processName || '');
    setValue(
      'workflows',
      workflows?.map((workflow) => String(workflow.id)) || [],
    );
    processOwnerId && setValue('processOwnerId', processOwnerId);
  }, [
    clearErrors,
    setValue,
    isOpen,
    organizationId,
    companyId,
    businessAreaId,
    description,
    processName,
    workflows,
    processOwnerId,
  ]);

  const onSubmit: SubmitHandler<ProcessSchemaType> = (data) => {
    trigger();
    if (!isValid) {
      return;
    }
    handleOnSubmit(data);
  };

  const { data: users } = useGetUsersBaseInfo();

  const dropdownUsersOptions = useMemo(
    () =>
      getDropdownArray(
        users?.filter((user) => user.status || user.id === processOwnerId) ??
          [],
      ),
    [processOwnerId, users],
  );

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      className="h-144 w-128"
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2 flex h-112 flex-col overflow-y-auto p-2">
          <FieldInfo
            title={t('process_name')}
            errorText={
              errors.processName?.message
                ? t(errors.processName.message)
                : undefined
            }
            hasError={!!errors.processName?.message}
          >
            <Input
              isDisabled={isLoading}
              className="w-full bg-brightGray italic"
              placeHolder={t('process_name')}
              name="processName"
              register={register}
            />
          </FieldInfo>
          <FilterBusinessAreaFormGroup<ProcessSchemaType>
            organizationOptions={dropdownOrganizationsOptions}
            companyOptions={dropdownCompaniesOptions}
            businessAreaOptions={dropdownBusinessAreasOptions}
            disableFields={disableFields}
            errors={errors}
            control={control}
          />
          <FieldInfo
            title={t('process_owner')}
            errorText={
              errors.processOwnerId?.message
                ? t(errors.processOwnerId.message)
                : undefined
            }
            hasError={!!errors.processOwnerId?.message}
          >
            <Controller
              name="processOwnerId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ...otherFieldProps } }) => (
                <Dropdown
                  {...otherFieldProps.onBlur}
                  className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                  placeholder={t('process_owner')}
                  options={dropdownUsersOptions}
                  onSelect={(val) => onChange(val.value)}
                  value={value}
                />
              )}
            />
          </FieldInfo>
          <FieldInfo
            title={t('description')}
            errorText={
              errors.description?.message
                ? t(errors.description.message)
                : undefined
            }
            hasError={!!errors.description?.message}
          >
            <TextArea
              isDisabled={isLoading}
              className="h-24 w-full bg-brightGray"
              placeHolder={t('description')}
              name="description"
              register={register}
            />
          </FieldInfo>
        </div>
        <div className="mt-4 flex justify-between">
          <Button
            isDisabled={isLoading}
            onClick={handleClose}
            variant="outline"
            className="h-9 w-24"
          >
            {t('cancel')}
          </Button>
          <Button
            isDisabled={isLoading}
            buttonType="submit"
            className="h-9 w-24"
          >
            {type === 'create' ? t('create') : t('confirm')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

import { Modal } from '@/components';
import { TenantModel } from '@/modules/Tenants/domain';
import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../Spinner';
import { TenantEntry } from '../TenantEntry';

interface ChangeTenantModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSave: (tenantId: number) => void;
  isLoading: boolean;
  selectedTenantId: number | undefined;
  tenants: TenantModel[];
}

export const ChangeTenantModal = ({
  isOpen,
  handleClose,
  handleSave,
  isLoading,
  selectedTenantId,
  tenants,
}: ChangeTenantModalProps): ReactElement => {
  const { t } = useTranslation();

  const handleTenantClick = useCallback(
    (tenantId: number) => {
      handleSave(tenantId);
      handleClose();
    },
    [handleSave, handleClose],
  );

  return (
    <Modal
      isOpen={isOpen}
      title={t(selectedTenantId ? 'change_tenant' : 'select_tenant')}
      className="h-fit w-128"
      handleClose={handleClose}
      allowClickOutsideToClose={false}
    >
      {isLoading ? (
        <Spinner className="h-11 w-11" />
      ) : (
        <div className="flex flex-col gap-2 pt-5">
          {tenants.map((tenant) => (
            <TenantEntry
              className="w-full cursor-pointer"
              key={tenant.id}
              tenant={tenant}
              isSelected={tenant?.id === selectedTenantId}
              onClick={() => handleTenantClick(tenant.id)}
            />
          ))}
        </div>
      )}
    </Modal>
  );
};

import { SortDirection } from '@/components/Table/types';
import { usersRepo } from '@/constants/providers';
import { UserModel } from '@/modules/Users/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { UsersKeys } from './types';

export const useGetUsersByTask = (
  taskId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<UserModel[]>,
    any,
    DataWithPagination<UserModel[]>
  >,
) =>
  useQuery<DataWithPagination<UserModel[]>>(
    UsersKeys.BY_TASK_PAGED(taskId, {
      pageNumber,
      filter,
      sortBy,
      sortOrder,
    }),
    () =>
      usersRepo.getUsersByTask(taskId, pageNumber, filter, sortBy, sortOrder),
    options,
  );

import { UserAvatar } from '@/components';
import { TenantModel } from '@/modules/Tenants/domain';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

interface TenantEntryProps {
  tenant: TenantModel;
  className?: string;
  isSelected?: boolean;
  onClick?: (tenant: TenantModel) => void;
}

export const TenantEntry = ({
  tenant,
  isSelected,
  className,
  onClick,
}: TenantEntryProps): ReactElement => {
  const { t } = useTranslation();

  const clickStyleInfo = useMemo(() => {
    const style = 'border border-transparent';

    if (!isSelected && !onClick) {
      return style;
    }

    if (isSelected) {
      return 'bg-fennelBlue bg-opacity-25 border-fennelBlue border';
    }

    if (onClick) {
      return twMerge(
        'hover:bg-fennelBlue hover:bg-opacity-25 hover:border-blueAngel hover:border cursor-pointer',
        style,
      );
    }
  }, [isSelected, onClick]);

  return (
    <div
      className={twMerge(
        'flex h-14 items-center justify-between gap-5 rounded-md bg-white px-2.5 py-1',
        clickStyleInfo,
        className,
      )}
      onClick={() => onClick && onClick(tenant)}
    >
      <div
        className={twMerge(
          `grid items-center gap-5`,
          isSelected || onClick ? 'grid-cols-4' : 'grid-cols-3',
          className,
        )}
      >
        <div className="col-span-2 flex items-center gap-2.5">
          <UserAvatar name={tenant.name} imageId={tenant.imageFileId} />
          <p className="text-sm font-black">{tenant.name}</p>
        </div>
        <p className="text-sm font-normal">{tenant.userRole}</p>
        {isSelected && (
          <p className={`text-right text-sm italic text-blueNuit`}>
            {t('tenant_selected')}
          </p>
        )}
      </div>
    </div>
  );
};

import { Button, SwitchStatus } from '@/components';
import { Card } from '@/components/Card';
import { Container } from '@/components/Container';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditInfrastructureCostModal } from '../../../InfrastructureCostPage/components/EditInfrastructureCostModal';
import { InfrastructureCostModel } from '@/modules/Infrastructure/domain';

interface HeaderRowProps {
  infrastructureCost?: InfrastructureCostModel;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent>,
    id: string,
  ) => void;
  status: boolean;
  isLoading: boolean;
}

export const HeaderRow = ({
  infrastructureCost,
  handleStatus,
  status,
}: HeaderRowProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return (
    <Container isLoading={!infrastructureCost}>
      <div className="flex justify-between">
        <span>
          <h2>{infrastructureCost?.name}</h2>
          <h6>
            <span className="font-medium">{infrastructureCost?.costType}</span>
          </h6>
        </span>
        <div className="flex h-10 flex-row gap-3">
          <div className="flex flex-row items-center gap-3 rounded-md bg-white pb-0 pl-3 pr-3 pt-0 text-sm leading-xs">
            <span className="pr-10 font-black">Status</span>
            <span
              className={`p-0 ${status ? 'text-americanGreen' : 'brightGray'}`}
            >
              {status ? 'Active' : 'Inactive'}
            </span>
            <SwitchStatus onChange={handleStatus} checked={status} />
          </div>
          <Button className="h-9 p-5" onClick={handleModalState}>
            {t('edit_cost')}
          </Button>
          <EditInfrastructureCostModal
            isOpen={isOpen}
            id={infrastructureCost?.id}
            handleClose={handleModalState}
          />
        </div>
      </div>
      <div className="flex gap-2.5">
        <Card title={t('description')}>{infrastructureCost?.description}</Card>
      </div>
      <div className="flex flex-row gap-2.5">
        <Card title={t('recurrence')}>
          {t(infrastructureCost?.amountRecurrence ?? '')}
        </Card>
        <Card title={t('amount')}>
          {<p>{infrastructureCost?.amount.toFixed(2)} €</p>}
        </Card>
      </div>
    </Container>
  );
};

import { useQuery } from '@tanstack/react-query';
import { InfrastructureCostModel } from '../domain';
import { InfrastructureKeys } from './types';
import { infrastructuresRepo } from '@/constants/providers';

export const useGetInfrastructureCost = (id: string = '') =>
  useQuery<InfrastructureCostModel>(
    [InfrastructureKeys.GET, id],
    () => infrastructuresRepo.getInfrastructureCost(id),
    {
      enabled: !!id,
    },
  );

import { queryClient } from '@/constants';
import { RoutePath } from '@/core/router';
import { TenantModel } from '@/modules/Tenants/domain';
import { useGetTenant, useGetUserTenants } from '@/modules/Tenants/queries';
import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

export const TenantContext = createContext<{
  userTenants: TenantModel[];
  currentSelectedTenant?: TenantModel;
  isLoadingTenantInfo: boolean;
  onTenantIdChange: (tenantId: number) => void;
  hasMultipleTenants: boolean;
}>({
  userTenants: [],
  isLoadingTenantInfo: false,
  onTenantIdChange: () => {},
  hasMultipleTenants: false,
});

export const TENANT_ID_SELECTED = 'tenantIdSelected';
export const PREVIOUS_TENANT_ID = 'previousTenantId';

export const TenantProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  const { data: userTenants, isLoading: isLoadingUserTenants } =
    useGetUserTenants();

  const [selectedTenantId, setSelectedTenantId] = useState<number | undefined>(
    sessionStorage.getItem(TENANT_ID_SELECTED)
      ? Number(sessionStorage.getItem(TENANT_ID_SELECTED))
      : undefined,
  );

  const { data: currentSelectedTenant, isLoading: isLoadingCurrentTenant } =
    useGetTenant(selectedTenantId);

  const onTenantIdChange = useCallback(
    (tenantId: number) => {
      if (!tenantId) return;
      setSelectedTenantId(tenantId);
      sessionStorage.setItem(TENANT_ID_SELECTED, tenantId.toString());
      queryClient.invalidateQueries();

      const selectedTenant = userTenants?.find(({ id }) => id === tenantId);

      if (!selectedTenant) {
        return;
      }

      const firstOrganization = selectedTenant.organizations[0];

      navigate(
        !firstOrganization || selectedTenant.organizations.length > 1
          ? RoutePath.organizations()
          : RoutePath.organizationDetail(firstOrganization.toString()),
      );
    },
    [navigate, userTenants],
  );

  const isLoadingTenantInfo = useMemo(
    () => isLoadingCurrentTenant || isLoadingUserTenants,
    [isLoadingCurrentTenant, isLoadingUserTenants],
  );

  return (
    <TenantContext.Provider
      value={{
        onTenantIdChange,
        currentSelectedTenant,
        isLoadingTenantInfo,
        userTenants: userTenants ?? [],
        hasMultipleTenants: !!userTenants?.length && userTenants.length > 1,
      }}
    >
      {children}
    </TenantContext.Provider>
  );
};

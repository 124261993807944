import { Modal } from '@/components';
import { queryClient } from '@/constants/query-client';
import { UserModel } from '@/modules/Users/domain';
import { useUpdateUser } from '@/modules/Users/queries';
import { UsersKeys } from '@/modules/Users/queries/types';
import { UserWithoutRoleSchemaType } from '@/modules/Users/types';
import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UserModal } from '../../../UsersPage/components/UserModal';

interface CreateUserModalProps {
  isOpen: boolean;
  handleClose: () => void;
  disableFields?: string[];
  user?: UserModel;
}

export const EditUserModal = ({
  isOpen,
  handleClose,
  user,
  disableFields,
}: CreateUserModalProps): ReactElement => {
  const { t } = useTranslation();

  const { mutate, isLoading } = useUpdateUser();

  const handleConfirm = useCallback(
    (data: UserWithoutRoleSchemaType) => {
      const convertedData = {
        id: user?.id ?? 0,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        status: data.status,
        access: data.access,
      };

      mutate(convertedData, {
        onError: () => {
          toast.error(t('error_updating_user'));
        },
        onSuccess: () => {
          queryClient.invalidateQueries(UsersKeys.ALL);
          toast.success(t('success_updating_user'));
          handleClose();
        },
      });
    },
    [mutate, t, handleClose, user],
  );

  return (
    <Modal
      isOpen={isOpen}
      title={t('edit_user')}
      className="h-fit w-128"
      handleClose={handleClose}
    >
      <div className="mt-6 flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <UserModal
            handleOnSubmit={handleConfirm}
            handleClose={handleClose}
            isLoading={isLoading}
            user={user}
            disableFields={disableFields}
            type="edit"
          />
        </div>
      </div>
    </Modal>
  );
};

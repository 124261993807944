import { TableColumn, TableRow } from '@/components/Table/types';
import {
  averageCasesColumnConfig,
  businessAreaNameColumnConfig,
  deleteRowColumnConfig,
  idColumnConfig,
  runTimeColumnConfig,
  stageColumnConfig,
  statusColumnConfig,
  successColumnConfig,
} from '@/utils';
import { automationsNameColumnConfig } from '@/utils/tableColumns/automations-name-column-config';
import { dividerColumnConfig } from '@/utils/tableColumns/divider-column-config';
import { processCompanyNameColumnConfig } from '@/utils/tableColumns/process-company-name-column-config';
import { processOrganizationNameColumnConfig } from '@/utils/tableColumns/process-organization-name-column-config';
import { toggleStatusColumnConfig } from '@/utils/tableColumns/user-status-column-config';
import { AutomationModel } from '../../domain';

type AutomationsColumnsConfig = {
  onClick?: (row?: TableRow) => void;
  disableDeleteMessage?: string;
  onDeleteClick?: (row?: TableRow) => void;
  onStatusChange: (checked: boolean, row: TableRow) => void;
};

type AutomationsTabColumnsConfig = {
  onClick?: (row?: TableRow) => void;
  disableDeleteMessage?: string;
  onDeleteClick?: (row?: TableRow) => void;
};

export const getAutomationsColumns = ({
  onClick,
  disableDeleteMessage,
  onDeleteClick,
  onStatusChange,
}: AutomationsColumnsConfig): Array<TableColumn<AutomationModel>> => {
  return [
    automationsNameColumnConfig({ onClick }),
    processOrganizationNameColumnConfig(),
    processCompanyNameColumnConfig(),
    businessAreaNameColumnConfig(),
    stageColumnConfig(),
    toggleStatusColumnConfig({
      onChange: onStatusChange,
    }),
    idColumnConfig(),
    dividerColumnConfig(),
    runTimeColumnConfig(),
    averageCasesColumnConfig(),
    successColumnConfig(),
    deleteRowColumnConfig({
      disabledMessage: disableDeleteMessage,
      onDeleteClick: onDeleteClick,
    }),
  ];
};

export const getAutomationTabColumns = ({
  onClick,
  disableDeleteMessage,
  onDeleteClick,
}: AutomationsTabColumnsConfig): Array<TableColumn<AutomationModel>> => {
  return [
    automationsNameColumnConfig({ onClick }),
    businessAreaNameColumnConfig(),
    stageColumnConfig(),
    statusColumnConfig(),
    idColumnConfig(),
    dividerColumnConfig(),
    runTimeColumnConfig(),
    averageCasesColumnConfig(),
    successColumnConfig(),
    deleteRowColumnConfig({
      disabledMessage: disableDeleteMessage,
      onDeleteClick: onDeleteClick,
    }),
  ];
};

export const getAutomationColumnsNamedMapped = (columnName: string) => {
  const organizationsColumnsNamedMapped: Record<string, string> = {
    id: 'Id',
    name: 'Name',
    businessAreaName: 'BusinessAreaName',
    stage: 'Stage',
    status: 'Status',
    runTime: 'RunTime',
    averageCases: 'AverageCases',
    sucess: 'Sucess',
    organizationName: 'Organization',
    company: 'Company',
  };

  return organizationsColumnsNamedMapped[columnName] ?? 'Name';
};

export const AUTOMATION_PRIORITIES = [
  'very_low',
  'low',
  'medium',
  'high',
  'very_high',
];

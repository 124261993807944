import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type SucessColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const successColumnConfig = <T extends TableRow>({
  options,
}: SucessColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'success',
  label: <Trans i18nKey="success_percentage" />,
  render: (value) => (
    <div className="overflow-hidden text-ellipsis">{Math.round(value)} %</div>
  ),
  acessorKey: 'success',
  sortable: false, //TODO: Implement sorting for this column on server-side.
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});

import { z } from 'zod';

export const AutomationSchema = z.object({
  automationName: z.string().min(1, { message: 'error_field_required' }),
  automationCode: z.string().optional(),
  organizationId: z.coerce
    .number({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  companyId: z.coerce
    .number({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  businessAreaId: z.coerce
    .number({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  processId: z.coerce
    .number({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  tasks: z.any().optional(),
  description: z.string().optional(),
  developerId: z.coerce
    .number({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  stageId: z.coerce
    .number({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  priority: z
    .string({ required_error: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  orchestratorProcessId: z.string({
    required_error: 'error_field_required',
  }),
  epicId: z.string({
    required_error: 'error_field_required',
  }),
  automationPdd: z.any().optional(),
  automationSdd: z.any().optional(),
  automationManualFile: z.any().optional(),
  automationsupportDocuments: z.any().optional(),
});

export type FormAutomationSchemaType = z.infer<typeof AutomationSchema>;

export interface AutomationSchemaType extends FormAutomationSchemaType {
  id?: number;
}

import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorIcon } from '../Icons';

export const NotFound = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 flex-col items-center gap-8 pt-[10%]">
      <ErrorIcon className="size-28" />
      <h1 className="text-2xl">{t('oops!')}</h1>
      <div className="flex flex-col justify-center gap-3 text-center">
        <p className="whitespace-pre text-xl font-normal">
          {t('page_not_found_description')}
        </p>
        <small className="font-normal text-orange">
          {t('not_found_error')}
        </small>
      </div>
    </div>
  );
};

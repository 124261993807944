import { CompanyKeys, useCreateCompany } from '@/modules/Companies/queries';
import { CompanySchemaType } from '@/modules/Companies/types';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CompanyModal } from '../CompanyModal';

interface CreateCompanyModalProps {
  isOpen: boolean;
  handleClose: () => void;
  organizationId?: number;
  disableFields?: string[];
}

export const CreateCompanyModal = ({
  isOpen,
  handleClose,
  organizationId,
  disableFields,
}: CreateCompanyModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateCompany();

  const handleConfirm = (data: CompanySchemaType) => {
    const formData = new FormData();

    formData.append('organizationId', data.organizationId.toString());
    formData.append('status', 'true');
    formData.append('line1', data.addressLineOne);
    formData.append('line2', data.addressLineTwo);
    formData.append('postalCode', data.postCode);
    formData.append('country', data.country);
    formData.append('city', data.city);
    formData.append('name', data.companyName);
    formData.append('code', data.companyCode);
    formData.append('nif', data.nif);
    formData.append('jiraLink', data.jiraLink ?? '');
    data.file && formData.append('file', data.file);

    mutate(formData, {
      onError: () => {
        toast.error(t('error_creating_company'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([CompanyKeys.ALL]);
        queryClient.invalidateQueries(OrganizationKeys.ALL);
        handleClose();
        toast.success(t('success_creating_company'));
      },
    });
  };

  return (
    <CompanyModal
      handleClose={handleClose}
      type="create"
      handleOnSubmit={handleConfirm}
      title={t('create_company')}
      isOpen={isOpen}
      isLoading={isLoading}
      organizationId={organizationId}
      disableFields={disableFields}
    />
  );
};

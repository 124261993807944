import { toast } from 'react-toastify';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { InfrastructureCostSchemaType } from '@/modules/Infrastructure/types/infrastructure-cost-schema';
import { useCreateInfrastructureCost } from '@/modules/Infrastructure/queries/create-infrastructure-cost';
import { InfrastructureCostJson } from '@/modules/Infrastructure/types';
import { InfrastructureKeys } from '@/modules/Infrastructure/queries/types';
import {
  Button,
  CustomDatePicker,
  Dropdown,
  FieldInfo,
  Input,
  Modal,
  TextArea,
} from '@/components';
import { InfrastructureCostSchema } from '@/modules/Infrastructure/types';
import { RecurrenceTypes } from '@/modules/Infrastructure/types/recurrenceTypes';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

interface CreateInfrastructureCostModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const CreateInfrastructureCostModal = ({
  isOpen,
  handleClose,
}: CreateInfrastructureCostModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateInfrastructureCost();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<InfrastructureCostSchemaType>({
    defaultValues: {
      costTypeId: 0,
      name: '',
      amount: '',
      amountRecurrence: '',
      description: '',
    },
    resolver: zodResolver(InfrastructureCostSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
  });

  const dropdownCostTypeOptions = getDropdownArray([
    { id: 1, name: 'Virtual Machine' },
    { id: 2, name: 'Physical Server' },
    { id: 3, name: 'UiPath Licenses' },
    { id: 4, name: 'Microsoft Power Automate Licenses' },
    { id: 5, name: 'Other Software License' },
  ]);

  const dropdownRecurrenceOptions = getDropdownArray(
    Object.keys(RecurrenceTypes).map((recurrenceTypes) => ({
      name: t(recurrenceTypes),
      id: recurrenceTypes,
    })),
  );

  const handleConfirm = (data: InfrastructureCostSchemaType) => {
    const convertedData: InfrastructureCostJson = {
      costTypeId: Number(data.costTypeId),
      name: data.name,
      amount: Number(data.amount),
      amountRecurrence: data.amountRecurrence,
      description: data.description,
      status: true,
      startDate: data.startDate,
      endDate: data.endDate,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_creating_infrastructure_cost'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([InfrastructureKeys.GET_ALL]);
        handleClose();
        toast.success(t('success_creating_infrastructure_cost'));
      },
    });
  };

  const onSubmit: SubmitHandler<InfrastructureCostSchemaType> = (data) => {
    handleConfirm(data);
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('create_infrastructure_cost')}
      className="h-fit w-128"
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2 flex h-112 flex-col overflow-y-auto p-2">
          <FieldInfo
            title={t('cost_name')}
            errorText={
              errors.name?.message ? t(errors.name.message) : undefined
            }
            hasError={!!errors.name?.message}
          >
            <Input
              isDisabled={isLoading}
              className="w-full bg-brightGray italic"
              placeHolder={t('cost_name')}
              name="name"
              register={register}
            />
          </FieldInfo>

          <FieldInfo
            title={t('cost_type')}
            errorText={
              errors.costTypeId?.message
                ? t(errors.costTypeId.message)
                : undefined
            }
            hasError={!!errors.costTypeId?.message}
          >
            <Controller
              name="costTypeId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ...otherFieldProps } }) => (
                <Dropdown
                  {...otherFieldProps.onBlur}
                  className="h-10 w-full rounded-md bg-brightGray capitalize italic"
                  placeholder={t('select_cost_type')}
                  options={dropdownCostTypeOptions}
                  onSelect={(val) => onChange(val.value)}
                  value={Number(value)}
                />
              )}
            />
          </FieldInfo>

          <FieldInfo
            title={t('amount')}
            errorText={
              errors.amount?.message ? t(errors.amount.message) : undefined
            }
            hasError={!!errors.amount?.message}
          >
            <Input
              isDisabled={isLoading}
              className="w-full bg-brightGray italic"
              placeHolder={t('insert_amount')}
              name="amount"
              register={register}
            />
          </FieldInfo>

          <FieldInfo
            title={t('recurrence')}
            errorText={
              errors.amountRecurrence?.message
                ? t(errors.amountRecurrence.message)
                : undefined
            }
            hasError={!!errors.amountRecurrence?.message}
          >
            <Controller
              name="amountRecurrence"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ...otherFieldProps } }) => (
                <Dropdown
                  {...otherFieldProps.onBlur}
                  className="h-10 w-full rounded-md bg-brightGray capitalize italic"
                  placeholder={t('select_recurrence')}
                  options={dropdownRecurrenceOptions}
                  onSelect={(val) => onChange(val.value)}
                  value={value}
                />
              )}
            />
          </FieldInfo>

          <FieldInfo
            title={t('description')}
            errorText={
              errors.description?.message
                ? t(errors.description.message)
                : undefined
            }
            hasError={!!errors.description?.message}
          >
            <TextArea
              isDisabled={isLoading}
              className="h-24 w-full bg-brightGray"
              placeHolder={t('optional')}
              name="description"
              register={register}
            />
          </FieldInfo>

          <div className="flex flex-row justify-between gap-3">
            <FieldInfo
              title={t('start_date')}
              errorText={
                errors.startDate?.message
                  ? t(errors.startDate.message)
                  : undefined
              }
              hasError={!!errors.startDate?.message}
            >
              <Controller
                name={'startDate'}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <CustomDatePicker selected={value} onChange={onChange} />
                )}
              />
            </FieldInfo>

            <FieldInfo
              title={t('end_date')}
              errorText={
                errors.endDate?.message ? t(errors.endDate.message) : undefined
              }
              hasError={!!errors.endDate?.message}
            >
              <Controller
                name={'endDate'}
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <CustomDatePicker
                    selected={value as Date}
                    onChange={onChange}
                  />
                )}
              />
            </FieldInfo>
          </div>
        </div>

        <div className="mt-4 flex justify-between">
          <Button
            isDisabled={isLoading}
            onClick={handleClose}
            variant="outline"
            className="h-9 w-24"
          >
            {t('cancel')}
          </Button>
          <Button
            isDisabled={isLoading}
            buttonType="submit"
            className="h-9 w-24"
          >
            {t('confirm')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

import { DEFAULT_PAGE_SIZE, SortDirection } from '@/components/Table/types';
import { HttpClient } from '@/core/http/http-client';
import { ApiResponse, DataWithPagination } from '@/shared/types';
import { AutomationModel, AutomationPerformanceModel } from '../domain';
import { AutomationTaskPerformance } from '../domain/associated-tasks-model';
import { StageModel } from '../domain/stage-model';
import {
  automationMapper,
  automationPerformanceMapper,
  automationsWithPaginationMapper,
  mapAutomationTaskPerformances,
  stagesMapper,
} from '../mappers';
import {
  ApiResponseAutomations,
  AutomationDetailJson,
  AutomationPerformanceApiResponse,
  DeleteAutomationDocumentJson,
  PagedAutomations,
  UpdateAutomationJson,
  UpdateStageJson,
} from '../types';
import { AutomationTaskPerformanceJson } from '../types/associated-tasks-json';
import { StageJson } from '../types/stage-json';
import { AutomationRepository } from './automation.repository';

export class HttpAutomationRepository extends AutomationRepository {
  constructor(private readonly client: HttpClient) {
    super();
  }

  async getAllAutomations(
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<AutomationModel[]>> {
    const response = await this.client.get<
      ApiResponseAutomations<PagedAutomations>
    >({
      url: '/me/automations',
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });
    return automationsWithPaginationMapper(response);
  }

  async getAutomation(id: number): Promise<AutomationModel> {
    const response = await this.client.get<ApiResponse<AutomationDetailJson>>({
      url: `${id}`,
    });
    return automationMapper(response.data.automation);
  }

  async getAutomationPerformance(
    automationId: number,
  ): Promise<AutomationPerformanceModel> {
    const automationPerformanceJson =
      await this.client.get<AutomationPerformanceApiResponse>({
        url: `${automationId}/performance`,
      });

    return automationPerformanceMapper(
      automationPerformanceJson.data.automationPerformance,
    );
  }

  async getAutomationStages(): Promise<Array<StageModel>> {
    const stagesJson = await this.client.get<Array<StageJson>>({
      url: 'stages',
    });
    return stagesMapper(stagesJson);
  }

  async getTasksPerformanceByAutomationId(
    automationId: number,
  ): Promise<Array<AutomationTaskPerformance>> {
    const associatedTasksJson = await this.client.get<
      ApiResponse<{ tasks: Array<AutomationTaskPerformanceJson> }>
    >({
      url: `${automationId}/tasks/performance`,
    });
    return mapAutomationTaskPerformances(associatedTasksJson.data.tasks);
  }

  createAutomation = async (data: FormData): Promise<number> => {
    return await this.client.post<number>({ body: data });
  };

  async getAutomationsByBusinessArea(
    businessAreaId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<AutomationModel[]>> {
    const automations = await this.client.get<
      ApiResponseAutomations<PagedAutomations>
    >({
      url: `/business-areas/${businessAreaId}/automations`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return automationsWithPaginationMapper(automations);
  }

  async getAutomationsByCompany(
    companyId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<AutomationModel[]>> {
    const automations = await this.client.get<
      ApiResponseAutomations<PagedAutomations>
    >({
      url: `/companies/${companyId}/automations`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return automationsWithPaginationMapper(automations);
  }

  async getAutomationsByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<AutomationModel[]>> {
    const automations = await this.client.get<
      ApiResponseAutomations<PagedAutomations>
    >({
      url: `/organizations/${organizationId}/automations`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return automationsWithPaginationMapper(automations);
  }

  async getAutomationsByTask(
    taskId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<AutomationModel[]>> {
    const automations = await this.client.get<
      ApiResponseAutomations<PagedAutomations>
    >({
      url: `/tasks/${taskId}/automations`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return automationsWithPaginationMapper(automations);
  }

  async getAutomationsByInfrastructureCost(
    infrastructureCostId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<AutomationModel[]>> {
    const automations = await this.client.get<
      ApiResponseAutomations<PagedAutomations>
    >({
      url: `/infrastructure-costs/${infrastructureCostId}/automations`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return automationsWithPaginationMapper(automations);
  }

  updateAutomation = async (data: UpdateAutomationJson): Promise<number> => {
    return await this.client.put<number>(`${data.id}`, data);
  };

  updateStage = async (data: UpdateStageJson): Promise<unknown> => {
    return await this.client.put<unknown>(`${data.id}/stage`, data);
  };

  addDocument = async (data: FormData): Promise<unknown> => {
    return await this.client.post<unknown>({
      url: `${data.get('id')}/document`,
      body: data,
    });
  };

  deleteDocument = async (
    data: DeleteAutomationDocumentJson,
  ): Promise<unknown> => {
    return await this.client.delete<unknown>('document', data);
  };

  enableAutomation = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/enable`,
    });
  };

  disableAutomation = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/disable`,
    });
  };

  async deleteAutomation(id: number): Promise<ApiResponse<unknown>> {
    return await this.client.delete(`${id}`);
  }
}

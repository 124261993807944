import { Button, FilterSearchBar, Table } from '@/components';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import { useInvalidateBusinessAreaQueries } from '@/modules/BusinessAreas/hooks/use-invalidate-business-areas-queries';
import { AttachUserToBusinessModal } from '@/modules/BusinessAreas/pages/BusinessAreasPage/components/AttachUserToBusinessModal';
import { CreateBusinessAreaModal } from '@/modules/BusinessAreas/pages/BusinessAreasPage/components/CreateBusinessAreaModal';
import { useDeleteBusinessArea } from '@/modules/BusinessAreas/queries';
import {
  automationsCountColumnConfig,
  codeColumnConfig,
  companyNameColumnConfig,
  deleteRowColumnConfig,
  getBusinessAreasChildRows,
  nameWithAvatarColumnConfig,
  processesCountColumnConfig,
  roleColumnConfig,
  statusColumnConfig,
  tasksCountColumnConfig,
  usersCountColumnConfig,
  workflowsColumnConfig,
} from '@/utils';
import { ReactElement, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TabContext } from '../TabContainer';
import { TableRow } from '../Table/types';

interface BusinessAreasTabProps {
  canAttachUser?: boolean;
  canCreate?: boolean;
  userId?: string;
  disableFields?: string[];
  organizationId?: number;
  companyId?: number;
  context?: 'default' | 'userDetails';
}

export const BusinessAreasTab = ({
  canAttachUser,
  canCreate,
  userId,
  disableFields,
  organizationId,
  companyId,
  context,
}: BusinessAreasTabProps): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();
  const invalidateBusinessAreaQueries = useInvalidateBusinessAreaQueries();

  const routeChange = (row?: TableRow) => {
    const path = `/business-areas/${row?.id}`;
    navigate(path);
  };

  const { mutate: deleteBusinessArea } = useDeleteBusinessArea();

  const {
    data,
    isLoading,
    isOpen,
    page,
    onPageChange,
    handleSearchChange,
    handleModalState,
    onSort,
  } = useContext(TabContext);

  const handleDelete = useCallback(
    (businessAreaId: number) => {
      deleteBusinessArea(businessAreaId, {
        onError: () => {
          toast.error(t('error_deleting_ba'));
        },
        onSuccess: () => {
          invalidateBusinessAreaQueries();
          toast.success(t('success_deleting_ba'));
        },
      });
    },
    [deleteBusinessArea, invalidateBusinessAreaQueries, t],
  );

  const columns = useMemo(() => {
    const baseColumns = [
      nameWithAvatarColumnConfig({}),
      codeColumnConfig(),
      statusColumnConfig(),
      companyNameColumnConfig(),
      usersCountColumnConfig(),
      processesCountColumnConfig(),
      workflowsColumnConfig(),
      tasksCountColumnConfig(),
      automationsCountColumnConfig({
        options: {
          className: 'w-25',
        },
      }),
      deleteRowColumnConfig({
        disabledMessage: t('delete_ba'),
        onDeleteClick(row) {
          handleDelete(row?.id as number);
        },
      }),
    ];

    switch (context) {
      case 'userDetails':
        return [...baseColumns, roleColumnConfig()];
      case 'default':
      default:
        return [...baseColumns];
    }
  }, [context, handleDelete, t]);

  const rows = useMemo(
    () => getBusinessAreasChildRows(data?.items ?? []),
    [data],
  );

  return (
    <>
      <div className="center flex flex-row">
        <FilterSearchBar
          total={data?.totalBusinessAreas || 0}
          title={t('business_areas')}
          onSearch={handleSearchChange}
          variant={'secondary'}
          handleInputSearchChange={handleInputSearchChange}
          searchInputValue={searchInputValue}
        />
        <div className="flex items-center">
          {canCreate && (
            <Button onClick={handleModalState} variant="primary">
              {t('add_business_areas')}
            </Button>
          )}
          {canAttachUser && userId && (
            <AttachUserToBusinessModal userId={parseInt(userId)} />
          )}
        </div>
      </div>
      <CreateBusinessAreaModal
        handleClose={handleModalState}
        isOpen={isOpen}
        disableFields={disableFields}
        organizationId={organizationId}
        companyId={companyId}
      />
      <Table
        onRowClick={routeChange}
        columns={columns}
        rows={rows}
        isLoading={isLoading}
        noResultsLabel={t('no_business_areas_available')}
        withPagination
        totalItems={data?.totalRows}
        totalPages={data?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
        isTree
      />
    </>
  );
};

import { Container, Modal, TabItemProps, Tabs } from '@/components';
import { queryClient } from '@/constants/query-client';
import { HttpError } from '@/core/http';
import { useAttachUserBusinessArea } from '@/modules/BusinessAreas/queries/attach-user-businessArea';
import { BusinessAreasKey } from '@/modules/BusinessAreas/queries/keys';
import { AttachUserBusinessAreaModel } from '@/modules/BusinessAreas/types/attach-user-businessArea-model';
import { CompanyKeys } from '@/modules/Companies/queries';
import { useAttachUserCompany } from '@/modules/Companies/queries/attach-user-company';
import { AttachUserCompanyModel } from '@/modules/Companies/types/attach-user-company-model';
import { useAttachUserOrganization } from '@/modules/Organizations/queries/attach-user-organizations';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';
import { AttachUserOrganizationModel } from '@/modules/Organizations/types/attach-user-entity-model';
import { TaskKey } from '@/modules/Tasks/queries';
import { useAttachUserTask } from '@/modules/Tasks/queries/attach-user-task';
import { AttachUserTaskModel } from '@/modules/Tasks/types/attach-user-task-model';
import { useCreateUser } from '@/modules/Users/queries/create-user';
import { UsersKeys } from '@/modules/Users/queries/types';
import {
  CreateUserJson,
  UserRole,
  UserSchemaType,
} from '@/modules/Users/types';
import { ApiResponse } from '@/shared/types';
import { MutateOptions, UseMutateFunction } from '@tanstack/react-query';
import { HttpStatusCode } from 'axios';
import { TFunction } from 'i18next';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { UseFormReset } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AddCsvTabModal } from '../AddCsvTab';
import { AddExistingTab } from '../AddExistingTab';
import { UserModal } from '../UserModal';

interface CreateUserModalProps {
  isOpen: boolean;
  handleClose: () => void;
  role?: UserRole;
  disableFields?: string[];
  organizationId?: number;
  companyId?: number;
  businessAreaId?: number;
  taskId?: number;
  showRole?: boolean;
}

const handleMutation = (
  mutate: {
    (
      variables: CreateUserJson,
      options?:
        | MutateOptions<
            ApiResponse<{ data: { userId: number } }>,
            unknown,
            CreateUserJson
          >
        | undefined,
    ): void;
    (
      variables: CreateUserJson,
      options?:
        | MutateOptions<
            ApiResponse<{ data: { userId: number } }>,
            unknown,
            CreateUserJson
          >
        | undefined,
    ): void;
    (
      arg0: any,
      arg1: {
        onError: () => void;
        onSuccess: (
          data: ApiResponse<{
            data: {
              userId: number;
            };
          }>,
          variables: CreateUserJson,
          context: unknown,
        ) => void;
      },
    ): void;
  },
  data: CreateUserJson,
  t: TFunction<'translation', undefined>,
  handleClose: { (): void; (): void; (): void },
  profileTypeId: number | null,
  attachUser?:
    | {
        attach: UseMutateFunction<void, unknown, AttachUserOrganizationModel>;
        loading: boolean;
        organizationId: number;
      }
    | {
        attach: UseMutateFunction<void, unknown, AttachUserCompanyModel>;
        loading: boolean;
        companyId: number;
      }
    | {
        attach: UseMutateFunction<void, unknown, AttachUserBusinessAreaModel>;
        loading: boolean;
        businessAreaId: number;
      }
    | {
        attach: UseMutateFunction<void, unknown, AttachUserTaskModel>;
        loading: boolean;
        taskId: number;
      }
    | undefined,
  reset?: UseFormReset<UserSchemaType>,
) => {
  mutate(data, {
    onError: (error) => {
      if ((error as HttpError<unknown>)?.code === HttpStatusCode.BadRequest) {
        toast.error(t('user_already_exists'));
      } else {
        toast.error(t('error_creating_user'));
      }
    },
    onSuccess: (userData) => {
      queryClient.invalidateQueries(UsersKeys.ALL);
      queryClient.invalidateQueries(OrganizationKeys.ALL);
      queryClient.invalidateQueries(CompanyKeys.ALL);
      queryClient.invalidateQueries(BusinessAreasKey.ALL);
      queryClient.invalidateQueries(TaskKey.ALL);

      toast.success(t('success_creating_user'));
      const { data: responseData } = userData as unknown as {
        data: { userId: number };
      };
      reset && reset();
      if (!(attachUser && profileTypeId)) {
        handleClose();
        return;
      }

      if ('organizationId' in attachUser) {
        attachUser.attach({
          organizationId: attachUser.organizationId,
          userProfiles: [
            {
              userId: responseData.userId ?? 0,
              profileTypeId: profileTypeId,
            },
          ],
        });
      }
      if ('companyId' in attachUser) {
        attachUser.attach({
          companyId: attachUser.companyId,
          userProfiles: [
            {
              userId: responseData.userId ?? 0,
              profileTypeId: profileTypeId,
            },
          ],
        });
      }
      if ('businessAreaId' in attachUser) {
        attachUser.attach({
          businessAreaId: attachUser.businessAreaId,
          userProfiles: [
            {
              userId: responseData.userId ?? 0,
              profileTypeId: profileTypeId,
            },
          ],
        });
      }
      if ('taskId' in attachUser) {
        attachUser.attach({
          taskId: attachUser.taskId,
          userProfiles: [
            {
              userId: responseData.userId ?? 0,
              profileTypeId: profileTypeId,
            },
          ],
        });
      }

      handleClose();
    },
  });
};

export const CreateUserModal = ({
  isOpen,
  handleClose,
  organizationId,
  role,
  disableFields,
  companyId,
  businessAreaId,
  showRole,
  taskId,
}: CreateUserModalProps): ReactElement => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);

  const { mutate, isLoading } = useCreateUser();

  const {
    mutate: attachUserOrganization,
    isLoading: isLoadingAttachUserOrganization,
  } = useAttachUserOrganization();

  const { mutate: attachUserCompany, isLoading: isLoadingAttachUserCompany } =
    useAttachUserCompany();

  const {
    mutate: attachUserBusinessArea,
    isLoading: isLoadingAttachUserBusinessArea,
  } = useAttachUserBusinessArea();

  const { mutate: attachUserTask, isLoading: isLoadingAttachUserTask } =
    useAttachUserTask();

  const attachUser = useMemo(() => {
    if (organizationId) {
      return {
        attach: attachUserOrganization,
        loading: isLoadingAttachUserOrganization,
        organizationId: organizationId,
      };
    }
    if (companyId) {
      return {
        attach: attachUserCompany,
        loading: isLoadingAttachUserCompany,
        companyId: companyId,
      };
    }

    if (businessAreaId) {
      return {
        attach: attachUserBusinessArea,
        loading: isLoadingAttachUserBusinessArea,
        businessAreaId: businessAreaId,
      };
    }

    if (taskId) {
      return {
        attach: attachUserTask,
        loading: isLoadingAttachUserTask,
        taskId: taskId,
      };
    }
  }, [
    organizationId,
    companyId,
    businessAreaId,
    attachUserBusinessArea,
    isLoadingAttachUserBusinessArea,
    attachUserCompany,
    isLoadingAttachUserCompany,
    attachUserOrganization,
    isLoadingAttachUserOrganization,
    attachUserTask,
    isLoadingAttachUserTask,
    taskId,
  ]);

  const handleConfirm = useCallback(
    (data: UserSchemaType, reset?: UseFormReset<UserSchemaType>) => {
      const convertedData: CreateUserJson = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        status: data.status,
        access: data.access,
      };

      handleMutation(
        mutate,
        convertedData,
        t,
        handleClose,
        data.role ?? 0,
        attachUser,
        reset,
      );
    },
    [mutate, t, handleClose, attachUser],
  );

  const tabs: Array<TabItemProps> = useMemo(
    () => [
      { label: t('create_new'), badgeNumber: 0 },
      {
        label: t('add_through_csv'),
        badgeNumber: 0,
      },
      {
        label: t('add_existing_users'),
        badgeNumber: 0,
      },
    ],
    [t],
  );

  const tabsToRender = useMemo(() => {
    if (showRole) {
      return tabs;
    }
    return tabs.slice(0, 2);
  }, [tabs, showRole]);

  const onTabChange = useCallback(
    (tab: number) => {
      setSelectedTab(tab);
    },
    [setSelectedTab],
  );

  const tabList = useMemo(() => {
    return [
      {
        index: 0,
        tab: (
          <UserModal
            handleOnSubmit={handleConfirm}
            handleClose={handleClose}
            isLoading={isLoading}
            role={role}
            disableFields={disableFields}
            showRole={showRole}
            type="create"
          />
        ),
      },
      {
        index: 1,
        tab: (
          <AddCsvTabModal
            organizationId={organizationId}
            companyId={companyId}
            businessAreaId={businessAreaId}
            taskId={taskId}
            handleClose={handleClose}
          />
        ),
      },
      {
        index: 2,
        tab: (
          <AddExistingTab
            handleClose={handleClose}
            organizationId={organizationId}
            companyId={companyId}
            businessAreaId={businessAreaId}
            taskId={taskId}
          />
        ),
      },
    ];
  }, [
    handleClose,
    handleConfirm,
    isLoading,
    role,
    disableFields,
    showRole,
    businessAreaId,
    organizationId,
    companyId,
    taskId,
  ]);

  const elementToRender = tabList.find((el) => el.index === selectedTab);
  const modalTitle = selectedTab !== 2 ? 'create_users' : 'add_users';

  return (
    <Modal
      isOpen={isOpen}
      title={t(modalTitle)}
      className="h-fit w-128"
      handleClose={handleClose}
    >
      <div className="mt-6 flex flex-col gap-5">
        <Container className="mb-2">
          <Tabs tabs={tabsToRender} onTabChange={onTabChange} />
        </Container>
        <div className="flex flex-col gap-2">{elementToRender?.tab}</div>
      </div>
    </Modal>
  );
};

import { TenantModel } from '../domain';
import { TenantJson } from '../types';

export const mapTenantJsonToTenantModel = (
  tenantJson: TenantJson,
): TenantModel => ({
  id: tenantJson.id ?? 0,
  name: tenantJson.name ?? '',
  imageFileId: tenantJson.profileImageFileId,
  userRole: tenantJson.userRole,
  organizations: tenantJson.organizations ?? [],
});

export const tenantsMapper = (
  usersJson: Array<TenantJson>,
): Array<TenantModel> => usersJson.map(mapTenantJsonToTenantModel);

import {
  Button,
  CustomDatePicker,
  FieldInfo,
  Input,
  Modal,
} from '@/components';
import { Spinner } from '@/components/Spinner';
import { AmountSchema, AmountSchemaType } from '@/modules/Infrastructure/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReactElement, useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface AmountModalProps {
  handleOnSubmit: (e: AmountSchemaType) => void;
  handleClose: () => void;
  isLoading: boolean;
  disableFields?: (keyof AmountSchemaType)[];
  type: 'create' | 'edit';
  isOpen: boolean;
  title: string;
  amount?: number;
  startDate?: Date;
  endDate?: Date;
  minDate?: Date;
}

export const AmountModal = ({
  handleOnSubmit,
  handleClose,
  isLoading,
  type,
  isOpen,
  title,
  amount,
  startDate,
  endDate,
  minDate,
}: AmountModalProps): ReactElement => {
  const { t } = useTranslation();

  const {
    clearErrors,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm<AmountSchemaType>({
    resolver: zodResolver(AmountSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
  });

  useEffect(() => {
    clearErrors();

    setValue('amount', amount?.toString() || '');
    setValue('startDate', minDate || startDate || new Date());
    setValue('endDate', endDate);
  }, [
    clearErrors,
    setValue,
    watch,
    amount,
    startDate,
    endDate,
    isOpen,
    minDate,
  ]);

  const onSubmit: SubmitHandler<AmountSchemaType> = (data) => {
    handleOnSubmit(data);
  };

  const minEndDate = watch('startDate');
  const maxStartDate = useMemo(() => {
    if (type === 'edit' && endDate) {
      return watch('endDate');
    }
  }, [endDate, type, watch]);

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      className="h-fit w-128"
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="h-70 mt-2 flex flex-col overflow-y-auto p-2">
          <FieldInfo
            title={t('amount')}
            errorText={
              errors.amount?.message ? t(errors.amount.message) : undefined
            }
            hasError={!!errors.amount?.message}
          >
            <Input
              type="number"
              step="0.01"
              isDisabled={isLoading}
              className="w-full bg-brightGray italic"
              placeHolder={'0 €'}
              name="amount"
              register={register}
            />
          </FieldInfo>
          <div className="flex flex-row justify-between gap-3">
            <FieldInfo
              title={t('rate_start_date')}
              errorText={
                errors.startDate?.message
                  ? t(errors.startDate.message)
                  : undefined
              }
              hasError={!!errors.startDate?.message}
            >
              <Controller
                name={'startDate'}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <CustomDatePicker
                    selected={value}
                    onChange={onChange}
                    minDate={minDate}
                    maxDate={maxStartDate}
                  />
                )}
              />
            </FieldInfo>

            <FieldInfo
              title={t('rate_end_date')}
              errorText={
                errors.endDate?.message ? t(errors.endDate.message) : undefined
              }
              hasError={!!errors.endDate?.message}
            >
              <Controller
                name={'endDate'}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <CustomDatePicker
                    selected={value as Date}
                    onChange={onChange}
                    minDate={minEndDate}
                    disabled={!(type === 'edit' && endDate)}
                  />
                )}
              />
            </FieldInfo>
          </div>
        </div>

        <div className="mt-8 flex justify-between px-2">
          <Button onClick={handleClose} variant="outline" className="h-9 w-24">
            {t('cancel')}
          </Button>
          <Button
            buttonType="submit"
            className="h-9 w-24"
            isDisabled={isLoading}
          >
            {t(type === 'create' ? 'create' : 'confirm')}
            {isLoading && <Spinner className="ml-4 h-5 w-5" />}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

import { RoutePath } from '@/core/router';
import { useGetTagLabel } from '@/hooks';
import { PerformanceModel } from '@/modules/Automations/domain';
import { CardsTitle } from '@/modules/Automations/pages/AutomationDetailPage/components/TaskPerformanceTab/components/CardsTitle';
import { convertSecondsToTimeString } from '@/utils';
import { roundToDecimal } from '@/utils/roundToDecimals';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container } from '../Container';
import { PerformanceCard } from '../PerformanceCard';

export interface TaskPerformanceProps {
  taskId: number;
  isLoading?: boolean;
  title?: string;
  company?: string;
  businessArea?: string;
  organization?: string;
  cardData: PerformanceModel;
}

export const TaskPerformance = ({
  isLoading,
  taskId,
  company,
  businessArea,
  organization,
  cardData,
}: TaskPerformanceProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Link to={RoutePath.taskDetail(taskId.toString())}>
      <Container>
        <CardsTitle
          title={cardData.title}
          company={company}
          businessArea={businessArea}
          organization={organization}
        />
        <div className="flex flex-row gap-2">
          <PerformanceCard
            isLoading={isLoading}
            title={t('total_time_saved')}
            subtitle={`${convertSecondsToTimeString(cardData.totalTimeSaved.predicted)} ${t('predicted_manual_time')}`}
            performance={cardData.totalTimeSavedTag.performance}
            value={`${cardData.totalTimeSavedTag.timeDifference} ${useGetTagLabel(cardData.totalTimeSavedTag.difference)}`}
          >
            {convertSecondsToTimeString(cardData.totalTimeSaved.value)}
          </PerformanceCard>
          <PerformanceCard
            isLoading={isLoading}
            title={t('average_time_saved_per_case')}
            subtitle={`${convertSecondsToTimeString(cardData.averageTimeSaved.predicted)} ${t('predicted_manual_time')}`}
            performance={cardData.averageTimeSavedTag.performance}
            value={`${cardData.averageTimeSavedTag.timeDifference} ${useGetTagLabel(
              cardData.averageTimeSavedTag.difference,
            )}`}
          >
            {convertSecondsToTimeString(cardData.averageTimeSaved.value)}
          </PerformanceCard>
          <PerformanceCard
            isLoading={isLoading}
            title={t('total_return')}
            subtitle={`(${roundToDecimal(cardData.totalReturn.totalInvestment)}€ ${t('estimated_manual_cost')})`}
            performance={cardData.totalReturnTag.performance}
            value={`${roundToDecimal(+cardData.totalReturnTag.timeDifference)}€ ${useGetTagLabel(
              cardData.totalReturnTag.difference,
              'coin',
            )}`}
          >
            {roundToDecimal(cardData.totalReturn.value)}€
          </PerformanceCard>
          <PerformanceCard
            isLoading={isLoading}
            title={t('average_return_per_case')}
            subtitle={`(${roundToDecimal(cardData.averageReturn.predicted)}€ ${t('minimum_value')})`}
            performance={cardData.averageReturnTag.performance}
            value={`${roundToDecimal(+cardData.averageReturnTag.timeDifference) || 0}€ ${useGetTagLabel(
              cardData.averageReturnTag.difference,
              'coin',
            )}`}
          >
            {roundToDecimal(cardData.averageReturn.value || 0)}€
          </PerformanceCard>
        </div>
      </Container>
    </Link>
  );
};

import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { infrastructuresRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import {
  InfrastructureCostBaseInfoModel,
  InfrastructureCostModel,
} from '../domain';
import { SortDirection } from '@/components/Table/types';
import { InfrastructureKeys } from './types';

export const useGetInfrastructureCosts = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<InfrastructureCostModel[]>,
    any,
    DataWithPagination<InfrastructureCostModel[]>
  >,
) =>
  useQuery<DataWithPagination<InfrastructureCostModel[]>>(
    [InfrastructureKeys.GET_ALL, { pageNumber, filter, sortBy, sortOrder }],
    () =>
      infrastructuresRepo.getInfrastructureCosts(
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );

export const useGetAllInfrastructureCostsByAutomationId = (
  automationId: number,
) =>
  useQuery<InfrastructureCostBaseInfoModel[]>(
    [InfrastructureKeys.GET_ALL],
    () =>
      infrastructuresRepo.getAllInfrastructureCostsByAutomationId(automationId),
  );

import { Button, FieldInfo, Input, Modal } from '@/components';
import {
  ChangePasswordSchema,
  ChangePasswordSchemaType,
} from '@/components/SideNav/types/change-password-schema';
import { Spinner } from '@/components/Spinner';
import { useChangePassword } from '@/modules/Users/queries';
import { ChangePasswordJson } from '@/modules/Users/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface ChangePasswordModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const ChangePasswordModal = ({
  isOpen,
  handleClose,
}: ChangePasswordModalProps): ReactElement => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ChangePasswordSchemaType>({
    resolver: zodResolver(ChangePasswordSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
  });

  const { mutate, isLoading } = useChangePassword();

  const handleConfirm = (data: ChangePasswordSchemaType) => {
    const convertedData: ChangePasswordJson = {
      password: data.newPassword,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_changing_password'));
      },
      onSuccess: () => {
        handleClose();
        toast.success(t('success_changing_password'));
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('change_password')}
      className="h-fit w-128"
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(handleConfirm)}>
        <div className="mt-2 flex flex-col overflow-y-auto p-2">
          <FieldInfo
            title={t('new_password')}
            errorText={
              errors.newPassword?.message
                ? t(errors.newPassword.message)
                : undefined
            }
            hasError={!!errors.newPassword?.message}
          >
            <Input
              isDisabled={isLoading}
              className="w-full bg-brightGray italic"
              placeHolder={t('new_password')}
              name="newPassword"
              register={register}
              type="password"
            />
          </FieldInfo>
          <FieldInfo
            title={t('confirm_password')}
            errorText={
              errors.confirmPassword?.message
                ? t(errors.confirmPassword.message)
                : undefined
            }
            hasError={!!errors.confirmPassword?.message}
          >
            <Input
              isDisabled={isLoading}
              className="w-full bg-brightGray italic"
              placeHolder={t('confirm_password')}
              name="confirmPassword"
              register={register}
              type="password"
            />
          </FieldInfo>
        </div>

        <div className="mt-8 flex justify-between">
          <Button onClick={handleClose} variant="outline" className="h-9 w-24">
            {t('cancel')}
          </Button>
          <Button buttonType="submit" className="h-9 w-24">
            {t('confirm')}
            {isLoading && <Spinner className="ml-4 h-5 w-5" />}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

import { StatusColumn } from '@/components/StatusColumn/StatusColumn';
import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type StatusColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
  onChange: (checked: boolean, row: T) => void;
  parseValue?: (value: string) => boolean;
};

export const toggleStatusColumnConfig = <T extends TableRow>({
  options,
  onChange,
  parseValue,
}: StatusColumnConfig<T>): TableColumn<T> => ({
  id: 'status',
  acessorKey: 'status',
  label: <Trans i18nKey="status" />,
  render: (value, row) => (
    <StatusColumn
      value={parseValue ? parseValue(value) : value}
      row={row}
      onChange={onChange}
    />
  ),
  sortable: true,
  ...options,
});

import { useCallback } from 'react';
import { useDisableBusinessArea, useEnableBusinessArea } from '../queries';
import { BusinessAreasKey } from '../queries/keys';
import { useQueryClient } from '@tanstack/react-query';

export const useToggleBusinessAreaStatus = () => {
  const { mutate: enableBusinessArea } = useEnableBusinessArea();
  const { mutate: disableBusinessArea } = useDisableBusinessArea();
  const queryClient = useQueryClient();
  const onChange = useCallback(
    (checked: boolean, businessAreaId: number) => {
      const onSuccess = () => {
        queryClient.invalidateQueries(BusinessAreasKey.ALL);
      };

      if (checked) {
        enableBusinessArea(businessAreaId, {
          onSuccess,
        });
      } else {
        disableBusinessArea(businessAreaId, {
          onSuccess,
        });
      }
    },
    [disableBusinessArea, enableBusinessArea, queryClient],
  );

  return { onChange };
};

import { FilterSearchBar, Table } from '@/components';
import {
  amountColumnConfig,
  companyNameColumnConfig,
  costColumnConfig,
  endDateColumnConfig,
  recurrenceColumnConfig,
  startDateColumnConfig,
  statusColumnConfig,
  typeColumnConfig,
} from '@/utils';
import { ReactElement, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TabContext } from '../TabContainer';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import { TableRow } from '../Table/types';
import { useNavigate } from 'react-router-dom';
import { AttachEntityToInfrastructureCostModal } from '@/modules/Infrastructure/pages/InfrastructureCostPage/components/AttachEntityToInfrastructureCostModal';

interface InfrastructureCostsTabProps {
  automationId?: number;
  canAttach?: boolean;
  disableFields?: string[];
  context?: 'companyDetails' | 'organizationDetails' | 'automationDetails';
}

export const InfrastructureCostsTab = ({
  context,
  automationId,
  canAttach,
}: InfrastructureCostsTabProps): ReactElement => {
  const { t } = useTranslation();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();
  const navigate = useNavigate();

  const {
    data: infrastructure_costs,
    isLoading: isLoadinginfrastructure_costs,
    page,
    onPageChange,
    handleSearchChange,
    onSort,
  } = useContext(TabContext);

  const routeChange = (row?: TableRow) => {
    const path = `/infrastructure-costs/${row?.id}`;
    navigate(path);
  };

  const columns = useMemo(() => {
    const baseColumns = [
      costColumnConfig({}),
      typeColumnConfig(),
      amountColumnConfig(),
      recurrenceColumnConfig(),
      statusColumnConfig(),
    ];

    if (context == 'organizationDetails') {
      baseColumns.splice(1, 0, companyNameColumnConfig());
    }

    if (context == 'automationDetails') {
      baseColumns.splice(1, 0, startDateColumnConfig(), endDateColumnConfig());
    }

    return baseColumns;
  }, [context]);

  return (
    <>
      <div className="center flex flex-row">
        <FilterSearchBar
          total={infrastructure_costs?.totalRows || 0}
          title={t('infrastructure_costs')}
          onSearch={handleSearchChange}
          variant={'secondary'}
          handleInputSearchChange={handleInputSearchChange}
          searchInputValue={searchInputValue}
        />
        <div className="flex items-center">
          {canAttach && automationId && (
            <AttachEntityToInfrastructureCostModal
              costId={automationId}
              context="automation"
              automationId={automationId}
            />
          )}
        </div>
      </div>
      <Table
        onRowClick={routeChange}
        columns={columns}
        rows={infrastructure_costs?.items ?? []}
        isLoading={isLoadinginfrastructure_costs}
        noResultsLabel={t('no_costs_available')}
        withPagination
        totalItems={infrastructure_costs?.totalRows}
        totalPages={infrastructure_costs?.totalPages}
        currentPage={page}
        onSort={onSort}
        onPageChange={onPageChange}
      />
    </>
  );
};

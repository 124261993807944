import { Button, Table } from '@/components';
import { TabContext } from '@/components/TabContainer';
import { t } from 'i18next';
import { useCallback, useContext, useMemo, useState } from 'react';
import { getUserRatesColumns } from '../../../UsersPage/utils';
import { roundToDecimalFull } from '@/utils/roundToDecimals';
import { CreateHourlyRateModal } from '../CreateHourlyRateModal';
import { EditHourlyRateModal } from '../EditHourlyRateModal';
import { UserRateModel } from '@/modules/Users/domain/user-rate-model';

export const HourlyRatesTab = ({
  canCreate = true,
  userId,
}: {
  canCreate?: boolean;
  userId: number;
}) => {
  const [isOpenEditModal, setIsEditOpenModal] = useState<boolean>(false);
  const [selectedHourlyRate, setSelectedHourlyRate] = useState<UserRateModel>();

  const {
    data: userRates,
    isLoading: isLoadingUserRates,
    isOpen,
    page,
    onPageChange,
    handleModalState,
    onSort,
  } = useContext(TabContext);

  const handleEditModalState = useCallback(
    (hourlyRateId: number) => {
      const hourlyRate = (userRates?.items as UserRateModel[]).find(
        (rate) => rate.id === hourlyRateId,
      );
      setSelectedHourlyRate(hourlyRate);
      setIsEditOpenModal(true);
    },
    [userRates],
  );

  const handleEditModalClose = useCallback(() => {
    setIsEditOpenModal(false);
    setSelectedHourlyRate(undefined);
  }, []);

  const lastChange = useMemo(() => {
    const rateItems = (userRates?.items ?? []) as UserRateModel[];

    rateItems.sort((a, b) => {
      const dateA = a.endDate;
      const dateB = b.endDate;

      if (!dateA && !dateB) {
        return 0;
      }

      if (dateA && !dateB) {
        return 1;
      }

      if (!dateA && dateB) {
        return -1;
      }

      return dateB!.getTime() - dateA!.getTime();
    });

    const change = rateItems[0];

    let date = change?.updatedAt ?? change?.createdAt;

    if (!date && rateItems.length > 1) {
      date = rateItems[1]?.updatedAt;
    }

    if (!date) {
      return { lastRate: change };
    }

    return {
      lastRate: change,
      text: t('last_rate_changed', {
        date: date?.toLocaleString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }),
      }),
    };
  }, [userRates]);

  const minDate = useMemo(() => {
    if (lastChange.lastRate?.startDate) {
      const date = new Date(lastChange.lastRate?.startDate);
      return new Date(date.setDate(date.getDate() + 1));
    }
  }, [lastChange.lastRate?.startDate]);

  const columnsToEdit = useMemo(() => {
    const items = userRates?.items as UserRateModel[];
    return items?.slice(0, 2).map((column) => column.id);
  }, [userRates]);

  return (
    <>
      <div className="flex flex-row justify-between">
        <span className="font-bold text-blueNuit">{t('hourly_rates')}</span>
        {canCreate && (
          <Button onClick={handleModalState} variant="primary">
            {t('add_hourly_rate')}
          </Button>
        )}
      </div>
      <CreateHourlyRateModal
        isOpen={isOpen}
        handleClose={handleModalState}
        userId={userId}
        minDate={minDate}
        jiraAccountId={lastChange.lastRate?.jiraAccountId}
      />
      {selectedHourlyRate && (
        <EditHourlyRateModal
          isOpen={isOpenEditModal}
          handleClose={handleEditModalClose}
          userId={userId}
          rate={selectedHourlyRate?.value}
          jiraAccountId={selectedHourlyRate?.jiraAccountId}
          startDate={selectedHourlyRate?.startDate}
          endDate={selectedHourlyRate?.endDate}
          hourlyRateId={selectedHourlyRate.id}
        />
      )}
      <div className="flex flex-col">
        <h1>{roundToDecimalFull(lastChange.lastRate?.value ?? 0)}€</h1>
        <span className="text-xs text-primaryBlue">{lastChange.text}</span>
      </div>
      <Table
        columns={getUserRatesColumns({
          onClick: handleEditModalState,
          columnsToEdit: columnsToEdit,
        })}
        rows={userRates?.items ?? []}
        isLoading={isLoadingUserRates}
        noResultsLabel={t('no_rates_available')}
        withPagination
        totalItems={userRates?.totalRows}
        totalPages={userRates?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
      />
    </>
  );
};

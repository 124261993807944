import { PerformanceModel } from '@/modules/Automations/domain';
import { useGetAssociatedTasks } from '@/modules/Automations/queries/use-get-associated-tasks';
import { calculateValueDifference } from '@/utils';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useGetAssociatedTaskCardsData = (): PerformanceModel[] => {
  const { automationId: automationIdParam } = useParams<{
    automationId: string;
  }>();
  const automationId = +automationIdParam!;
  const { data: taskPerformances } = useGetAssociatedTasks(automationId);

  return useMemo(() => {
    if (!taskPerformances || !taskPerformances.length) {
      return [];
    }

    return taskPerformances.map<PerformanceModel>((taskPerformance) => {
      return {
        id: taskPerformance.id,
        title: taskPerformance.name,
        totalTimeSaved: {
          value: taskPerformance.runTimeSeconds,
          predicted: taskPerformance.predictedManualTimeSeconds,
        },
        averageReturn: {
          value: taskPerformance.averageCost,
          predicted: taskPerformance.averageManualCost,
        },
        totalReturn: {
          totalInvestment: taskPerformance.totalInvestment,
          value: taskPerformance.totalReturn,
        },
        totalTimeSavedTag: calculateValueDifference(
          taskPerformance.predictedManualTimeSeconds,
          taskPerformance.runTimeSeconds,
          'time',
        ),
        averageTimeSaved: {
          predicted: taskPerformance.averageManualTimeSeconds,
          value: taskPerformance.averageRunTimeSeconds,
        },
        averageTimeSavedTag: calculateValueDifference(
          taskPerformance.averageRunTimeSeconds,
          taskPerformance.averageManualTimeSeconds,
          'time',
        ),
        averageReturnTag: calculateValueDifference(
          taskPerformance.averageManualCost,
          taskPerformance.averageCost,
          'coin',
        ),
        totalReturnTag: calculateValueDifference(
          taskPerformance.totalInvestment,
          taskPerformance.totalReturn,
          'coin',
        ),
      };
    });
  }, [taskPerformances]);
};

import { Button, ConfirmModal, FilterSearchBar, Table } from '@/components';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import { useToggleUserAccess } from '@/hooks/use-toggle-user-access';
import { useToggleUserStatus } from '@/hooks/use-toggle-user-status';
import { UserRole } from '@/modules';
import { EditRoleModal } from '@/modules/Users/pages/UserDetailPage/components/EditRoleModal';
import { CreateUserModal } from '@/modules/Users/pages/UsersPage/components/CreateUserModal';
import { getUsersColumns } from '@/modules/Users/pages/UsersPage/utils';
import { roleColumnConfig } from '@/utils';
import { actionsEditRowColumnConfig } from '@/utils/tableColumns/actions-edit-row-column-config';
import { ReactElement, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TabContext } from '../TabContainer';
import { TableColumn, TableRow } from '../Table/types';

interface UserTabProps {
  canCreate?: boolean;
  disableFields?: string[];
  role?: UserRole;
  companyId?: number;
  organizationId?: number;
  businessAreaId?: number;
  taskId?: number;
  showRole?: boolean;
  unlinkData?: {
    canUnlink: boolean;
    action: (userId: number) => void;
    message: string;
  };
}

export const UsersTab = ({
  disableFields,
  role,
  companyId,
  organizationId,
  businessAreaId,
  taskId,
  showRole,
  unlinkData,
}: UserTabProps): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();
  const [isOpenRole, setIsOpenRole] = useState(false);
  const [isOpenUnlink, setIsOpenUnlink] = useState(false);
  const [userSelected, setUserSelected] = useState<{
    id: number;
    role?: number;
  }>();

  const { onChange: onAccessChange } = useToggleUserAccess();
  const { onChange: onStatusChange } = useToggleUserStatus();

  const handleOpenRoleModalState = useCallback((id: number, role?: number) => {
    setIsOpenRole(true);
    setUserSelected({ id, role });
  }, []);

  const handleOpenRemoveUserModalState = useCallback((id: number) => {
    setIsOpenUnlink(true);
    setUserSelected({ id });
  }, []);

  const handleCloseRoleModalState = useCallback(() => {
    setIsOpenRole(false);
    setUserSelected(undefined);
  }, []);

  const handleCloseRemoveUserModalState = useCallback(() => {
    setIsOpenUnlink(false);
    setUserSelected(undefined);
  }, []);

  const disassociateUserWithTask = useCallback(() => {
    if (!unlinkData || !userSelected?.id) {
      return;
    }

    unlinkData.action(userSelected.id);
    setIsOpenUnlink(false);
  }, [unlinkData, userSelected?.id]);

  const {
    data: users,
    isLoading: isLoadingUsers,
    isOpen,
    page,
    onPageChange,
    handleSearchChange,
    handleModalState,
    onSort,
  } = useContext(TabContext);

  const routeChange = (row?: TableRow) => {
    const path = `/users/${row?.id}`;
    navigate(path);
  };

  const handleOnAccessChange = useCallback(
    (checked: boolean, row: TableRow) => {
      onAccessChange(checked, +row.id);
    },
    [onAccessChange],
  );

  const handleOnStatusChange = useCallback(
    (checked: boolean, row: TableRow) => {
      onStatusChange(checked, +row.id);
    },
    [onStatusChange],
  );

  return (
    <>
      <div className="center flex flex-row">
        <FilterSearchBar
          total={users?.totalRows || 0}
          title={t('users')}
          onSearch={handleSearchChange}
          variant={'secondary'}
          handleInputSearchChange={handleInputSearchChange}
          searchInputValue={searchInputValue}
        />
        <div className="flex items-center">
          <Button onClick={handleModalState} variant="primary">
            {t('add_user')}
          </Button>
        </div>
      </div>
      <CreateUserModal
        isOpen={isOpen}
        handleClose={handleModalState}
        organizationId={organizationId}
        role={role}
        disableFields={disableFields}
        companyId={companyId}
        businessAreaId={businessAreaId}
        taskId={taskId}
        showRole={showRole}
      />
      <EditRoleModal
        isOpen={isOpenRole}
        handleClose={handleCloseRoleModalState}
        user={userSelected}
        organizationId={organizationId}
        companyId={companyId}
        businessAreaId={businessAreaId}
      />
      {unlinkData?.canUnlink && (
        <ConfirmModal
          isOpen={isOpenUnlink}
          handleClose={handleCloseRemoveUserModalState}
          title="Please confirm action"
          message={unlinkData.message}
          onConfirm={disassociateUserWithTask}
        ></ConfirmModal>
      )}
      <Table
        onRowClick={routeChange}
        columns={[
          ...getUsersColumns({
            onAccessChange: handleOnAccessChange,
            onStatusChange: handleOnStatusChange,
          }),
          roleColumnConfig(),
          actionsEditRowColumnConfig({
            options: { label: t('actions') } as TableColumn<TableRow>,
            onEditClick(row) {
              handleOpenRoleModalState(row.id as number, row.profileTypeId);
            },
            onUnlinkClick(row) {
              handleOpenRemoveUserModalState(row.id as number);
            },
            canUnlink: unlinkData?.canUnlink,
          }),
        ]}
        rows={users?.items ?? []}
        isLoading={isLoadingUsers}
        noResultsLabel={t('no_users_available')}
        withPagination
        totalItems={users?.totalRows}
        totalPages={users?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
      />
    </>
  );
};

import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type AverageCasesColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const averageCasesColumnConfig = <T extends TableRow>({
  options,
}: AverageCasesColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'averageCases',
  label: <Trans i18nKey="average_cases" />,
  render: (value) => (
    <div className="overflow-hidden text-ellipsis">{Math.trunc(value)}</div>
  ),
  acessorKey: 'averageCases',
  sortable: false, //TODO: Implement sorting for this column on server-side.
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});

import { ReactElement, useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetCurrentUser } from '../../queries';
import { Button, ChevronLeft, CustomPageContainer } from '@/components';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@/components/Spinner';
import { TenantContext } from '@/components/TenantProvider';
import { ManageUsers, MyAccountInfo } from './components';

export const UserProfilePage = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoadingTenantInfo } = useContext(TenantContext);

  const { data: user, isLoading: isLoadingUser } = useGetCurrentUser();

  const routeChange = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const isLoadingInfo = useMemo(
    () => isLoadingUser || isLoadingTenantInfo,
    [isLoadingUser, isLoadingTenantInfo],
  );

  return (
    <CustomPageContainer className="w-4/5">
      {isLoadingInfo ? (
        <Spinner className="ml-4 h-5 w-5" />
      ) : (
        <>
          <div>
            <Button
              className="h-6 w-14 justify-between border-blueNuit p-2 font-black text-blueNuit"
              variant="outline"
              onClick={routeChange}
            >
              <ChevronLeft />
              {t('back')}
            </Button>
            <p className="mt-3 text-xs">
              {t('my_account')} /{' '}
              <span className="font-black">
                {user?.firstName} {user?.lastName} {''}
              </span>
            </p>
          </div>
          <div className="flex w-full flex-col gap-10">
            <MyAccountInfo />
            {user?.accountAdmin && <ManageUsers />}
          </div>
        </>
      )}
    </CustomPageContainer>
  );
};

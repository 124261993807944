import {
  Button,
  CustomPageContainer,
  FilterAndPaginationContext,
  FilterSearchBar,
  Table,
} from '@/components';
import { TableRow, TableSort } from '@/components/Table/types';
import { queryClient } from '@/constants';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useToggleWorkflowStatus } from '../../hooks/use-toggle-workflow-status';
import { useDeleteWorkflow, useGetWorkflows, WorkflowKey } from '../../queries';
import { CreateWorkflowModal } from './components/CreateWorkflowModal';
import { getWorflowsColumnsNamedMapped, getWorkflowsColumns } from './utils';

export const WorkflowsPage = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tableSort, setTableSort] = useState<TableSort[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const handleModalState = () => {
    setIsOpen(!isOpen);
  };
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();
  const { onChange: onWorkflowStatusChange } = useToggleWorkflowStatus();

  const { mutate: deleteOrganization } = useDeleteWorkflow();

  const handleDelete = (workflowId: number) => {
    deleteOrganization(workflowId, {
      onError: () => {
        toast.error(t('error_deleting_workflow'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(WorkflowKey.ALL);
        toast.success(t('success_deleting_workflow'));
      },
    });
  };

  const getAllWorkflowsOptions = useMemo(
    () => ({
      onError: () => {
        toast.error(t('error_getting_workflows'));
      },
    }),
    [t],
  );

  const { searchTerm, page, onPageChange, handleSearchChange } = useContext(
    FilterAndPaginationContext,
  );

  const { data: workflows, isLoading: isLoadingWorkflows } = useGetWorkflows(
    page + 1,
    searchTerm,
    getWorflowsColumnsNamedMapped(tableSort?.[0]?.columnId ?? 'name'),
    tableSort?.[0]?.direction ?? 'ascending',
    getAllWorkflowsOptions,
  );

  const routeChange = useCallback(
    (row?: TableRow) => {
      const path = `${row?.id}`;
      navigate(path);
    },
    [navigate],
  );

  const handleOnStatusChange = useCallback(
    (checked: boolean, row: TableRow) => {
      onWorkflowStatusChange(checked, +row.id);
    },
    [onWorkflowStatusChange],
  );

  return (
    <CustomPageContainer>
      <CustomPageContainer.Header title={t('workflows')}>
        <Button onClick={handleModalState} variant="primary" className="h-9">
          {t('create_workflow')}
        </Button>
      </CustomPageContainer.Header>
      <CreateWorkflowModal handleClose={handleModalState} isOpen={isOpen} />
      <FilterSearchBar
        total={workflows?.totalRows || 0}
        title={t('workflows')}
        onSearch={handleSearchChange}
        handleInputSearchChange={handleInputSearchChange}
        searchInputValue={searchInputValue}
      ></FilterSearchBar>
      <Table
        columns={getWorkflowsColumns({
          onClick: routeChange,
          disableDeleteMessage: t('delete_workflow'),
          onDeleteClick(row) {
            handleDelete(row?.id as number);
          },
          onStatusChange: handleOnStatusChange,
        })}
        rows={workflows?.items ?? []}
        isLoading={isLoadingWorkflows}
        noResultsLabel={t('no_workflows_available')}
        withPagination
        totalItems={workflows?.totalRows}
        totalPages={workflows?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={setTableSort}
      />
    </CustomPageContainer>
  );
};

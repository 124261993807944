import { SwitchStatus, UserAvatar } from '@/components';
import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { Container } from '@/components/Container';
import { queryClient } from '@/constants';
import { OrganizationModel } from '@/modules/Organizations/domain';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';
import { OrganizationStatus } from '@/modules/Organizations/types';
import { useGetCurrentUser } from '@/modules/Users/queries';
import {
  useDeleteProfileOrganizationPicture,
  useUploadProfileOrganizationPicture,
} from '@/modules/Users/queries/profile-picture';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { EditOrganizationModal } from '../../../OrganizationsPage/components/EditOrganizationModal';

interface HeaderRowProps {
  organization?: OrganizationModel;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string,
  ) => void;
  status: boolean;
  isLoading?: boolean;
}

export const HeaderRow = ({
  organization,
  handleStatus,
  status,
}: HeaderRowProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const { mutate: uploadProfilePicture, isLoading: isUpdatingProfilePicture } =
    useUploadProfileOrganizationPicture();

  const { mutate: deleteProfileImage, isLoading: isDeletingProfilePicture } =
    useDeleteProfileOrganizationPicture();

  const { data: currentUser } = useGetCurrentUser();

  const handleSuccessRequest = useCallback(
    (toastKeyMsg: string) => {
      queryClient.invalidateQueries(OrganizationKeys.ALL);
      toast.success(t(toastKeyMsg));
    },
    [t],
  );

  const isLoadingProfileImage = useMemo(
    () => isUpdatingProfilePicture || isDeletingProfilePicture,
    [isDeletingProfilePicture, isUpdatingProfilePicture],
  );

  const pictureOrganizationActions = useMemo(
    () =>
      currentUser?.accountAdmin
        ? {
            onUploadClick: (file: File) => {
              const formData = new FormData();
              formData.append('EntityId', (organization?.id ?? '').toString());
              formData.append('File', file);

              uploadProfilePicture(formData, {
                onSuccess: () =>
                  handleSuccessRequest('success_profile_image_updated'),
                onError: () => toast.error(t('error_updating_profile_image')),
              });
            },
            onDeleteClick: () => {
              if (!organization || !organization.profileImageFileId) {
                return;
              }

              deleteProfileImage(
                {
                  entityId: organization.id,
                  fileId: organization.profileImageFileId,
                },
                {
                  onSuccess: () =>
                    handleSuccessRequest('success_profile_image_deleted'),
                  onError: () => toast.error(t('error_deleting_profile_image')),
                },
              );
            },
          }
        : undefined,
    [
      currentUser?.accountAdmin,
      deleteProfileImage,
      handleSuccessRequest,
      organization,
      t,
      uploadProfilePicture,
    ],
  );

  return (
    <Container isLoading={!organization}>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-5">
          <UserAvatar
            size="large"
            name={organization?.name || ''}
            pictureActions={pictureOrganizationActions}
            isLoading={isLoadingProfileImage}
            imageId={organization?.profileImageFileId}
          />
          <h2>{organization?.name || ''}</h2>
        </div>
        <div className="flex h-10 flex-row gap-3">
          <div className="flex flex-row items-center gap-3 rounded-md bg-white pb-0 pl-3 pr-3 pt-0 text-sm leading-xs">
            <span className="pr-10 font-black">Status</span>
            <span
              className={`p-0 ${status ? 'text-americanGreen' : 'brightGray'}`}
            >
              {status ? OrganizationStatus.Active : OrganizationStatus.Inactive}
            </span>
            <SwitchStatus onChange={handleStatus} checked={status} />
          </div>
          <Button className="h-9 p-5" onClick={handleModalState}>
            {t('edit_organization')}
          </Button>
          {organization && (
            <EditOrganizationModal
              isOpen={isOpen}
              organizationId={organization.id}
              handleClose={handleModalState}
            />
          )}
        </div>
      </div>
      <Card title={t('description')}>
        <p>{organization?.description || ''}</p>
      </Card>
    </Container>
  );
};

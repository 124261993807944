export type TotalDuration = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export const getTotalDurationBySeconds = (seconds: number): TotalDuration => {
  return {
    days: seconds / 86400,
    hours: seconds / 3600,
    minutes: seconds / 60,
    seconds,
  };
};

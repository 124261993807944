import { Button, Dropdown, SelectDropdownOption } from '@/components';
import { AttachUserToEntityModal } from '@/components/AttachUserToEntityModal';
import { queryClient } from '@/constants';
import { useGetAllBusinessAreasByCompany } from '@/modules/BusinessAreas/queries';
import { useGetAllCompaniesByOrganization } from '@/modules/Companies/queries';
import { useGetAllOrganizations } from '@/modules/Organizations/queries';
import {
  ProcessKey,
  useGetAllProcessesByBusinessArea,
} from '@/modules/Processes/queries';
import { useAttachUserProcess } from '@/modules/Processes/queries/attach-user-process';
import { UsersKeys } from '@/modules/Users/queries/types';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface AttachUserToProcessModalProps {
  userId: number;
}

export const AttachUserToProcessModal = ({
  userId,
}: AttachUserToProcessModalProps): ReactElement => {
  const { t } = useTranslation();
  const [dropdownOrganizationValue, setOrganizationDropdownEntityValue] =
    useState<number>();
  const [dropdownCompanyValue, setDropdownCompanyValue] = useState<number>();
  const [dropdownBusinessValue, setDropdownBusinessValue] = useState<number>();
  const [dropdownProcessValue, setDropdownProcessValue] = useState<number>();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleModalState = () => setIsOpen(!isOpen);

  const { mutate, isLoading } = useAttachUserProcess();

  const handleConfirm = (roleId: number) => {
    if (!dropdownProcessValue) return;
    mutate(
      {
        userProfiles: [
          {
            userId: userId ?? 0,
            profileTypeId: roleId,
          },
        ],
        processId: dropdownProcessValue,
      },
      {
        onError: () => {
          toast.error(
            t('error_attaching_user_to_entity', { entity: t('process') }),
          );
        },
        onSuccess: () => {
          queryClient.invalidateQueries(ProcessKey.ALL);
          queryClient.invalidateQueries(UsersKeys.ALL);
          handleModalState();
          toast.success(
            t('success_attaching_user_to_entity', { entity: t('process') }),
          );
        },
      },
    );
  };

  const { data: organizationData } = useGetAllOrganizations();

  const { data: companiesData } = useGetAllCompaniesByOrganization(
    dropdownOrganizationValue,
  );

  const { data: businessData } =
    useGetAllBusinessAreasByCompany(dropdownCompanyValue);
  const { data: processData } = useGetAllProcessesByBusinessArea(
    dropdownBusinessValue,
  );

  const organizationDropdownOptions = useMemo(
    () => getDropdownArray(organizationData ?? []),
    [organizationData],
  );

  const companiesDropdownOptions = useMemo(
    () => getDropdownArray(companiesData ?? []),
    [companiesData],
  );

  const businessDropdownOptions = useMemo(
    () => getDropdownArray(businessData ?? []),
    [businessData],
  );

  const processDropdownOptions = useMemo(
    () => getDropdownArray(processData ?? []),
    [processData],
  );

  const handleOnSelectOrganizationDropdownValue = useCallback(
    (option: SelectDropdownOption<number>) =>
      setOrganizationDropdownEntityValue(option.value),
    [],
  );

  const handleOnSelectCompanyDropdownValue = useCallback(
    (option: SelectDropdownOption<number>) =>
      setDropdownCompanyValue(option.value),
    [],
  );

  const handleOnSelectBusinessDropdownValue = useCallback(
    (option: SelectDropdownOption<number>) =>
      setDropdownBusinessValue(option.value),
    [],
  );

  const handleOnSelectProcessDropdownValue = useCallback(
    (option: SelectDropdownOption<number>) =>
      setDropdownProcessValue(option.value),
    [],
  );

  useEffect(() => {
    setDropdownCompanyValue(undefined);
  }, [dropdownOrganizationValue]);

  useEffect(() => {
    setDropdownBusinessValue(undefined);
  }, [dropdownCompanyValue]);

  useEffect(() => {
    setDropdownProcessValue(undefined);
  }, [dropdownBusinessValue]);

  return (
    <>
      <Button onClick={handleModalState} variant="primary">
        {t('attach_entity', { entity: t('process') })}
      </Button>
      <AttachUserToEntityModal
        isOpen={isOpen}
        isLoading={isLoading}
        entityType={'process'}
        onHandleClose={handleModalState}
        onHandleConfirm={handleConfirm}
        isConfirmDisabled={!dropdownProcessValue}
      >
        <div className="text-sm font-bold capitalize">
          {t('select_existing_entity', { entity: t('organizations') })}
        </div>
        <Dropdown
          className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
          placeholder={t('select')}
          options={organizationDropdownOptions}
          value={dropdownOrganizationValue}
          onSelect={handleOnSelectOrganizationDropdownValue}
        />
        <div className="text-sm font-bold capitalize">
          {t('select_existing_entity', { entity: t('companies') })}
        </div>
        <Dropdown
          className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
          placeholder={t('select')}
          options={companiesDropdownOptions}
          value={dropdownCompanyValue}
          onSelect={handleOnSelectCompanyDropdownValue}
        />
        <div className="text-sm font-bold capitalize">
          {t('select_existing_entity', { entity: t('businessArea') })}
        </div>
        <Dropdown
          className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
          placeholder={t('select')}
          options={businessDropdownOptions}
          value={dropdownBusinessValue}
          onSelect={handleOnSelectBusinessDropdownValue}
        />
        <div className="text-sm font-bold capitalize">
          {t('select_existing_entity', { entity: t('process') })}
        </div>
        <Dropdown
          className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
          placeholder={t('select')}
          options={processDropdownOptions}
          value={dropdownProcessValue}
          onSelect={handleOnSelectProcessDropdownValue}
        />
      </AttachUserToEntityModal>
    </>
  );
};

import { PaginationParams } from '@/shared/types';

export abstract class ProcessKey {
  static ALL = ['processes'];
  static LIST = [...ProcessKey.ALL, 'list'];
  static LIST_PAGED = (pagination: PaginationParams) => [
    ...ProcessKey.LIST,
    pagination,
  ];
  static BY_ID = (processId: number) => [...ProcessKey.ALL, 'by-id', processId];
  static BY_BUSINESS_AREA = (businessAreaId?: number) => [
    ...ProcessKey.ALL,
    'by-business-area',
    businessAreaId,
  ];
  static BY_BUSINESS_AREA_PAGED = (
    businessAreaId: number,
    pagination: PaginationParams,
  ) => [...ProcessKey.BY_BUSINESS_AREA(businessAreaId), pagination];
  static BY_COMPANY = (companyId: number) => [
    ...ProcessKey.ALL,
    'by-company',
    companyId,
  ];
  static BY_COMPANY_PAGED = (
    companyId: number,
    pagination: PaginationParams,
  ) => [...ProcessKey.BY_COMPANY(companyId), pagination];
  static BY_ORGANIZATION = (organizationId: number) => [
    ...ProcessKey.ALL,
    'by-organization',
    organizationId,
  ];
  static BY_ORGANIZATION_PAGED = (
    organizationId: number,
    pagination: PaginationParams,
  ) => [...ProcessKey.BY_ORGANIZATION(organizationId), pagination];
  static BY_USER = (userId: number) => [...ProcessKey.ALL, 'by-user', userId];
  static BY_USER_PAGED = (userId: number, pagination: PaginationParams) => [
    ...ProcessKey.BY_USER(userId),
    pagination,
  ];
}

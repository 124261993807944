import { z } from 'zod';

export const OrganizationSchema = z.object({
  organizationName: z.string().min(1, { message: 'error_field_required' }),
  organizationDescription: z.string().optional(),
});

export type FormOrganizationSchemaType = z.infer<typeof OrganizationSchema>;

export interface OrganizationSchemaType extends FormOrganizationSchemaType {
  id?: number;
  fileId?: number;
  file?: File;
}

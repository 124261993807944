import { Button, FilterSearchBar, Table } from '@/components';
import { queryClient } from '@/constants';
import { useSearchInputHandler } from '@/hooks/use-search-input-handler.hook';
import { AttachUserToCompanyModal } from '@/modules/Companies/pages/CompaniesPage/components/AttachUserToCompanyModal';
import { CreateCompanyModal } from '@/modules/Companies/pages/CompaniesPage/components/CreateCompanyModal';
import { CompanyKeys, useDeleteCompany } from '@/modules/Companies/queries';
import { AttachEntityToInfrastructureCostModal } from '@/modules/Infrastructure/pages/InfrastructureCostPage/components/AttachEntityToInfrastructureCostModal';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';
import {
  automationsCountColumnConfig,
  businessAreasCountColumnConfig,
  deleteRowColumnConfig,
  nameWithAvatarColumnConfig,
  processesCountColumnConfig,
  roleColumnConfig,
  usersCountColumnConfig,
} from '@/utils';
import { ReactElement, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TabContext } from '../TabContainer';
import { TableRow } from '../Table/types';

interface CompaniesTabProps {
  userId?: string;
  canCreate?: boolean;
  canAttachUser?: boolean;
  canAttachCost?: boolean;
  costId?: string;
  organizationId?: number;
  disableFields?: string[];
  context?: 'default' | 'userDetails';
}

export const CompaniesTab = ({
  userId,
  canCreate,
  canAttachUser,
  canAttachCost,
  costId,
  organizationId,
  disableFields,
  context,
}: CompaniesTabProps): ReactElement => {
  const { t } = useTranslation();
  const { handleInputSearchChange, searchInputValue } = useSearchInputHandler();
  const navigate = useNavigate();

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    isOpen,
    page,
    onPageChange,
    handleSearchChange,
    handleModalState,
    onSort,
  } = useContext(TabContext);

  const routeChange = (row?: TableRow) => {
    const path = `/companies/${row?.id}`;
    navigate(path);
  };

  const { mutate: deleteCompany } = useDeleteCompany();

  const columns = useMemo(() => {
    const handleDelete = (companyId: number) => {
      deleteCompany(companyId, {
        onError: () => {
          toast.error(t('error_deleting_company'));
        },
        onSuccess: () => {
          queryClient.invalidateQueries(CompanyKeys.ALL);
          queryClient.invalidateQueries(OrganizationKeys.ALL);
          toast.success(t('success_deleting_company'));
        },
      });
    };

    const baseColumns = [
      nameWithAvatarColumnConfig({}),
      usersCountColumnConfig(),
      businessAreasCountColumnConfig(),
      processesCountColumnConfig(),
      automationsCountColumnConfig({
        options: {
          className: 'w-25',
        },
      }),
    ];

    switch (context) {
      case 'userDetails':
        return [...baseColumns, roleColumnConfig()];
      case 'default':
      default:
        return [
          ...baseColumns,
          deleteRowColumnConfig({
            disabledMessage: t('delete_company'),
            onDeleteClick(row) {
              handleDelete(row?.id as number);
            },
          }),
        ];
    }
  }, [context, deleteCompany, t]);

  return (
    <>
      <div className="center flex flex-row">
        <FilterSearchBar
          total={companies?.totalRows || 0}
          title={t('companies')}
          onSearch={handleSearchChange}
          variant={'secondary'}
          handleInputSearchChange={handleInputSearchChange}
          searchInputValue={searchInputValue}
        />
        <div className="flex items-center">
          {canCreate && (
            <Button onClick={handleModalState} variant="primary">
              {t('add_companies')}
            </Button>
          )}
          {canAttachUser && userId && (
            <AttachUserToCompanyModal userId={parseInt(userId)} />
          )}
          {canAttachCost && costId && (
            <AttachEntityToInfrastructureCostModal
              costId={parseInt(costId)}
              context="company"
            />
          )}
        </div>
      </div>
      <CreateCompanyModal
        isOpen={isOpen}
        handleClose={handleModalState}
        organizationId={organizationId}
        disableFields={disableFields}
      />
      <Table
        onRowClick={routeChange}
        columns={columns}
        rows={companies?.items ?? []}
        isLoading={isLoadingCompanies}
        noResultsLabel={t('no_companies_available')}
        withPagination
        totalItems={companies?.totalRows}
        totalPages={companies?.totalPages}
        currentPage={page}
        onSort={onSort}
        onPageChange={onPageChange}
      />
    </>
  );
};

import { TaskPerformance } from '@/components/TaskPerformance/TaskPerformance';
import { ReactElement } from 'react';
import { useGetAssociatedTaskCardsData } from '../../hooks';

interface AutomationPerformanceCardsProps {
  isLoading?: boolean;
  company?: string;
  businessArea?: string;
  organization?: string;
}

export const TaskPerformanceCards = ({
  isLoading,
  company,
  businessArea,
  organization,
}: AutomationPerformanceCardsProps): ReactElement => {
  const cardsData = useGetAssociatedTaskCardsData();

  return (
    <div className="flex flex-col gap-2">
      {cardsData.map((cardData, index) => (
        <TaskPerformance
          key={index}
          taskId={cardData.id}
          company={company}
          businessArea={businessArea}
          organization={organization}
          cardData={cardData}
          isLoading={isLoading}
        />
      ))}
    </div>
  );
};
